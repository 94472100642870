

export default function (state = {}, action) {
  switch(action.type) {
    case 'SET_PENDING_WEBCAM_UPLOAD':
      return {
        ...state,
          [action.payload.captureId]: {
            clipId:action.payload.clipId,
            status: 'processing'
          }
      };

    case 'WEBCAM_PROCESSING_COMPLETE':
  	const captureId = action.captureId;
  	const newState = {
    	...state,
    	[captureId]: {
      	...state[captureId],
      	status: 'complete',
        webcam:action.webcamData
  	  	}
  	};

  	return newState;

    default:
      return state;
  }
}

