export const fetchScreenRecordingMetadata = async (captureId) => {
// console.log(`fetch screen recording metadata`)

  try {
    let data;
    if(window.isElectron){
       const relativePath = `screenRecordings/${captureId}/metadata.json`;
        //console.log(relativePath)
        const content = await ipcRenderer.invoke('read-file', relativePath);
        data = JSON.parse(content);
    }
    else{ //Server side Export
      const s3BaseUrl = 'https://yarn-assets.s3.amazonaws.com/';
      const metadataUrl = `${s3BaseUrl}screenRecordings/${captureId}/metadata.json`;
      const response = await fetch(metadataUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      data = await response.json();
    }

    return data
    //return {width:data.dimensions.width,height:data.dimensions.height}
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return null;
  }
};





