import findIndex from 'lodash/findIndex'


const DUMMY_DATA_ORG='jasperdstory'


export default function (state = [], action) {
	switch (action.type) {
	

    case 'SIGN_OUT':
      return []

    case 'FETCH_PROJECTS_SUCCESS':
      if (action.orgDomain === DUMMY_DATA_ORG) {
        const fourWeeksAgo = new Date();
        fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 28);

        const getRandomDate = () => {
          const start = fourWeeksAgo.getTime();
          const end = Date.now();
          return new Date(start + Math.random() * (end - start));
        };

      return action.response.map(project => ({
        ...project,
        updated_at: getRandomDate().toISOString()
        }));
      } else {
      return action.response;
    }


     case 'FETCH_SINGLE_PROJECT_SUCCESS':
      const fetchedProject = action.response;
      const existingIndex = findIndex(state, project => project.id === fetchedProject.id);
      if (existingIndex !== -1) {
        // If the project already exists in the state, update it
        return [
          ...state.slice(0, existingIndex),
          fetchedProject,
          ...state.slice(existingIndex + 1)
        ];
      } else {
        // If it's a new project, add it to the state
        return [...state, fetchedProject];
      }


    case 'UPDATE_COLLAB_PAGE_SETTINGS_REQUEST':
      const updateIdx = findIndex(state, project => project.id === action.projectId);
      if (updateIdx === -1) return state;
      return [
        ...state.slice(0, updateIdx),
        {
          ...state[updateIdx],
          collab_page_settings: action.pageSettings
        },
        ...state.slice(updateIdx + 1)
    ]

      

    case 'CREATE_PROJECT_REQUEST':
      const project = action.project    
      return [...state,project]



    case 'RESTORE_PROJECT_REQUEST':
      return [...state, {...action.project,archived_at:null}]

    case 'RESTORE_PROJECT_FAILURE':
      return state.filter(project => project.id !== action.projectId)
      
    case 'UPDATE_PROJECT_PRIVATE_STATUS': {
      const projectId = action.projectId;
      const isPrivate = action.isPrivate;
      return state.map(project =>
        project.id === projectId ? { ...project, is_private: isPrivate } : project
      )
    }

  case 'UPDATE_PROJECT_SUCCESS':
    const projectId = action.response.id
    let index = findIndex(state,(project => {
      return project.id === projectId
    }))
    return [
      ...state.slice(0,index),
      action.response,
      ...state.slice(index + 1)
    ]

  case 'ARCHIVE_PROJECT_REQUEST': 
    const id = action.projectId
    let idx = findIndex(state,(project => {
      return project.id === id
    }))
    return [
      ...state.slice(0,idx),
      ...state.slice(idx + 1)
    ]

    case 'UPDATE_PROJECT_ACCESS_USER':
      const updatedProjectId = action.projectId;
      return state.map(project => 
        project.id === updatedProjectId ? { ...project, project_access_user:action.userId } : project
      );


    case 'ADD_PROJECT_TO_COLLECTION_REQUEST':
      const { projectId: requestId, collectionId: requestCollectionId } = action;
      return state.map((project) =>
        project.id === requestId
        ? {
        ...project,
          collections: [...(project.collections || []), requestCollectionId],
       }
      : project
      );


    case 'REMOVE_PROJECT_FROM_COLLECTION_REQUEST':
      const { projectId: removeRequestId, collectionId: removeCollectionId } = action;
      return state.map((project) =>
        project.id === removeRequestId
          ? {
              ...project,
              collections: project.collections.filter(id => id !== removeCollectionId),
            }
          : project
      );

    default:
      return state;
	}
}
