import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import Icon from '../../../misc/Icon';
import TranscriptPanelDragHandleDropdown from './TranscriptPanelDragHandleDropdown'

const TranscriptPanelDragHandle = ({ id, yPos, height, text, hovered, hasCursorInside, requiresUpdate, isLastChunk, isOnlyChunk, textLength,playClipFromTranscript,clipId,recalculateAudioClip,nearBottom,previewingAudioClipId,cancelPreviewingAudioClip,addSceneAtEndOfProject,addAudioClipAtEndOfProject }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    height: `${height}px`,
    top: `${yPos}px`,    
  };

  // Effect to set showDropdown to false when dragging starts
  useEffect(() => {
    if (isDragging) {
      setShowDropdown(false);
    }
  }, [isDragging]);

  // Custom click handler for the handle
  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDropdown(prev => !prev);
  }, []);

  // Combine our custom click handler with the dnd-kit listeners
  const combinedListeners = {
    ...listeners,
    onClick: handleClick,
  };

  // const [isWriting, setIsWriting] = useState(false);

  // const textLengthChangesRef = useRef([]);
  // const writingTimeoutRef = useRef(null);

  // useEffect(() => {
  //   if (hasCursorInside) {
  //     const now = Date.now();
  //     textLengthChangesRef.current.push(now);
      
  //     // Keep only the last 5 changes
  //     if (textLengthChangesRef.current.length > 2) {
  //       textLengthChangesRef.current.shift();
  //     }

  //     // Check if there have been 5 changes in the last 2 seconds
  //     if (textLengthChangesRef.current.length === 2 &&
  //         now - textLengthChangesRef.current[0] <= 200) {
  //       setIsWriting(true);
  //     }

  //     // Reset the writing state after 1 second of inactivity
  //     if (writingTimeoutRef.current) {
  //       clearTimeout(writingTimeoutRef.current);
  //     }
  //     writingTimeoutRef.current = setTimeout(() => {
  //       setIsWriting(false);
  //       textLengthChangesRef.current = [];
  //     }, 2500); // 1500
  //   }

  //   return () => {
  //     if (writingTimeoutRef.current) {
  //       clearTimeout(writingTimeoutRef.current);
  //     }
  //   };
  // }, [textLength, hasCursorInside]);


  // requiresUpdate
  // isPreviewPlaying

  //const actionBarVisible = !isWriting && hasCursorInside && textLength > 5
  const actionBarVisible = textLength > 1
  // const actionBarVisible = false

  return (
    <div
      ref={setNodeRef}
      style={style}
      data-dragging-state={isDragging}
      data-chunk-hovered-state={hovered}
      data-dropdown-open={showDropdown}      
      className='editor-transcriptPanel-draggableContainer'
    >      

      
      {/*{nearBottom && 
        <div className='nearBottomTest' />
      }*/}
      
      <button className='editor-transcriptPanel-handle' {...combinedListeners} {...attributes}>
        <Icon name='dragHandle' />

        <div data-position={nearBottom ? "above" : "below"} className='editor-transcriptPanel-handle-tooltipContainer'>
          <div className='tooltip tooltip--chunkHandle'>
            <div className='tooltip--chunkHandle-line'>
              Drag <span className='tooltip-light'> to move</span>
            </div>
            <div className='tooltip--chunkHandle-line'>
              Click <span className='tooltip-light'> to open menu</span>
            </div>
          </div>
        </div>

      </button>
      <TranscriptPanelDragHandleDropdown 
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        playClipFromTranscript={playClipFromTranscript}
        recalculateAudioClip={recalculateAudioClip}
        clipId={clipId}
        isPreviewPlaying={previewingAudioClipId==clipId}
        cancelPreviewingAudioClip={cancelPreviewingAudioClip}
        requiresUpdate={requiresUpdate}
        nearBottom={nearBottom}
        //previewingAudioClipId={previewingAudioClipId}
        // isPreviewPlaying={false}
        // requiresUpdate={true}
        // isPreviewPlaying={true}
        // requiresUpdate={false}
      />
      <div className='editor-transcriptPanel-draggablePreview'>
        {text}
      </div>

      {isOnlyChunk && isLastChunk && !isDragging &&
        <div data-visible={textLength > 5 ? true : false} className='editor-transcriptPanel-draggableContainer-tooltip'>
          <div className='editor-transcriptPanel-draggableContainer-tooltip-label'>
            New clip with Enter
          </div>
          <div className='editor-transcriptPanel-draggableContainer-tooltip-iconContainer'>
            <Icon name='enterKey' />
          </div>
        </div>
      }

      {!isOnlyChunk && isLastChunk && !isDragging &&
        <div data-visible={actionBarVisible ? true : false} className='editor-transcriptPanel-draggableContainer-actionBar'>
          {/*}
          <button onClick={addAudioClipAtEndOfProject} className='editor-transcriptPanel-draggableContainer-actionBar-btn'>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer'>
              <Icon name='enterKey' />
            </div>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-label'>
              New Clip
            </div>
          </button>
          */}
          <button onClick={addSceneAtEndOfProject} className='editor-transcriptPanel-draggableContainer-actionBar-btn'>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer'>
              <Icon name='hash' />
            </div>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-label'>
              New Scene
            </div>
          </button>
          <button onClick={addSceneAtEndOfProject} className='editor-transcriptPanel-draggableContainer-actionBar-btn'>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer'>
              <Icon name='atMedium' />
            </div>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-label'>
              Input Camera
            </div>
          </button>

          {/* }
          <button className='editor-transcriptPanel-draggableContainer-actionBar-btn'>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer'>
              <Icon name='chunkPreview' />
            </div>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-label'>
              Preview
            </div>
          </button>
          <button className='editor-transcriptPanel-draggableContainer-actionBar-btn'>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-iconContainer'>
              <Icon name='chunkRegen' />
            </div>
            <div className='editor-transcriptPanel-draggableContainer-actionBar-btn-label'>
              Regen
            </div>
          </button>
          */}
        </div>
      }


    </div>
  );
};

export default TranscriptPanelDragHandle;