import React, { useRef, useEffect, useState,memo }  from 'react';
import EditorTimelineClipContents from './EditorTimelineClipContents'
import EditorTimelineClipFreezeSegments from './EditorTimelineClipFreezeSegments'
import EditorTimelineClipSkipSegments from './EditorTimelineClipSkipSegments'
import EditorTimelineClipContextMenuContents from './EditorTimelineClipContextMenuContents'
import EditorTimelineClipDraggableHandle from './EditorTimelineClipDraggableHandle'
import * as ContextMenu from '@radix-ui/react-context-menu'
import { Rnd } from 'react-rnd';
import find from 'lodash/find'
import {timeToPixels} from '../../utils/timeToPixels'
// import {calculateDropLines} from '../../utils/calculateDropLines'
import {pixelsToTime} from '../../utils/pixelsToTime'

//while dragging in dndMode we show the proposedClip location which is where it will be when dropped
const ResizeHandle = ({onMouseDown, onMouseUp,onMouseEnter,onMouseLeave}) => (
  <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={(e) => e.stopPropagation()} className='editor-timeline-clip--draggableHandle' />    
);


const leftHandleStyle = {      
  marginTop: '-1px',
  opacity: 0.5,
  width: '8px',
  height: 'calc(100% + 2px)',
  left: '0px',      
  cursor: 'grab',      
  zIndex: 100,
}

const rightHandleStyle = {        
  marginTop: '-1px',
  width: '8px',
  height: 'calc(100% + 2px)',
  right: '0px', 
  opacity: 0.5,     
  cursor: 'grab',
  zIndex: 100,
}



const EditorTimelineClip = (props) => {

 const { 
    clip,
    isActive,
    onDragStart,
    draggablePositionX,
    draggablePositionY,
    handleChangeSegmentPlaybackRate,
    isDnDMode,
    isDragging,
    dragClipId,
    sceneGap,
    pixelsPerSec,
    yOffset,
    devMode,
    TRACK_HEIGHT,
    CLIP_HEIGHT,
    ZOOM_CLIP_HEIGHT,
    scenes,
    scrollRef,
    setIsDraggingSegment} 
  = props

// // // // // // // // /////// TESTING

//   const renderCount = useRef(0);
//   const prevProps = useRef(props);

//   useEffect(() => {
//     renderCount.current += 1;
    
//     const changedProps = Object.entries(props).reduce((acc, [key, value]) => {
//       if (prevProps.current[key] !== value) {
//         acc[key] = {
//           from: prevProps.current[key],
//           to: value
//         };
//       }
//       return acc;
//     }, {});

//     if(clip.id=='15248887'){
//     if (Object.keys(changedProps).length > 0) {
//       console.log(`[EditorTimelineClip] Render #${renderCount.current}. Changed props:`, changedProps);
//     } else {
//       console.log(`[EditorTimelineClip] Render #${renderCount.current}. No props changed.`);
//     }
//     }
//     prevProps.current = props;
//   });
// // // // // // // //   ///////////////////

  const rndRef = useRef(null);

  let clipType = clip.type  
  
  if(clip.type === 'video' && clip.isBasicVideo){
    clipType = 'basicVideo'
  }else if(clip.type === 'video' && !clip.isBasicVideo){
    clipType = 'screenVideo'
  }  

  

  ///temp
  if(clip.type=='webcam' && clip.metadata.isVariable && !clip.metadata.hasInstructions){
    clipType='webcamScript'
  }
  if(clip.type=='webcam' && clip.metadata.isVariable && clip.metadata.hasInstructions){
    clipType='webcamInstructions'
  }
  if(clip.type=='webcam' && !clip.metadata.isVariable){
    clipType='webcamRecording'
  }


  //Temp for slides
  if(clipType=='slide'){
    clipType='textSlide'
  }
  //////



  const canHaveSegments = clipType === 'screenVideo' || clipType === 'basicVideo'
  const canChangePlaybackRate = clipType === 'screenVideo'

  //You can't resize audio clips or webcam recordings
  //You can resize "placeholder" webcam clips
  let canResize = true 
  if(clip.type=='audio' || (clip.type=='webcam' && !clip.metadata.isVariable)){
    canResize = false
  }

  let minWidth=0
  if(clip.type=='video'){
    const hasQuietSegments = clip.segments.some(segment => segment.isQuiet);
    if(!hasQuietSegments){ //Can't resize video with no silent segments
      canResize=false
    }else{
      const minDuration = clip.segments.reduce((acc, segment) => {
      if (segment.isQuiet) {
        return acc; // Add 0.5 seconds for each quiet segment
      }else if(segment.isSkipSegment && !segment.isExpanded){
        return acc
      } else {
        return acc + segment.originalDuration; 
      }
     }, 0);

      //TODO fix why the min duration is wrong sometimes (elias clip in project 1626010472 clip 2137067473)
      minWidth = Math.min(minDuration,clip.duration) * pixelsPerSec; // Convert to pixel width
    }
  }

  if(clip.type=='webcam' && clip.metadata.isVariable){
    minWidth = clip.minDuration * pixelsPerSec
  }

  const draggableClipTopBottomPadding = 5 

  const sceneGapOffset = clip.sceneIndex * sceneGap
  const showRnd = !isDragging || (clip.id==dragClipId)

  //

  let renderClipState = 'push'
  if(isDnDMode && clip.id == dragClipId){
    renderClipState = 'activePreview'
  }
  if(isDnDMode && clip.id != dragClipId){
    renderClipState = 'passivePreview'
  }

  //

  let draggableClipState = 'push'
  if(isDnDMode){
    draggableClipState = 'dnd'
  }

  //

  //const clipLeft = (proposedStartTime * pixelsPerSec) + sceneGapOffset
  const clipLeft = timeToPixels(clip.startTime, pixelsPerSec, scenes, sceneGap)
  const clipWidth = clip.duration * pixelsPerSec
  const clipHeight = clipType === 'zoom' ? ZOOM_CLIP_HEIGHT : CLIP_HEIGHT

  let marginTop = yOffset
  if(clipType === 'zoom'){
    marginTop = 10
  }

  //
  //const isActive = props.activePanelClip && props.activePanelClip.id==clip.id
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [hoveredHandle, setHoveredHandle] = useState(null); // 'left', 'right', or null
  const handleMouseEnterLeft = () => setHoveredHandle('left');
  const handleMouseLeaveLeft = () => setHoveredHandle(null);
  const handleMouseEnterRight = () => setHoveredHandle('right');
  const handleMouseLeaveRight = () => setHoveredHandle(null);


 const [resizingHandle, setResizingHandle] = useState(null);

  const customHandleResizeStop = (e, direction, ref, delta, position) => {
    handleResizeStop(e, direction, ref, delta, position);
    setResizingHandle(null);
  };


  const customHandleResizeStart = (e,direction) => {
    handleResizeStart(clip,direction)
    setResizingHandle(direction)
  };

  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  useEffect(() => {
    if (contextMenuOpen) {
      const handleScroll = () => {
        document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
        // hack to close the context menu
      };

      const scrollElement = scrollRef.current;
      if (scrollElement) {
        scrollElement.addEventListener('scroll', handleScroll, { passive: true });

        // Clean up function to remove the event listener
        return () => {
          scrollElement.removeEventListener('scroll', handleScroll);
        };
      }
    }
  }, [contextMenuOpen]); // Only re-run this effect when contextMenuOpen changes


  let voiceoverRequiresUpdate = false
  if(clip.type=='audio' && clip.requiresUpdate){
    voiceoverRequiresUpdate= true
  }

  const [startX, setStartX] = useState(0);  
  const draggingCursorTimeMs = 200; 
  const draggingCursorDelta = 2; 
  const clickDelta = 6;
  const timeoutRef = useRef(null);


   const handleDragStart = (clip,e) => {
    const canDnD = clip.type!='audio' && clip.type!='webcam'
    const isDnDMode = e.metaKey && !e.shiftKey & canDnD
    const isDragPullMode = e.metaKey && e.shiftKey
    props.onDragStart(clip,isDnDMode,isDragPullMode)    
    setStartX(e.clientX);
    const timeoutId = setTimeout(() => { // Set a timeout to force dragging cursor after draggingCursorTime seconds
      props.setShowDraggingCursor(true);
    }, draggingCursorTimeMs);
    props.handleSetActivePanelClip(clip);
    // Store the timeout ID in a separate ref so we can clear it later
    timeoutRef.current = timeoutId;
    if(props.isDnDMode){
      props.calculateAndSetDropLines(clip,0,sceneGap)
    }
  };


  const onDragClip = (e,sceneId, clip,dx,d) => {
    let metaKeyIsPressed = false 
    if(e.metaKey){
      metaKeyIsPressed = true
    }
    const clipId = clip.id 
    const newStartTime = pixelsToTime(dx, pixelsPerSec, scenes, sceneGap)
    if (Math.abs(dx - startX) > draggingCursorDelta) {
      props.setShowDraggingCursor(true);
    }

    const newDirection = d.deltaX > 0 ? 'right' : d.deltaX < 0 ? 'left' : null;
    if (newDirection !== props.dragDirection) {
      props.setDragDirection(newDirection);
    }

    props.handleDragClip(sceneId,clip,newStartTime,metaKeyIsPressed)
     if(props.isDnDMode){
      props.calculateAndSetDropLines(clip,dx,sceneGap)
    }
  }


const handleDragStop = (clip, e) => {   
   props.setDragDirection(null) 
    const diffX = Math.abs(e.clientX - startX);    
    if (diffX < clickDelta) {
      // It's a click, not a drag
      const targetRect = e.target.getBoundingClientRect(); // Using e.target directly
      if (targetRect) {
        const clickX = startX - targetRect.left;
        const seekTime = clip.startTime + (clickX / pixelsPerSec);
        props.handleSeek(seekTime);        
      }      
    }
    props.setShowDraggingCursor(false);
    clearTimeout(timeoutRef.current);
    if(props.isDnDMode){
      const dropPostion = props.dropLines.closestPosition
      let dropTime = null
     
      const activeDropType = props.dropLines.activeDropType 
      if(dropPostion || dropPostion==0){
        dropTime = pixelsToTime(dropPostion, pixelsPerSec, props.scenes, props.sceneGap)
      }
      props.onDragEnd(clip,dropTime,activeDropType)
    }else{
      props.onDragEnd();
    }
  };





  const handleResize = (clip, e, direction, ref, delta, position) => {
    let newWidth = ref.offsetWidth;
    let newDuration = newWidth / pixelsPerSec
    props.onResize(clip,newDuration,direction)
  };

  const handleResizeStart = (clip,direction) =>{
    props.onResizeStart(clip)
    props.setIsResizing(true)
    props.setResizeDirection(direction)
  }

  const handleResizeStop = () =>{
    props.onResizeStop()
    props.setIsResizing(false)
    props.setResizeDirection(null)
  }

  //console.log(`CLIP RENDER`)

  let isPlaceholderClip = false 
  let opacity = 1
  if(clip.metadata.isVariable){
    isPlaceholderClip = true
    // opacity = 0.5
  }


  const voiceoverWebcamClip = clip.type === 'audio' && clip.parentWebcamClip

  if(voiceoverWebcamClip){
    marginTop = 80    
    opacity = 0.5
  }

  return (
    <>  
      {showRnd && !voiceoverWebcamClip &&

          <ContextMenu.Root modal={false} key={clip.id}  onOpenChange={(open) => {
            setContextMenuOpen(open);
            if (open) {
              props.handleSetActivePanelClip(clip);
            }
          }}> 
          <ContextMenu.Trigger 
            asChild
             onContextMenu={(event)=>{
              const clipRef = rndRef.current 
               if (clipRef && clipRef.props && clipRef.props.position) {
                const scrollAmount = scrollRef.current.scrollLeft                
                const clickX = event.clientX - clipRef.props.position.x + scrollAmount - 11 - 5; // 11 is left scroll gutter
                const seekTime = clip.startTime + (clickX / pixelsPerSec);
                props.handleSeek(seekTime);
              }
            }}                        
            >
            <Rnd     
              ref={rndRef}                     
              key={`draggable_${clip.id}`}
              className='editor-timeline-clip--draggableContainer'
              bounds="parent"   
              minWidth={minWidth}               
              position={ {x:draggablePositionX,y:draggablePositionY}}
              size={{ width: clip.duration * pixelsPerSec, height: clip.type === 'zoom' ? ZOOM_CLIP_HEIGHT + draggableClipTopBottomPadding : CLIP_HEIGHT + draggableClipTopBottomPadding  }}
              onDragStart={(e)=>{handleDragStart(clip,e)}}
              onDragStop={(e)=>{handleDragStop(clip,e)}}              
              onDrag={(e, d) => onDragClip(e,clip.sceneId, clip, d.x,d)}
              onResizeStart={customHandleResizeStart}
              onResizeStop={customHandleResizeStop}
              onResize={(e, direction, ref, delta, position) => handleResize(clip, e, direction, ref, delta, position)}            
              enableResizing={{top: false, right: canResize, bottom: false, left: canResize, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false}}
              dragAxis={isDnDMode ? "both" : "x"}                            
              style={{cursor: 'pointer'}}              
              resizeHandleStyles={{
                left: leftHandleStyle,
                right: rightHandleStyle,
              }}
              resizeHandleComponent={{
                left: <ResizeHandle onMouseEnter = {handleMouseEnterLeft} onMouseLeave={handleMouseLeaveLeft}/>,
                right: <ResizeHandle onMouseEnter = {handleMouseEnterRight} onMouseLeave={handleMouseLeaveRight}/>,
              }}
            >
              <div 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={()=>props.handleSetActivePanelClip(clip)}
                className={"editor-timeline-clip editor-timeline-clip--draggable" + (draggableClipState ? ` editor-timeline-clip--draggable--${draggableClipState} ` : '') + (clipType ? ` editor-timeline-clip--draggable--${clipType} ` : '')}>
                
                {devMode && <div className='draggableTrackClip-inner'> {clip.id} </div>}              
                <EditorTimelineClipContents 
                  clip={clip}
                  clipType={clipType}   
                  isActive={isActive}    
                  hoveredHandle={hoveredHandle}  
                  resizingHandle={resizingHandle}   
                  webcamChunks={props.webcamChunks}         
                />                   
              </div>
            </Rnd>                  
          </ContextMenu.Trigger>
          <EditorTimelineClipContextMenuContents
            clip={clip}
            cutClip={props.cutClip}
            copyClip={props.copyClip}
            duplicateClip={props.duplicateClip}
            addFreezeFrame={props.addFreezeFrame}
            addSkipSegment={props.addSkipSegment}
            canChangePlaybackRate={canChangePlaybackRate}
            changeVideoClipPlaybackRate={props.changeVideoClipPlaybackRate}
            deleteClip={props.deleteClip}
            splitRecordingClip={props.splitRecordingClip}
          />                        
        </ContextMenu.Root>            
      }
    
      

      <div 
        data-clip-type={clipType}        
        data-clip-active={isActive ? true : false}
        data-clip-hovered={isHovered ? true : false}
        data-clip-context-menu-open={contextMenuOpen ? true : false}
        data-clip-promotezindex-state={contextMenuOpen ? true : false}
        data-clip-voiceover-updating={voiceoverRequiresUpdate ? true : false}
        data-clip-voiceover-webcam={voiceoverWebcamClip ? true : false}        
        style={{left: `${clipLeft}px`, width: `${clipWidth}px`, height: `${clipHeight}px`, marginTop: `${marginTop}px`,opacity:opacity}}
        className={'editor-timeline-clip editor-timeline-clip--render ' + (renderClipState ? ` editor-timeline-clip--render--${renderClipState} ` : '')}
      >      
        <EditorTimelineClipContents 
          clip={clip}
          clipType={clipType}
          canResize={canResize}  
          hoveredHandle={hoveredHandle}   
          resizingHandle={resizingHandle}   
          webcamChunks={props.webcamChunks}    
        />      
      </div>
  


      {/* Freeze Frame Segments */}
   
      {canHaveSegments && !isDnDMode &&

        <div data-clip-type={clipType} style={{left:`${clip.startTime*pixelsPerSec}px`}} className='editor-timeline-clip-segmentContainer'>
          
          <EditorTimelineClipFreezeSegments          
            clip={clip}
            trimStart={clip.metadata.trimStart}
            clipWidth={clip.duration * pixelsPerSec}
            handleChangeSegmentPlaybackRate={handleChangeSegmentPlaybackRate}
            scrollRef={scrollRef}            
            handleSeek={props.handleSeek}          
            removeFreeze={props.removeFreeze}
            pixelsPerSec={pixelsPerSec}
            yOffset={yOffset}
            TRACK_HEIGHT={TRACK_HEIGHT}
            clipHeight={CLIP_HEIGHT}
            pixelsPerSec={pixelsPerSec}
            sceneGap={sceneGap}
            setIsDraggingSegment={setIsDraggingSegment}
          />
          
          
          <EditorTimelineClipSkipSegments          
            clip={clip}
            trimStart={clip.metadata.trimStart}
            clipWidth={clip.duration * pixelsPerSec}
            toggleSkipSegment={props.toggleSkipSegment}
            handleChangeSkipSegmentDuration={props.handleChangeSkipSegmentDuration}       
            scrollRef={scrollRef}            
            handleSeek={props.handleSeek}          
            removeSkip={props.removeSkip}
            pixelsPerSec={pixelsPerSec}
            yOffset={yOffset}
            TRACK_HEIGHT={TRACK_HEIGHT}
            clipHeight={CLIP_HEIGHT}
            pixelsPerSec={pixelsPerSec}
            sceneGap={sceneGap}
            handleResizeSkipSegmentStart={props.handleResizeSkipSegmentStart}
            handleResizeSkipSegmentStop={props.handleResizeSkipSegmentStop}
            setIsDraggingSegment={setIsDraggingSegment}
            resizingSkipSegmentHandle={props.resizingSkipSegmentHandle}
          />
          
        </div>
      }
    </>
  );
}
//);

// export default EditorTimelineClip;



const MemoizedEditorTimelineClip = memo(EditorTimelineClip);

export default MemoizedEditorTimelineClip;




