import {ExportTimelineClip} from './ExportTimelineClip'

// import {ExportVideoClip} from './ExportVideoClip'
import {CodecVideoClip} from '../../timeline/CodecVideoClip'
import {TextSlideClip} from '../../timeline/TextSlideClip'
import {ZoomClip} from '../../timeline/ZoomClip'
import {SlideClip} from '../../timeline/slide/SlideClip'
// import {ChartClip} from './ChartClip'
import {ExportImageClip} from './ExportImageClip'
import {ExportAudioClip} from './ExportAudioClip'

import {calulateVideoTimeFromTimelineTime} from '../../timeline/utils/calulateVideoTimeFromTimelineTime'
 




class ExportTimelineScene { 

	constructor({id, sceneStartTime, startTime,duration,clips,sceneIndex,title},addLoadMediaPromise,renderVariables,exportStartTime,exportEndTime) {
		this.id = id;
		//this.startTime = sceneStartTime || 0;
		this.startTime = startTime || 0;
		this.sceneStartTime = sceneStartTime
		this.duration = duration;
		this.clips = []
		this.addLoadMediaPromise=addLoadMediaPromise
		this.sceneIndex=sceneIndex
		this.renderVariables=renderVariables || {}
		this.exportStartTime = exportStartTime 
		this.exportEndTime = exportEndTime

	}

	initScene(isInitialLoad,clips){
		const scene={id: this.id, startTime: this.startTime, duration: this.duration,sceneIndex:this.sceneIndex,title:this.title}
		
		clips.forEach((clip) => {
			// Skip placeholder webcam clips during export
			if (clip.type === 'webcam' && clip.metadata.isVariable) {
				return;
			}
			this.addClip({...clip, sceneId: this.id})
		})

		// clips.forEach((clip)=>{this.addClip({...clip,sceneId:this.id})})		
	}

	// addClip(clip) {
	// 	const timelineClip = this.createNewTimelineClip(clip)
	// 	if(timelineClip){
	// 		this.clips.push(timelineClip)
	// 	}
	// }

	addClip(clip) {
		// Calculate the absolute start and end times of the clip
		const clipStartTime = this.sceneStartTime + clip.startTime;
		const clipEndTime = clipStartTime + clip.duration;
		// // Check if the clip is within the export time period
		if (clip.type!=='zoom' && (clipEndTime <= this.exportStartTime || clipStartTime >= this.exportEndTime) ){
			// Clip is outside the export time period, so we don't add it
			return;
		}else{
			const timelineClip = this.createNewTimelineClip(clip)
			if(timelineClip){
				this.clips.push(timelineClip)
			}
		}
	}


	createNewTimelineClip(newClip){
		const scene = this
		let timelineClip
		let clip = {...newClip}
		if(newClip.startTime){
			clip.startTime = newClip.startTime - this.startTime //adjust for scene start time (this is not a TimelineClip so doesnt go through the setter)
		}
		if(newClip.relativeStartTime || newClip.relativeStartTime==0){
			clip.startTime = newClip.relativeStartTime
		}
		if((clip.duration >0 && clip.startTime>=0)){
			 if(clip.type=='video'){
				timelineClip = new CodecVideoClip(clip,scene,this.renderVariables)
				this.addLoadMediaPromise(timelineClip.loadPromise)
			}
			else if(clip.type=='webcam') {
				timelineClip = new CodecVideoClip(clip,scene)	
			}
			else if(clip.type=='textSlide'){
				timelineClip = new TextSlideClip(clip,scene)
			}
			else if(clip.type=='slide'){
				timelineClip = new SlideClip(clip,scene,true,this.renderVariables)
				this.addLoadMediaPromise(timelineClip.loadPromise)
			}
			else if(clip.type=='zoom'){
				timelineClip = new ZoomClip(clip,scene)
			}
			else if(clip.type=='audio') {
				timelineClip = new ExportAudioClip(clip,scene)
				this.addLoadMediaPromise(timelineClip.loadPromise)
			}	
			else if(clip.type=='chart'){
				timelineClip = new ChartClip(clip,scene)
			}
			else if(clip.type=='image') {
				timelineClip = new ExportImageClip(clip,scene,this.handleClipMediaLoaded)
				this.addLoadMediaPromise(timelineClip.loadPromise)
			}	
		}
		return timelineClip	
	}

	
  getSortedClips() {
	  return this.clips.sort((a, b) => a.startTime - b.startTime);
  }

	getSortedTrackClips(zIndex) {
		let sameTrackClips = this.clips.filter(clip => clip.zIndex === zIndex);
		return sameTrackClips.sort((a, b) => a.startTime - b.startTime);
	}

  getClipIndex(clips, clipId) {
	  return clips.findIndex(c => c.id === clipId);
  }

	

 get endTime() {
		return this.startTime+this.duration
	}

	destroy() {
		this.clips.forEach(clip => {
			if (clip.destroy && typeof clip.destroy === 'function') {
				clip.destroy();
			}
		});
		this.clips = null;
	}


}


export { ExportTimelineScene }


