import React, { useState, useEffect,useRef,memo } from 'react';
import Icon from '../../misc/Icon';
import ReactSlider from 'react-slider';
import * as Tooltip from '@radix-ui/react-tooltip';
import EditorToolbarInsertBar from './EditorToolbarInsertBar'
import {formatTimelineDuration} from '../../../utils/dateFormatters/formatTimelineDuration'
import {formatTimelineDurationProMode} from '../../../utils/dateFormatters/formatTimelineDurationProMode'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import AddFromLibraryDialog from '../popovers/AddFromLibraryDialog/AddFromLibraryDialog'

function EditorToolbar(props) {

	const {
		isDnDMode,
		toggleDnDMode,
		currentTime, 
		duration, 
		isPlaying, 
		togglePlayPause,
		addRecordingFromVideoLibrary,
		addZoom,
		isPreviewing,
		prePreviewState,
		handleSeek,
		splitRecordingClip, 
		isEmpty, 
		activeScreenVideoAtPlayhead,
		addVideoClipFromUpload,
		updateImageDisplayWidth,
		handleNewImageFileUpload,
		insertImageFromRecent,
		handleNewVideoFileUpload,
		insertVideoFromRecent,
		insertWebcamFromRecent,
		userIsProMode,
		isRyanDriverAI,
		addSlide,
		slideClipAtPlayhead,
		splitScene} = props


  const [isMetaKeyPressed, setIsMetaKeyPressed] = useState(false);
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false);
  //const [showAddFromLibraryDialog, setShowAddFromLibraryDialog] = useState(false);

  const [showAddFromLibraryDialog, setShowAddFromLibraryDialog] = useState(false);
    
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey) {
        setIsMetaKeyPressed(true);
      }
      if (event.shiftKey) {
        setIsShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      // Explicitly check for false to reset the state
      if (event.metaKey === false) {
        setIsMetaKeyPressed(false);
      }
      if (event.shiftKey === false) {
        setIsShiftKeyPressed(false);
      }
    };

    const handleWindowBlur = () => {
      setIsMetaKeyPressed(false);
      setIsShiftKeyPressed(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('blur', handleWindowBlur);

    // Cleanup function
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);


  

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if (event.shiftKey && event.code === 'Space') {
      //   togglePlayPause();
      // }
      if (event.shiftKey && event.metaKey && event.key.toLowerCase() === 's') {
      	splitRecordingClip();
    	}
    	if (event.metaKey && event.key.toLowerCase() === 'o') {
      	addZoom();
    	}
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [togglePlayPause, splitRecordingClip, addZoom]); // togglePlayPause dependency


  const handleRestartButtonClick = (e) => {    
    handleSeek(0);
    togglePlayPause();    
  };

  const handlePlayButtonClick = (e) => {    
    togglePlayPause();	        
  };


 	let displayTime = currentTime 
 	if(isPreviewing){
 		displayTime = prePreviewState.currentTime
 	}

 	const playButtonClassName = `editor-toolbar-playBtn' : ''}`;

 	let playBtnShortcutLabel = 'J'

 	//const isRyanDriverAI = true
 	const leftHandPlayShortcut = isRyanDriverAI

 	if(leftHandPlayShortcut){
 		playBtnShortcutLabel = 'E'
 	}

 	const proMode = userIsProMode	

 	const disableClipButtons = !activeScreenVideoAtPlayhead || isPlaying 


  return (
    <div className={'editor-toolbar ' + (proMode ? ' editor-toolbar--proMode ' : ' editor-toolbar--beginnerMode ') + (isEmpty ? 'editor-toolbar--isEmpty' : '')}>    
    	<div className='editor-toolbar-farLeftContainer'>
    		

	
    	</div>

			<div className='editor-toolbar-leftContainer'>
				
				<button className='editor-toolbar-playBtn' onClick={handlePlayButtonClick} tabIndex="-1" onMouseDown={(e) => e.preventDefault()}>
					{(!isPlaying || isPreviewing) &&
						<Icon name='playbackPlay' />
					}
					{isPlaying && !isPreviewing &&
						<Icon name='playbackPause' />
					}					

					{proMode && 					
						<div className='editor-toolbar-playBtn-fixedShortcut'>
							<div className='editor-toolbar-playBtn-fixedShortcut-iconContainer'>
								<Icon name='keyCommand' />
							</div>
							<div className='editor-toolbar-playBtn-fixedShortcut-letter'>
								{playBtnShortcutLabel}
							</div>						
						</div>
					}

					{!proMode && 					
						<div className='editor-toolbar-toolbarBtnShortcutContainer'>
							<div className='editor-toolbar-toolbarBtnShortcut'>							
								<div className='editor-toolbar-toolbarBtnShortcut-iconContainer'>
									<Icon name='keyCommand' />
								</div>
								<div className='editor-toolbar-toolbarBtnShortcut-letter'>
									{playBtnShortcutLabel}
								</div>
							</div>
						</div>
					}
					

					
				</button>			

				{proMode && 
					<button className='editor-toolbar-restartBtn' onClick={handleRestartButtonClick} tabIndex="-1" onMouseDown={(e) => e.preventDefault()}>
						<Icon name='playFromStartSemibold' />												
					</button>							    					
				}

				{!proMode && 
					<div className='editor-toolbar-timestampsContainer'>
						<div className='editor-toolbar-timestamp editor-toolbar-timestamp--current'>
							{formatTimelineDuration(displayTime)}
						</div>
						
						<div className='editor-toolbar-timestamp editor-toolbar-timestamp--duration'>
							{formatTimelineDuration(duration)}
						</div>					
					</div>			
				}

				{proMode && 
					<div className='editor-toolbar-timestampsContainer'>
						<div className='editor-toolbar-timestamp editor-toolbar-timestamp--current'>
							{formatTimelineDurationProMode(displayTime)}
						</div>
						
						<div className='editor-toolbar-timestamp editor-toolbar-timestamp--duration'>
							{formatTimelineDurationProMode(duration)}
						</div>					
					</div>			
				}


			</div>
			
			
			<div className='editor-toolbar-centerContainer'>
				<EditorToolbarInsertBar 
					addSlide={addSlide}
					addVideoClipFromUpload={addVideoClipFromUpload}
					addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
					isPlaying={isPlaying}
					isEmpty={isEmpty}
					handleNewImageFileUpload={handleNewImageFileUpload}
					insertImageFromRecent={insertImageFromRecent}
					handleNewVideoFileUpload={handleNewVideoFileUpload}
					insertVideoFromRecent={insertVideoFromRecent}
					addSlideElement={props.addSlideElement}
					activePanelClip={props.activePanelClip}
					handleSlideImageFileUpload={props.handleSlideImageFileUpload}
					handleNewWebcamFileUpload={props.handleNewWebcamFileUpload}
					insertWebcamFromRecent={props.insertWebcamFromRecent}
					slideClipAtPlayhead={slideClipAtPlayhead}
				/>				
			</div>

			<div className='editor-toolbar-hSpacer' />


			
			

			 <div className='editor-toolbar-rightContainer'>
			 	
			 		{/*}

					<button disabled={!activeScreenVideoAtPlayhead || isPlaying } className='editor-toolbar-btn editor-toolbar-btn--addZoom' onClick={addZoom} tabIndex="-1" onMouseDown={(e) => e.preventDefault()}>
						<div className='editor-toolbar-btn-iconContainer'>
							<Icon name='magnifyingGlass' />
						</div>   




						{!proMode && 					
							<div className='editor-toolbar-btn-label'>
								Add Zoom
							</div>
						}
						{proMode && 					
							<div className='editor-toolbar-btn-label'>
								Zoom
							</div>
						}						

						{!proMode && 					
							<div className='editor-toolbar-toolbarBtnShortcutContainer'>
								<div className='editor-toolbar-toolbarBtnShortcut'>								
									<div className='editor-toolbar-toolbarBtnShortcut-iconContainer'>
										<Icon name='keyCommand' />
									</div>										
									<div className='editor-toolbar-toolbarBtnShortcut-letter'>
										O
									</div>
								</div>
							</div>
						}

						{proMode && 					
							<div className='editor-toolbar-toolbarBtnFixedShortcut'>
								<div className='editor-toolbar-toolbarBtnFixedShortcut-iconContainer'>
									<Icon name='keyCommand' />
								</div>
								<div className='editor-toolbar-toolbarBtnFixedShortcut-letter'>
									O
								</div>						
							</div>
						}


					</button>
					*/}

					
						{/*}
						<button  onClick={splitScene} className='editor-toolbar-btn editor-toolbar-btn--splitClip' tabIndex="-1" onMouseDown={(e) => e.preventDefault()}>
								<div className='editor-toolbar-btn-iconContainer'>
									<Icon name='splitClipAlt2' />
								</div>    
									<div className='editor-toolbar-btn-label'>
										Split Scene
									</div>
							</button> 
						*/}


			 			<AddFromLibraryDialog
							showPopover={showAddFromLibraryDialog}
		          handleClosePopover={() => setShowAddFromLibraryDialog(false)}
		          insertImageFromRecent={insertImageFromRecent}
		          insertVideoFromRecent={insertVideoFromRecent}
		          addRecordingFromVideoLibrary={addRecordingFromVideoLibrary}
		          insertWebcamFromRecent={insertWebcamFromRecent}
		        >          		          
							<button onClick={() => setShowAddFromLibraryDialog(!showAddFromLibraryDialog)}  className='editor-toolbar-btn editor-toolbar-btn--library editor-toolbar-btn--noBG'>
								<div className='editor-toolbar-btn-iconContainer'>
									<Icon name='addFromLibraryMedium' />
								</div>    
								<div className='editor-toolbar-btn-label'>
									Library
								</div>
							</button>
		        </AddFromLibraryDialog>			

						

			 			{proMode && 					
							<button disabled={!activeScreenVideoAtPlayhead  || isPlaying} onClick={splitRecordingClip} className='editor-toolbar-btn editor-toolbar-btn--splitClip editor-toolbar-btn--noBG' tabIndex="-1" onMouseDown={(e) => e.preventDefault()}>
								
								<div className='editor-toolbar-btn-iconContainer'>
									<Icon name='splitClipAlt2' />
								</div>    
										
								<div className='editor-toolbar-btn-label'>
									Split Clip
								</div>

								{proMode && 					
									<div className='editor-toolbar-toolbarBtnFixedShortcut'>
										<div className='editor-toolbar-toolbarBtnFixedShortcut-iconContainer'>
											<Icon name='keyShift' />
										</div>
										<div className='editor-toolbar-toolbarBtnFixedShortcut-iconContainer'>
											<Icon name='keyCommand' />
										</div>
										<div className='editor-toolbar-toolbarBtnFixedShortcut-letter'>
											S
										</div>
									</div>
								}


							</button> 
						}




				<DropdownMenu.Root modal={false}> 
          <DropdownMenu.Trigger asChild>
            <button className='editor-toolbar-btn editor-toolbar-btn--iconOnly editor-toolbar-btn--noBG editor-toolbar-btn--dropdown'>
              <div className='editor-toolbar-btn-iconContainer'>
                <Icon name='ellipses' />
              </div>
              
            </button>
          </DropdownMenu.Trigger>
               
          <DropdownMenu.Content             
            align="end"
            alignOffset={-2}
            className='dropdownMenu dropdownMenu--timelineSelect forceDarkTheme'
          >          		
        		<DropdownMenu.Item onSelect={splitScene}>
            	<div className='dropdownMenu-item-iconContainer'>
            		<Icon name='splitClip' />
          		</div>
              Split Scene
              <div className='dropdownMenu-item-keyboardShortcut'>
              	⌘⇧C
              </div>
            </DropdownMenu.Item>
            <DropdownMenu.Separator/>
            {!proMode && 					
            <DropdownMenu.Item onSelect={splitRecordingClip} className={disableClipButtons ? 'dropdownMenu-item--disabled' : ''}>
            	<div className='dropdownMenu-item-iconContainer'>
            		<Icon name='splitClipAlt2' />
          		</div>
              Split Clip
              <div className='dropdownMenu-item-keyboardShortcut'>
              	⌘⇧S
              </div>
            </DropdownMenu.Item>
          	}
            
            <DropdownMenu.Item onSelect={()=>{props.addFreezeFrame()}} className={disableClipButtons ? 'dropdownMenu-item--disabled' : ''}>
            	<div className='dropdownMenu-item-iconContainer'>
            		<Icon name='playbackPause' />
          		</div>
              Add Freeze Frame
            </DropdownMenu.Item>
            <DropdownMenu.Item onSelect={()=>{props.addSkipSegment()}}  className={disableClipButtons ? 'dropdownMenu-item--disabled' : ''}>
            	<div className='dropdownMenu-item-iconContainer'>
            		<Icon name='skipSegment' />
          		</div>
              Add Skip Segment
            </DropdownMenu.Item>
          
          </DropdownMenu.Content>          
        </DropdownMenu.Root>
						
				
				
				</div>
			
			
				<div className='editor-toolbar-topBorder' />
			</div>		  
  );
}

//export default EditorToolbar;


const MemoizedEditorToolbar = memo(EditorToolbar);


export default MemoizedEditorToolbar;



