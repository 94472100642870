import React from 'react';
import Icon from '../../../../misc/Icon'
import EditorTimelineClipContentsWebcam from './EditorTimelineClipContentsWebcam'

const EditorTimelineClipContents = ({ clip, clipType, canResize, hoveredHandle, resizingHandle,webcamChunks}) => {
  
  let clipTypeIcon
  let clipLabel

  if(clipType === 'textSlide'){
    clipTypeIcon = 'textSlideClipMedium'
    clipLabel = 'Slide'

    // Find the first text element and use its metadata.text
    if (clip.elements) {
      const firstTextElement = clip.elements.find(element => element.type === 'text')
      if (firstTextElement && firstTextElement.metadata && firstTextElement.metadata.text) {
        clipLabel = firstTextElement.metadata.text
      }
    }
  }

  if(clipType === 'chart'){
    clipTypeIcon = 'chartClipMedium'
    clipLabel = 'Bar Chart'
    
    if(clip.metadata.activeChartType=='number' ){
      clipLabel = "Number"
    }
     else if(clip.metadata.activeChartType=='donut' ){
      clipLabel = "Donut"
    }    
  }

  if(clipType === 'screenVideo'){
    clipTypeIcon = 'screenVideoClipMedium'
    clipLabel = clip.metadata.label
  }
  
  if(clipType === 'basicVideo'){
    clipTypeIcon = 'videoClipMedium'
    clipLabel = clip.metadata.label
  }

  if(clipType === 'zoom'){
    clipTypeIcon = 'magnifyingGlass'
  }

  if(clipType === 'image'){  
    clipLabel = "Image"
    clipTypeIcon = 'imageClipMedium'
  }

  if(clipType === 'image' && clip.metadata.originalFileName){
    clipLabel = clip.metadata.originalFileName
  }

  if(clipType === 'audio'){
    clipLabel = clip.metadata.text || 'New Clip'
  }


  if(clip.type=='webcam'){
    clipTypeIcon = 'speakerRectangleMedium'
    clipLabel='Webcam'
    if(clip.metadata.isVariable){
      clipLabel="Webcam Placeholder"
    }
  }

  if(clip.type=='webcam' && clipType !== 'webcamRecording'){
    clipLabel = 'Instructions'
  }



  return (
    <>
      {(clipType !== 'webcamRecording' && clipType !== 'webcamScript') &&
      <div className='editor-timeline-clip-inner'>
        {clipTypeIcon && 
          <div className='editor-timeline-clip-inner-iconContainer'>
            <Icon name={clipTypeIcon} />
          </div>
        }        
        {clipLabel &&
          <div className='editor-timeline-clip-inner-label'>
            {clipLabel}
          </div>
        }
      </div>
      }

      {(clipType === 'webcamRecording' || clipType === 'webcamScript') &&
        <EditorTimelineClipContentsWebcam 
          clip={clip}
          webcamChunks={webcamChunks}
        />
      }
      
      
     
        <>
         {canResize&& 
          <div 
            data-hovered-state={hoveredHandle=='left' ? true : false} 
            data-resizing-state={resizingHandle =='left' ? true : false}
            data-clip-type={clipType}
            className='editor-timeline-clip-handle editor-timeline-clip-handle--left'
          >
            <div className='editor-timeline-clip-handle-inner'  onMouseEnter={()=>{console.log('mouse enter')}}>
              <div className='editor-timeline-clip-handle-inner-bar' />
            </div>
          </div>
        }
        {canResize &&
          <div 
            data-hovered-state={hoveredHandle=='right' ? true : false} 
            data-resizing-state={resizingHandle =='right' ? true : false}
            data-clip-type={clipType}
            className='editor-timeline-clip-handle editor-timeline-clip-handle--right'
          >
            <div className='editor-timeline-clip-handle-inner'>
              <div className='editor-timeline-clip-handle-inner-bar' />
            </div>
          </div>    
          }  
        </>
      

    </>
  );
};

export default EditorTimelineClipContents;