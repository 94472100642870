import getChunkWordIndices from './getChunkWordIndices'

//Here we parse the prosemirror doc into the structure of timeline clips so we can sync the timeline with the transcriptpabel

//We increment clip index for webcam recording or transcript chunks/audio clips that are not in a placeholder
//for placeholder webcam audio clips dont give them an index, give them an index relative to the placeholder clip

//TODO In transcript panel it still called placeholder webcam

const getParsedNodes = (view) => {
  
  const { state } = view;
  
  let sceneNodes = []
  let audioNodes = []
  let webcamRecordingNodes = []  
  let variableWebcamNodes = []
  let webcamHeaders = [] //dimensions of the headers for positioning the buttons
  
  let currentGroup = null;
  let currentSceneId = null;

  let currentInstructions = []; 
  
  let currentSkippedWords=[]
  let currentWebcamTranscriptChunks = [];

  let clipIndex = 0
  let indexInPlaceholder = 0

  state.doc.descendants((node, pos) => {

    if (node.type.name === "sceneHeader") {
      currentSceneId = node.attrs.sceneId;
      const parsedSceneNode = parseSceneNode(node,pos,view)
      sceneNodes.push(parsedSceneNode)
    }

    else if (node.type.name === "transcriptGroup") {
      if (currentGroup?.groupType === 'variable-webcam' && currentGroup.hasInstructions && currentInstructions.length > 0) {
        const instructions = currentInstructions.join('\n');
        // Add instructions to the placeholder node
        const existingNode = variableWebcamNodes[variableWebcamNodes.length - 1];
        if (existingNode) {
          existingNode.instructions = instructions;
        }
        currentInstructions = []; // Reset for next group
      }


      // If we were processing a webcam recording group, finalize it
      if (currentGroup?.groupType === 'webcam-recording' && currentWebcamTranscriptChunks.length > 0) {
        webcamRecordingNodes.push({
          clipId: currentGroup.groupId,
          sceneId: currentSceneId,
          clipIndex: clipIndex, 
          chunks: currentWebcamTranscriptChunks,
          skippedWords: currentSkippedWords
        });
        clipIndex+=1
      }
      const groupId = node.attrs.groupId;
      const groupType = node.attrs.groupType;  
      const hasInstructions = node.attrs.hasInstructions    
      currentGroup = {groupId,groupType,hasInstructions};

      // Reset chunks array for new webcam recording group
      if (groupType === 'webcam-recording') {
        currentWebcamTranscriptChunks = [];
        currentSkippedWords = node.attrs.skippedWords || []
        const headerDimensions = getNodeDimensions(pos, view, `transcript-group-header-${groupId}`);
        webcamHeaders.push({
          clipId: groupId,
          isVariable:false,
          dimensions: headerDimensions,
          hasInstructions:hasInstructions
        });

      }
      else if (groupType === 'variable-webcam') {
        indexInPlaceholder = 0
        const headerDimensions = getNodeDimensions(pos, view, `transcript-group-header-${groupId}`);
        webcamHeaders.push({
          clipId: groupId,
          isVariable:true,
          dimensions: headerDimensions,
          hasInstructions:hasInstructions
        });


        variableWebcamNodes.push({
          clipId: groupId,
          sceneId: currentSceneId,
          type: 'variable-webcam',
          clipIndex:clipIndex,
          instructions:'',
          hasInstructions:hasInstructions
        })
        clipIndex+=1
      }
    }
    else if (node.type.name === "transcriptChunk") {
      const attrs = node.attrs;
      const textContent = node.textContent.trim();
      
      if (textContent) {
         if (currentGroup?.groupType === 'variable-webcam' && currentGroup.hasInstructions) {
          // Collect instructions instead of creating audio nodes
          currentInstructions.push(textContent);
        } else {

         let audioClipIndex 
         let indexInParentClip
         if(currentGroup.groupType=='variable-webcam'){
            audioClipIndex = null 
            indexInParentClip = indexInPlaceholder
            indexInPlaceholder+=1
         } else{
            audioClipIndex = clipIndex 
            clipIndex+=1
         }
        const audioNode = {
          clipId: attrs.clipId,
          sceneId: attrs.sceneId,
          clipIndex: audioClipIndex,
          indexInParentClip:indexInParentClip,
          textContent,
          parentWebcamClip: (currentGroup?.groupType === 'variable-webcam' ? currentGroup.groupId : null)
        };
        audioNodes.push(audioNode);
      }
      }
    }
    else if (node.type.name === "webcamRecordingChunk") {
      const attrs = node.attrs;
      const { firstWordIndex, lastWordIndex } = getChunkWordIndices(node);
      const transcriptChunk = {
        clipId: attrs.clipId,
        sceneId: attrs.sceneId,
        firstWordIndex,
        lastWordIndex
      };
      if (currentGroup?.groupType === 'webcam-recording') {
        currentWebcamTranscriptChunks.push(transcriptChunk);
      }
    }
  });

  if (currentGroup?.groupType === 'variable-webcam' && currentGroup.hasInstructions && currentInstructions.length > 0) {
    const instructions = currentInstructions.join('\n');
    const existingNode = variableWebcamNodes[variableWebcamNodes.length - 1];
    if (existingNode) {
        existingNode.instructions = instructions;
    }
}

  // Handle final webcam recording group if exists
  if (currentGroup?.groupType === 'webcam-recording' && currentWebcamTranscriptChunks.length > 0) {
    webcamRecordingNodes.push({
      clipId: currentGroup.groupId,
      sceneId: currentSceneId,
      clipIndex: clipIndex,
      chunks: currentWebcamTranscriptChunks,
      skippedWords:currentSkippedWords
    });
  }


  return {
    audioNodes,
    sceneNodes,
    webcamRecordingNodes,
    variableWebcamNodes,
    webcamHeaders
  };
};

export default getParsedNodes

function getNodeDimensions(pos, view, id) {
  const domNode = view.nodeDOM(pos);
  let dimensions = { top: 0};  
  if (domNode) {
    const titleDiv = domNode.querySelector('.editor-transcriptPanel-transcriptGroup-titleOuterContainer');
    if (titleDiv) {
      dimensions = {
        top: titleDiv.offsetTop + domNode.offsetTop,
      };
    }
  }
  return dimensions;
}


function parseSceneNode(node,pos,view){
  const domNode = view.nodeDOM(pos);
  let dimensions = { top: 0, height: 0 };
  if (domNode) {
    dimensions = {
      top: domNode.offsetTop,
      height: domNode.offsetHeight
    }
  }
  const title = node.textContent || 'Untitled Scene'
  return{
    sceneId: node.attrs.sceneId,
    sceneTitle:title,
    dimensions
  }
}


