import React, { useState, useEffect } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPCheckbox from './DPCheckbox';
import DPNumberInput from './DPNumberInput';
import DPTextInput from './DPTextInput'
import DPItemAnimationPanel from './DPItemAnimationPanel';
import AddMediaModal from '../popovers/addMediaPopover/AddMediaModal';
import {getOrgDomain} from '../../../utils/getOrgDomain'
import * as Tooltip from '@radix-ui/react-tooltip';  

const imageFillModeOptions = [
  { value: 'fill', label: 'Fill Image', iconName: 'fillImage' },
  { value: 'fit', label: 'Fit Image', iconName: 'fitImage' },        
];

const EditorDetailPanelImage = (props) => {
  
  const {element,handleDimensionsUpdatedFromDetailPanel,updateSlideElementMetadata,clipId} = props 
  const aspectRatio = element.metadata.original.width/element.metadata.original.height 

  const imageFillMode = element.metadata.isFill?'fill':'fit'
  const imageOpacity = element.metadata.opacity*100
  const cornerRounding = element.metadata.cornerRounding

  const {showReplaceImageMediaModal,handleSetShowReplaceImageMediaModal}=props

  // const [showMediaModal, setShowMediaModal] = useState(false);
  const [imageVariableName, setImageVariableName] = useState(element.metadata.variables[0] || '')


  const handleUpdateImageVariableName = (variableName) => {
    setImageVariableName(variableName)
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,variables:[variableName]})
  }  

  const isVariableImage = element.metadata.isVariable

  const setIsVariableImage = () =>{
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,isVariable:true,isFill:true})
  }

  const setIsStaticImage = () =>{    
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,isVariable:false})
  }

  const handleWidthChange = (newWidth) => {
    element.width = newWidth
    if (imageFillMode === 'fit') {
      element.height = Math.round(newWidth / aspectRatio)
    }
    handleDimensionsUpdatedFromDetailPanel()
  };


  const handleHeightChange = (newHeight) => {
    element.height = newHeight
    if (imageFillMode === 'fit') {
      element.width = Math.round(newHeight * aspectRatio)
    }
    handleDimensionsUpdatedFromDetailPanel()
  };



  const handleSetIsBGImage = (isBGImage) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,isBGImage:isBGImage,isFill:true,zOrder:-1})
  }  


  const handleUpdateIsFill = (value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,isFill:value})
  }  

  //TODO combine all the metadata stuff
  const handleUpdateCornerRounding= (value) => {
    //setCornerRounding(value)
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,cornerRounding:value})
  }  

  const handleUpdateOpacity= (value) => {
    const opacity = value/100
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,opacity:opacity})
  }  

  const handleFillModeChange = (newMode) => {
    if (newMode === 'fit') {
      const containerAspectRatio = element.width / element.height;
      if (containerAspectRatio > aspectRatio) {
        element.width = Math.round(element.height * aspectRatio);
      } else {
        element.height = Math.round(element.width / aspectRatio);
      }
      handleDimensionsUpdatedFromDetailPanel()
    }
    handleUpdateIsFill(newMode === 'fill')
  };

  const isBGImage = element.metadata.isBGImage || false

  let label = 'Image'
  if(isBGImage){
    label = 'Background Image'
  }
  if(isVariableImage){
    label = 'Variable Image'
  }

  const handleChangePositon = (axis,newValue) => {
    if(axis=='x'){
      element.x = newValue     
    }
    if(axis=='y'){
      element.y = newValue     
    }
  };

  const handleUpdateMetadata = (field,value) => { 
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,[field]:value})
  }  


  let showVariableImageOption = false 
  if(getOrgDomain()=='clay.com' || getOrgDomain()=='yarn.so'){
    showVariableImageOption = true
  }

 // const [variableAutoTrim, setVariableAutoTrim] = useState(false)
  // const [variableAreaScale, setAreaVariableScale] = useState(false)

  const variableAutoTrim = element.metadata.autoTrim || false 
  const variableAreaScale = element.metadata.areaScale || false

  const setVariableAutoTrim = (value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,autoTrim:value})
  }  

   const setAreaVariableScale = (value) => {
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,areaScale:value})
  }  


  const setMaxHeight = (value) =>{    
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,maxHeight:value})
  }

  const setMaxWidth = (value) =>{    
    updateSlideElementMetadata(clipId,element.id,{...element.metadata,maxWidth:value})
  }

  const defaultMaxHeight = element.height * 1.5 
  const defaultMaxWidth = element.width * 1.5



  // const [addVariableAreaMaxWidth, setAddVariableAreaMaxWidth] = useState(false)
  // // const [addVariableAreaMaxHeight, setAddVariableAreaMaxHeight] = useState(false)

  // const [variableAreaMaxWidth, setVariableAreaMaxWidth] = useState(element.width * 1.5)
  // const [variableAreaMaxHeight, setVariableAreaMaxHeight] = useState(element.height * 1.5)





  return (
    <>
      <div className='editor-detailPanel-header'>
        <div className='editor-detailPanel-header-label'>
          {label}
        </div>        
        <div className='editor-detailPanel-row-hSpacer' />

        {!isVariableImage && !isBGImage && showVariableImageOption &&
          <button onClick={() => setIsVariableImage()} className='dpButton dpButton--labelOnly dpButton--light'>             
            <div className='dpButton-label'>
              Make Variable
            </div>
          </button>     
        }
        {isVariableImage && !isBGImage && showVariableImageOption &&
          <button onClick={() => setIsStaticImage()} className='dpButton dpButton--labelOnly dpButton--light'> 
            {/*<div className='dpButton-iconContainer'>
              <Icon name={isVariableImage ? 'lockedFill' : 'locked'} />
            </div>
            */}
            <div className='dpButton-label'>
              Make Static
            </div>
          </button>     
        }


      </div>

      {/*}

         <button 
          onClick=
          data-active-state={isVariableImage}
          className='dpButton dpButton--iconOnly dpButton--mid dpButton--backgroundImage'
        >
          <div className='dpButton-iconContainer'>
            <Icon name='variants' />
          </div>
        </button>
      
       <div className='editor-detailPanel-row'>
        <DPNumberInput 
          min={-500}
          max={1920}          
          value={element.x}
          setValue={(value)=>{handleChangePositon('x',value)}}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='X'          
          width={79}
        />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <DPNumberInput
          min={-500}
          max={1920}               
          value={element.y}
          setValue={(value)=>{handleChangePositon('y',value)}}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='Y'
          width={79}
        />
        </div>
        */}



      <div className='editor-detailPanel-row'>
        <DPNumberInput 
          min={0}
          max={1920}          
          value={isBGImage ? 1920 : element.width}
          setValue={handleWidthChange}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='W'          
          disabled={isBGImage}
          width={79}
        />
        <div className='editor-detailPanel-row-smallHSpacer' />
        <DPNumberInput
          min={0}
          max={1920}               
          value={isBGImage ? 1080 : element.height}
          setValue={handleHeightChange} 
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          label='H'
          disabled={isBGImage}
          width={79}
        />
        <div className='editor-detailPanel-row-hSpacer' />

       
        {!isVariableImage &&
        <Tooltip.Root delayDuration={500}>
          <Tooltip.Trigger asChild> 
            <button 
              onClick={() => handleSetIsBGImage(!isBGImage)}
              data-active-state={isBGImage}
              className='dpButton dpButton--iconOnly dpButton--mid dpButton--backgroundImage'
            >
              <div className='dpButton-iconContainer'>
                <Icon name='backgroundImage' />
              </div>
            </button>
          </Tooltip.Trigger>                  
            <Tooltip.Content side="top" collisionPadding={10} className="tooltip tooltip--detailPanel tooltip--makeBGImage">
              {isBGImage ? 'Detach BG image' : 'Make BG image'}
            </Tooltip.Content>          
        </Tooltip.Root> 
        }

        
        
        
        
      </div>

      {!isVariableImage && 
        <>
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <button onClick={()=> handleSetShowReplaceImageMediaModal(true)} className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'>
            <div className='dpButton-label'>
              Replace Image...
            </div>
          </button>
          <AddMediaModal
            showPopover={showReplaceImageMediaModal}
            handleClosePopover={() => handleSetShowReplaceImageMediaModal(false)}          
            mediaType='image'
            handleNewImageFileUpload={(imageObj)=>{props.handleSlideImageFileUpload(imageObj,element.id)}}
            insertImageFromRecent={(imageObj)=>{props.insertImageFromRecent(imageObj,element.id)}}  
          />          
          </div>
        </>
      }

      {isVariableImage && 
        <>
        <div className='editor-detailPanel-divider' />
        <div className='editor-detailPanel-row'>
          <div className='editor-detailPanel-row-label'>
            Variable
          </div>
          <div className='editor-detailPanel-row-hSpacer' />            
          <DPTextInput            
            value={imageVariableName}
            setValue={handleUpdateImageVariableName}        
            width={120}
          />
        </div>
        <div className='editor-detailPanel-divider' />        
          <DPCheckbox            
            checked={variableAutoTrim}
            setChecked={setVariableAutoTrim}        
            label="Auto Trim"
            tight
          />     
          <div className='editor-detailPanel-divider' />   
          <DPCheckbox            
            checked={variableAreaScale}
            setChecked={setAreaVariableScale}        
            label="Area Scale"
            tight
          />

         {variableAreaScale && 
            <>
            
            <DPCheckbox            
              checked={element.metadata.maxWidth ? true : false}
              setChecked={() => {
                if (element.metadata.maxWidth) {
                  setMaxWidth(null)
                } else {
                  setMaxWidth(defaultMaxWidth)
                }
              }}        
              label="Set Max Width"
              tight
            />
            {element.metadata.maxWidth && 
                  <div className='editor-detailPanel-row'>
                    <div className='editor-detailPanel-row-label'>
                      Max Width
                    </div>
                    <div className='editor-detailPanel-row-hSpacer' />            
                    <DPNumberInput
                      min={0}
                      max={1920}               
                      value={element.metadata.maxWidth}
                      setValue={setMaxWidth} 
                      setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
                      iconName='doubleEndedArrowVertical'
                      width={79}
                    />
                  </div>

            }
            <DPCheckbox            
              checked={element.metadata.maxHeight ? true : false}
              setChecked={() => {
                if (element.metadata.maxHeight) {
                  setMaxHeight(null)
                } else {
                  setMaxHeight(defaultMaxHeight)
                }
              }}        
              label="Set Max Height"
              tight
            />
            {element.metadata.maxHeight&& 
                  <div className='editor-detailPanel-row'>
                    <div className='editor-detailPanel-row-label'>
                      Max Height
                    </div>
                    <div className='editor-detailPanel-row-hSpacer' />            
                    <DPNumberInput
                      min={0}
                      max={1920}               
                      value={element.metadata.maxHeight}
                      setValue={setMaxHeight} 
                      setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
                      iconName='doubleEndedArrowVertical'
                      width={79}
                    />
                  </div>
                }
              </>
            }
        </>
      }

      {!isBGImage && 
        <>
          <div className='editor-detailPanel-divider' />
          {!isVariableImage &&
          <div className='editor-detailPanel-row'>
            <DPToggleGrid
              items={imageFillModeOptions}
              activeItem={imageFillMode}
              onItemClick={handleFillModeChange}          
            />
          </div>
        }
          <div className='editor-detailPanel-divider' />
          <div className='editor-detailPanel-row'>
            <div className='editor-detailPanel-row-label'>
              Corner Rounding
            </div>
            <div className='editor-detailPanel-row-hSpacer' />            
            <DPNumberInput 
              min={0}
              max={1000}
              value={element.metadata.cornerRounding}
              setValue={handleUpdateCornerRounding}
              setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
              iconName='cornerRadius'              
            />
          </div>
      
        </>
      }

      <div className='editor-detailPanel-divider' />
      
      <div className='editor-detailPanel-row'>
        <div className='editor-detailPanel-row-label'>
          Opacity
        </div>
        <div className='editor-detailPanel-row-hSpacer' />            
        <DPNumberInput 
          percentage
          min={0}
          max={100}
          value={imageOpacity}
          setValue={handleUpdateOpacity}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          iconName='opacity'              
        />
      </div>

      <div className='editor-detailPanel-divider' />
      
      <DPItemAnimationPanel 
        item={element}
        handleUpdateMetadata={handleUpdateMetadata}
        activeStartTransition={props.element.metadata.startTransitionType}
        activeEndTransition={props.element.metadata.endTransitionType}
        itemType='image'
      />


    </>
  );
};

export default EditorDetailPanelImage;