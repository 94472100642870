import React, { useMemo, useState, useCallback } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Icon from '../../../misc/Icon';

const TranscriptPanelVariableWebcamHeader = (props) => {
  
  const { 
    hasInstructions, 
    isVariable, 
    y,
    deleteWebcamClipFromHeader,
    clipId,
    setWebcamHasInstructionsFromHeader,
    recordWebcamClipFromHeader } = props

  let cameraType = 'script'
  let title = 'Script'

  if(hasInstructions){
    cameraType = 'variable'
    title = 'Instructions'
  }

  return (
    <div style={{top: `${y}px`}} className='editor-transcriptPanel-webcamHeader'>          
        
      {!hasInstructions &&
        <button data-camera-type={cameraType} data-instructions-type={hasInstructions ? 'instructions' : 'script'}  className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--script'>
          {/*}
          <div  onClick={()=>{recordWebcamClipFromHeader(clipId)}}   className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--default'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              Script
            </div>
          </div>
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--hover'>          
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='cameraOutline' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              Record Now
            </div>
          </div>
          */}
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--permanent'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              Script
            </div>
          </div>
        </button>
      }

      {hasInstructions &&
        <button data-camera-type={cameraType} data-instructions-type={hasInstructions ? 'instructions' : 'script'}  className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--instructions'>
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--permanent'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              {title}
            </div>
          </div>
        </button>
      }

  
      <div className='editor-transcriptPanel-webcamHeader-hSpacer' />

      <DropdownMenu.Root modal={false}> 
        <DropdownMenu.Trigger asChild>
          
          <button data-instructions-type={hasInstructions ? 'instructions' : 'script'} className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--dropdown'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='ellipses' />
            </div>          
          </button>


        </DropdownMenu.Trigger>
             
        <DropdownMenu.Portal>
          <DropdownMenu.Content             
            align="end"
            alignOffset={-2}
            className='dropdownMenu dropdownMenu--webcamHeader forceDarkTheme'
          >
            
            {!hasInstructions &&
              <DropdownMenu.Item onSelect={()=>{setWebcamHasInstructionsFromHeader(clipId,true)}}>              
                Switch to Instructions...
              </DropdownMenu.Item>
            }

            {hasInstructions && 
              <DropdownMenu.Item onSelect={()=>{setWebcamHasInstructionsFromHeader(clipId,false)}}>              
                Switch to Script...
              </DropdownMenu.Item>
            }

            {/*}
            {hasInstructions && isVariable &&
              <DropdownMenu.Item onSelect={()=>{recordWebcamClipFromHeader(clipId)}} >              
                Record now       
              </DropdownMenu.Item>  
            }
            */}
            

            {/*}
            <DropdownMenu.Separator/>
            <DropdownMenu.Item>              
              Convert to voiceover
            </DropdownMenu.Item>
            */}
            <DropdownMenu.Separator/>
            <DropdownMenu.Item onSelect={()=>{deleteWebcamClipFromHeader(clipId)}}>              
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>       
        </DropdownMenu.Portal>   
      </DropdownMenu.Root>

      
      


    </div>
  );
};

const TranscriptPanelStaticWebcamHeader = (props) => {
  const { 
    hasInstructions, 
    isVariable, 
    y,
    deleteWebcamClipFromHeader,
    clipId } = props

  return (
    <div style={{top: `${y}px`}} className='editor-transcriptPanel-webcamHeader'>          

        <button data-camera-type='static' className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--static'>
          <div className='editor-transcriptPanel-webcamHeader-titleBtn-inner editor-transcriptPanel-webcamHeader-titleBtn-inner--permanent'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='speakerRectangleMedium' />
            </div>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-label'>
              Camera
            </div>
          </div>
        </button>

        <div className='editor-transcriptPanel-webcamHeader-hSpacer' />        




      <DropdownMenu.Root modal={false}> 
        <DropdownMenu.Trigger asChild>
          
          <button data-camera-type='static' className='editor-transcriptPanel-webcamHeader-titleBtn editor-transcriptPanel-webcamHeader-titleBtn--dropdown'>
            <div className='editor-transcriptPanel-webcamHeader-titleBtn-iconContainer'>
              <Icon name='ellipses' />
            </div>          
          </button>


        </DropdownMenu.Trigger>
             
        <DropdownMenu.Portal>
          <DropdownMenu.Content             
            align="end"
            alignOffset={-2}
            className='dropdownMenu dropdownMenu--webcamHeader forceDarkTheme'
          >
            
            <DropdownMenu.Item>              
              Re-record now
            </DropdownMenu.Item>
            <DropdownMenu.Item>           
              Edit as script...
            </DropdownMenu.Item>                        
            <DropdownMenu.Separator/>
            <DropdownMenu.Item>           
              Convert to voiceover
            </DropdownMenu.Item>            
            
            <DropdownMenu.Separator/>
            <DropdownMenu.Item onSelect={()=>{deleteWebcamClipFromHeader(clipId)}}>              
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>       
        </DropdownMenu.Portal>   
      </DropdownMenu.Root>
    </div>
  );
};

const TranscriptPanelWebcamHeaders = (props) => {
  return (
    <>    
      {props.webcamHeaders.map((header, index) => (
        header.isVariable ? (
          <TranscriptPanelVariableWebcamHeader 
            key={index}
            clipId={header.clipId}
            hasInstructions={header.hasInstructions}
            isVariable={header.isVariable}
            y={header.dimensions.top}
            deleteWebcamClipFromHeader={props.deleteWebcamClipFromHeader}
            setWebcamHasInstructionsFromHeader={props.setWebcamHasInstructionsFromHeader}
            recordWebcamClipFromHeader={props.recordWebcamClipFromHeader}
          />
        ) : (
          <TranscriptPanelStaticWebcamHeader 
            key={index}
            clipId={header.clipId}
            hasInstructions={header.hasInstructions}
            isVariable={header.isVariable}
            y={header.dimensions.top}
            deleteWebcamClipFromHeader={props.deleteWebcamClipFromHeader}
            recordWebcamClipFromHeader={props.recordWebcamClipFromHeader}
          />
        )            
      ))}      
    </>
  );
};

export default TranscriptPanelWebcamHeaders;