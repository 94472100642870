import React, {useRef, useState, useEffect} from 'react';
import Icon from '../misc/Icon';
import ReactPlayer from 'react-player/file';
import { animated, useSpring } from 'react-spring';
import RecordWebcamPreviewPreControls from './RecordWebcamPreviewPreControls'
import RecordWebcamPreviewControls from './RecordWebcamPreviewControls'

function RecordWebcamPreview({onReRecord,currentCaptureId,handleSubmit,submitLoading}) {
  const playerRef = useRef(null);    
  
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [lastPlayPauseChange, setLastPlayPauseChange] = useState(Date.now());
  const [showPreControls, setShowPreControls] = useState(true);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
  const loadVideo = async () => {
    try {
      const filePath = `webcamRecordings/${currentCaptureId}/original.webm`
      const buffer = await window.ipcRenderer.invoke('read-file-buffer', filePath);
      const blob = new Blob([buffer], { type: 'video/webm' });
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
    } catch (err) {
      console.error('Error loading video:', err);
    }
  };
  loadVideo();
  // Cleanup
  return () => {
    if (videoUrl) {
      URL.revokeObjectURL(videoUrl);
    }
  };
}, [currentCaptureId]);

  const handlePlay = () => {
    setPlaying(true);
    setHasPlayed(true);
    setLastPlayPauseChange(Date.now());    
    setShowPreControls(false);
  };

  const handlePause = () => {
    setPlaying(false);
    setLastPlayPauseChange(Date.now());
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const END_THRESHOLD = 0.99;

  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
      setProgressSeconds(state.playedSeconds);
      
      if (state.played >= END_THRESHOLD) {
        handleEnded();
      }
    }
  };

  const handleEnded = () => {
    setPlaying(false);
    setShowPreControls(true);
    
    setTimeout(() => {
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
      setProgress(0);
      setProgressSeconds(0);
    }, 50);
  };

  useEffect(() => {
    if (progress >= END_THRESHOLD && playing) {
      handleEnded();
    }
  }, [progress, playing]);

  const handleSeekChange = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);    
  };

  const handleSeekMouseUp = (newValue) => {
    const newProgress = parseFloat(newValue);
    const newProgressSeconds = newProgress * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(newProgress);
    }
    setProgress(newProgress);
    setProgressSeconds(newProgressSeconds);
    setSeeking(false);    
  };

  const handleError = (error) => {
    console.error('ReactPlayer error:', error);
  };

  const loadingSpring = useSpring({
    from: { opacity: 0.7, transform: 'translateX(-180px)' },
    to: { opacity: 0.15, transform: 'translateX(180px)' },
    loop: true,
    config: {
      duration: 2000,
      easing: t => t * (2 - t) // easeInOutCubic
    }
  });

  
  return (
    <>
      
      <div className='recordWebcamWindow--preview-videoContainer'>
        
        <ReactPlayer
          ref={playerRef}
          className='recordWebcamWindow--preview-video'
          width='100%'
          height='100%'        
          url={videoUrl}
          playing={playing}        
          progressInterval={20}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnded}
          onDuration={handleDuration}
          onProgress={handleProgress}
          onError={handleError}
          controls={false}
        />

        {showPreControls && 
          <RecordWebcamPreviewPreControls 
            duration={duration}
          />
        }
              
        {(!showPreControls || progress > 0) && duration > 0 && 
          <RecordWebcamPreviewControls           
            showControls
            playing={playing}
            onPlayPauseClick={() => {
              setPlaying(!playing);
              setLastPlayPauseChange(Date.now());
            }}
            progress={progress}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            duration={duration}          
          />
        }      

        <button onClick={() => setPlaying(!playing)} className='videoPlayer-bgPlayPauseBtn' />

        
      </div>      
      <div className='recordWebcamWindow--preview-toolbarContainer'>    
        
        <div className='recordWebcamWindow--preview-toolbar'>    
          <button data-visibility-state={submitLoading ? 'hidden' : 'visible'} onClick={() => onReRecord()} className='recordWebcamWindow--preview-toolbar-rerecordBtn'>    
            <Icon name='restartRecordMedium' />
          </button>

          <button onClick={handleSubmit} data-submit-loading={submitLoading ? true : false} className='recordWebcamWindow--preview-toolbar-upload'>    
            {!submitLoading && (
              <div className='recordWebcamWindow--preview-toolbar-upload-label'>
                Done
              </div>
            )}
            {submitLoading && (
              <div className='recordWebcamWindow--preview-toolbar-upload-bar'>
                <animated.div 
                  style={loadingSpring}
                  className='recordWebcamWindow--preview-toolbar-upload-bar-ball' 
                />
              </div>
            )}
          </button>

          <div className='recordWebcamWindow--preview-toolbar-rightSpacer' />    
          
        </div>
        
      </div>
    </>
  );
}

export default RecordWebcamPreview;