import React, { useState, useEffect, useRef, useMemo } from 'react'
import EditorTimelineClip from './clips/EditorTimelineClip'
import filter from 'lodash/filter'

const SceneTrack = (props) => {
   const {
    clips,
    isDragging,
    pixelsPerSec,
    sceneGap,
    trackIndex,
    TRACK_HEIGHT,
    activePanelClip,
    scenes,
    dndMode,
    dragClipId,
    devMode,
    CLIP_HEIGHT,
    ZOOM_CLIP_HEIGHT,
    scrollRef,
    setIsDraggingSegment,
    handleChangeSegmentPlaybackRate,
    cutClip,
    copyClip,
    duplicateClip,
    addFreezeFrame,
    addSkipSegment,
    changeVideoClipPlaybackRate,
    deleteClip,
    splitRecordingClip,
    toggleSkipSegment,
    handleChangeSkipSegmentDuration,
    removeFreeze,
    removeSkip,
    handleResizeSkipSegmentStart,
    handleResizeSkipSegmentStop,
    resizingSkipSegmentHandle,
    onDragStart,
    onDragEnd,
    onResize,
    onResizeStart,
    onResizeStop,
    handleSetActivePanelClip,
    handleSeek,
    dropLines,
    setDragDirection,
    dragDirection,
    isDnDMode,
    setShowDraggingCursor,
    handleDragClip,
    handleResizeStart,
  } = props;

  let yOffset = trackIndex === 0 ? 9 : trackIndex * TRACK_HEIGHT 

  const clipPositions = useMemo(() => {
    if (isDragging) return {}; // Don't recalculate while dragging
    return clips.reduce((acc, clip) => {
      const sceneGapOffset = clip.sceneIndex * sceneGap;
      acc[clip.id] = { 
        x: clip.startTime * pixelsPerSec + sceneGapOffset, 
        y: yOffset - 2
      };
      return acc;
    }, {});
  }, [clips, isDragging, pixelsPerSec, sceneGap, yOffset]);



// // // // // /////// TESTING

//   const renderCount = useRef(0);
//   const prevProps = useRef(props);

//   useEffect(() => {
//     renderCount.current += 1;
    
//     const changedProps = Object.entries(props).reduce((acc, [key, value]) => {
//       if (prevProps.current[key] !== value) {
//         acc[key] = {
//           from: prevProps.current[key],
//           to: value
//         };
//       }
//       return acc;
//     }, {});


//     if (Object.keys(changedProps).length > 0) {
//       console.log(`[SceneTrack] Render #${renderCount.current}. Changed props:`, changedProps);
//     } else {
//       console.log(`[SceneTrack] Render #${renderCount.current}. No props changed.`);
//     }
  
//     prevProps.current = props;
//   });
// // // // //   ///////////////////



  return (
    <>
      {clips.map(clip => {
        const isActive = (props.activePanelClip && props.activePanelClip.id==clip.id)?true:false
        const draggablePosition = clipPositions[clip.id] || { x: clip.startTime * pixelsPerSec + clip.sceneIndex * sceneGap, y: yOffset }   
        let clipDropLines = null
        if(isDragging && dragClipId ==clip.id ){
          clipDropLines=dropLines
        }

        let webcamChunks
        if(clip.type=='webcam' && clip.metadata.isVariable){
          webcamChunks=filter(clips,{parentWebcamClip:clip.id})
        }
 
        return(
          <EditorTimelineClip 
            key={clip.id}
            webcamChunks={webcamChunks}
            metadataJson={JSON.stringify(clip.metadata)}
            parentWebcamClip={clip.parentWebcamClip}
            draggablePositionX={draggablePosition.x}
            draggablePositionY={draggablePosition.y}
            clip={clip}
            startTime={clip.startTime}
            duration={clip.duration}
            isActive={isActive}
            scenes={scenes}
            sceneGap={sceneGap}
            handleChangeSegmentPlaybackRate={handleChangeSegmentPlaybackRate}
            dndMode={dndMode}
            isDragging={isDragging}
            dragClipId={dragClipId}
            pixelsPerSec={pixelsPerSec}
            yOffset={yOffset}
            devMode={devMode}
            TRACK_HEIGHT={TRACK_HEIGHT}
            CLIP_HEIGHT={CLIP_HEIGHT}
            ZOOM_CLIP_HEIGHT={ZOOM_CLIP_HEIGHT}
            scrollRef={scrollRef}
            setIsDraggingSegment={setIsDraggingSegment}
            cutClip={cutClip}
            copyClip={copyClip}
            duplicateClip={duplicateClip}
            addFreezeFrame={addFreezeFrame}
            addSkipSegment={addSkipSegment}
            changeVideoClipPlaybackRate={changeVideoClipPlaybackRate}
            deleteClip={deleteClip}
            splitRecordingClip={splitRecordingClip}
            toggleSkipSegment={toggleSkipSegment}
            handleChangeSkipSegmentDuration={handleChangeSkipSegmentDuration}
            removeFreeze={removeFreeze}
            removeSkip={removeSkip}
            handleResizeSkipSegmentStart={handleResizeSkipSegmentStart}
            handleResizeSkipSegmentStop={handleResizeSkipSegmentStop}
            resizingSkipSegmentHandle={resizingSkipSegmentHandle}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onResize={onResize}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
            handleSetActivePanelClip={handleSetActivePanelClip}
            handleSeek={handleSeek}
           // setDropLines={setDropLines}
            dropLines={clipDropLines}
            setDragDirection={setDragDirection}
            dragDirection={dragDirection}
            isDnDMode={isDnDMode}
            setShowDraggingCursor={setShowDraggingCursor}
            handleDragClip={handleDragClip}
            handleResizeStart={handleResizeStart}
            calculateAndSetDropLines={props.calculateAndSetDropLines}
            setIsResizing={props.setIsResizing}
            setResizeDirection={props.setResizeDirection}
            resizeDirection={props.resizeDirection}

            requiresUpdate = {clip.requiresUpdate}

          />
          )
        }
        
      )}
    </>
  );
};

export default SceneTrack


// export default React.memo(SceneTrack, (prevProps, nextProps) => {
//   // Custom comparison function
//   for (let key in prevProps) {
//     if (key === 'clips') {
//       if (JSON.stringify(prevProps.clips) !== JSON.stringify(nextProps.clips)) {
//         return false;
//       }
//     } else if (key === 'scenes') {
//       if (JSON.stringify(prevProps.scenes) !== JSON.stringify(nextProps.scenes)) {
//         return false;
//       }
//     } else if (prevProps[key] !== nextProps[key]) {
//       return false;
//     }
//   }
//   return true;
// });