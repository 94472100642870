import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import Icon from '../../../misc/Icon';

function EditorGeneratePopoverCodeBox({ content, type = 'plain', tight, minHeight, responseCode }) {
  const [tooltips, setTooltips] = useState([]);

  const handleCopyToClipboard = async () => {
    try {
      // Join all text parts to copy the complete string
      const fullText = content.map(part => part.text).join('');
      await navigator.clipboard.writeText(fullText);
      
      const newTooltip = {
        id: Date.now(),
        text: 'Copied'
      };
      setTooltips(prevTooltips => [...prevTooltips, newTooltip]);

      setTimeout(() => {
        setTooltips(prevTooltips => prevTooltips.filter(t => t.id !== newTooltip.id));
      }, 600);

    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const transitions = useTransition(tooltips, {
    from: { opacity: 0, transform: 'translateY(-2px) scale(0.8)' },
    enter: { opacity: 1, transform: 'translateY(-4px) scale(1)' },
    leave: { opacity: 0, transform: 'translateY(-12px) scale(1)' },
    config: (item, index, phase) => 
      phase === 'enter' 
        ? { tension: 600, friction: 18 }
        : { tension: 500, friction: 24, clamp: true },
    trail: 50
  });

  return (
    <div data-left-tag={type === 'postRequest' ? true : false} data-min-height={minHeight ? true : false} data-padding-type={tight ? 'tight' : 'default'} className="generatePopover-codeBlock-container">
      <div className="generatePopover-codeBlock-inner">
        <pre className="generatePopover-codeBlock-content">
          {content.map((part, index) => (
            <span 
              key={index} 
              className={`generatePopover-codeBlock-syntax-${part.type}`}
            >
              {part.text}
            </span>
          ))}
        </pre>
      </div>          

      {type === 'postRequest' && 
        <div className='generatePopover-codeBlock-POSTtag'>
          POST
        </div>
      }

      {responseCode && 
        <div className='generatePopover-codeBlock-responseCode'>
          {responseCode}
        </div>
      }

      

      <button 
        className="generatePopover-codeBlock-copyBtn"
        onClick={handleCopyToClipboard}
      >
        <div className="generatePopover-codeBlock-copyBtn-inner">
          <div className="generatePopover-codeBlock-copyBtn-icon">
            <Icon name="link" />
          </div>
          <div className="generatePopover-codeBlock-copyBtn-label">
            Copy
          </div>
        </div>
        
        {transitions((style, item) => (
          <animated.div 
            style={style} 
            className="generatePopover-codeBlock-tooltip-container"
          >
            <div className="generatePopover-codeBlock-tooltip">
              {item.text}
            </div>
          </animated.div>
        ))}
      </button>
    </div>
  );
}

export default EditorGeneratePopoverCodeBox;