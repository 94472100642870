import {webcamSizes as sizes, webcamPositions as positions, SLIDE_WIDTH, SLIDE_HEIGHT, FLOAT_CAMERA_INSET} from './webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from './calculateWebcamRectForSizeAndPosition'

export function updateWebcamLayoutForTime(
  updateClipMetadata,
  clip,
  selectedSize,
  selectedPosition,
  currentTime,
  slideClips,
  videoClips
) {
  const newWebcamLayout = {
    size: selectedSize,
    position: selectedPosition
  };

  // Check for overlapping slide clips first
  if (slideClips?.length > 0) {
    const overlappingSlideClip = slideClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );

    if (overlappingSlideClip) {
      updateClipMetadata(overlappingSlideClip.id, { webcamLayout: newWebcamLayout });
      return;
    }
  }

  // If no overlapping slide clip, check video clips
  if (videoClips?.length > 0) {
    const overlappingVideoClip = videoClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );
    if (overlappingVideoClip) {
      updateClipMetadata(overlappingVideoClip.id, { webcamLayout: newWebcamLayout });
      return;
    }
  }

  // If no overlapping clips found, update the webcam clip
  const newLayout = {
    ...clip.metadata.layout,
    size: selectedSize,
    position: selectedPosition
  };
  updateClipMetadata(clip.id, { layout: newLayout });
}




// export function updateWebcamLayoutForTime(updateClipMetadata,clip,selectedSize,selectedPostion,currentTime, slideClips,videoClips) {
//   //check for overlapping slide clips.  if there is update the slide clip metadata otherwise do the webcamp
//   const overlappingSlideClip = slideClips.find(clip => 
//     currentTime >= clip.startTime && currentTime <= clip.endTime
//   );

//   if(overlappingSlideClip){
//     let newWebcamLayout={
//       size:selectedSize,
//       position:selectedPostion
//     }
//     updateClipMetadata(overlappingSlideClip.id,{webcamLayout:newWebcamLayout})

//   }else{
//     let newLayout = {...clip.metadata.layout}
//     newLayout.size = selectedSize 
//     newLayout.position = selectedPostion 
//     updateClipMetadata(clip.id,{layout:newLayout})
//   }
// }