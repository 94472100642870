// const maxWidth = null

// export function scaleLogoVariableImage(element) {
//   const originalElementHeight = element.height;
//   const originalElementWidth = element.width;
//   const logoWidth = element.metadata.original.width;
//   const logoHeight = element.metadata.original.height;

//   // Calculate the original area
//   const originalArea = originalElementHeight * originalElementWidth;

//   // Calculate the aspect ratio of the logo
//   const logoAspectRatio = logoWidth / logoHeight;

//   // Calculate new dimensions that maintain the original area and logo's aspect ratio
//   const newHeight = Math.sqrt(originalArea / logoAspectRatio);
//   const newWidth = newHeight * logoAspectRatio;

//   // Update the element's dimensions
//   element.height = Math.round(newHeight);
//   element.width = Math.round(newWidth);
//   element.x -= (Math.round(newWidth) - originalElementWidth) / 2;
//   element.y -= (Math.round(newHeight) - originalElementHeight) / 2;

//   // console.log(`Original dimensions: ${originalElementWidth}x${originalElementHeight}`);
//   // console.log(`New dimensions: ${element.width}x${element.height}`);
// }

export function scaleLogoVariableImage(element) {
  const maxWidth = element.metadata.maxWidth;
  const maxHeight = element.metadata.maxHeight
  const originalElementHeight = element.height;
  const originalElementWidth = element.width;
  const logoWidth = element.metadata.original.width;
  const logoHeight = element.metadata.original.height;
  
  // Calculate the original area
  const originalArea = originalElementHeight * originalElementWidth;
  // Calculate the aspect ratio of the logo
  const logoAspectRatio = logoWidth / logoHeight;
  
  // Calculate new dimensions that maintain the original area and logo's aspect ratio
  let newHeight = Math.sqrt(originalArea / logoAspectRatio);
  let newWidth = newHeight * logoAspectRatio;
  
  // Apply maxWidth and maxHeight constraints if provided
  if (maxWidth && newWidth > maxWidth) {
    newWidth = maxWidth;
    newHeight = maxWidth / logoAspectRatio;
  }
  
  // Check if the height still exceeds maxHeight after width adjustment
  if (maxHeight && newHeight > maxHeight) {
    newHeight = maxHeight;
    newWidth = maxHeight * logoAspectRatio;
    
    // If width now exceeds maxWidth, readjust both dimensions
    if (maxWidth && newWidth > maxWidth) {
      newWidth = maxWidth;
      newHeight = maxWidth / logoAspectRatio;
    }
  }
  
  // Update the element's dimensions
  element.height = Math.round(newHeight);
  element.width = Math.round(newWidth);
  
  // Center the element by adjusting x and y coordinates
  element.x -= (Math.round(newWidth) - originalElementWidth) / 2;
  element.y -= (Math.round(newHeight) - originalElementHeight) / 2;
}