import React, { useState } from 'react'
import Icon from '../../../misc/Icon';
import SelectVoicePopover from './selectVoicePopover/SelectVoicePopover'
import {getNameForVoiceId} from '../../../../utils/voiceover/getNameForVoiceId'
import TranscriptPanelTitleBtn from './TranscriptPanelTitleBtn'

function TranscriptPanelFixedHeader({ 
  handleChangeActiveVoice,
  handleChangeVoiceoverPlaybackRate,
  activeVoiceoverPlaybackRate,
  activeVoiceId,  
  projectId,
  projectName,
  subtitlesType,
  handleChangeSubtitlesType,
  handleGenerateSRTFile,
  hideTranscriptPanel,
  displayTitleBtn,
  displayBottomBorder,
  scrollPosition
}) {
  
  const showRecordAll = false

  const [voiceDropdownOpen, setVoiceDropdownOpen] = useState(false)
  
  const voiceoverMatchType = null // default state   
  //const voiceoverMatchType = 'preview' // if you have webcam inputs and only webcam inputs, then you preview in e.g. sarah
  //const voiceoverMatchType = 'matched' // you have some camera that you've matched from  
  //const voiceoverMatchType = 'original' // you only have camera

  let voiceLabel
  let voiceSubLabel

  if(!voiceoverMatchType){
    voiceLabel = getNameForVoiceId(activeVoiceId)
  }  
  if(voiceoverMatchType){
    voiceLabel = 'Matched'
  }
  if(voiceoverMatchType === 'preview'){
    voiceSubLabel = getNameForVoiceId(activeVoiceId)
  }
  if(voiceoverMatchType === 'original'){
    voiceLabel = 'Original Audio'
  }

  return (
    <div className='editor-transcriptPanel-fixedHeader'>      

      <div data-visibility-state={displayTitleBtn ? 'visible' : 'hidden'} className='editor-transcriptPanel-fixedHeader-titleBtnContainer'>
      <TranscriptPanelTitleBtn
        projectId={projectId}
        projectName={projectName}
        subtitlesType={subtitlesType}
        handleChangeSubtitlesType={handleChangeSubtitlesType}
        handleGenerateSRTFile={handleGenerateSRTFile}            
        hideTranscriptPanel={hideTranscriptPanel}
        scrollPosition={scrollPosition}
      />
      </div>

      <div className='editor-transcriptPanel-fixedHeader-hSpacer' /> 


      {showRecordAll && 
        <button className='editor-transcriptPanel-fixedHeader-btn editor-transcriptPanel-fixedHeader-btn--recordAll'>
          <div className='editor-transcriptPanel-fixedHeader-btn-iconContainer'>
            <Icon name='cameraOutline' />
          </div>
          <div className='editor-transcriptPanel-fixedHeader-btn-label'>
            Record All
          </div>
        </button> 
      }           

      <SelectVoicePopover        
        activeSpeakerId={activeVoiceId}
        handleChangeActiveVoice={handleChangeActiveVoice}          
        handleClosePopover={() => setVoiceDropdownOpen(false)}   
        handleChangeVoiceoverPlaybackRate={handleChangeVoiceoverPlaybackRate}    
        activeVoiceoverPlaybackRate={activeVoiceoverPlaybackRate} 
        voiceoverMatchType={voiceoverMatchType}
      >
        <button disabled={voiceoverMatchType === 'matched' || voiceoverMatchType === 'original' } onClick={() => setVoiceDropdownOpen(!voiceDropdownOpen)} className='editor-transcriptPanel-fixedHeader-btn editor-transcriptPanel-fixedHeader-btn--voicePopover'>            
          <div className='editor-transcriptPanel-fixedHeader-btn-iconContainer'>
            <Icon name='waveform' />
          </div>
          <div className='editor-transcriptPanel-fixedHeader-btn-label'>
            {voiceLabel} {voiceSubLabel && <span className='editor-transcriptPanel-fixedHeader-btn-label-sub'>(Sarah)</span>}
          </div>
        </button>          
      </SelectVoicePopover>

      <div data-visibility-state={displayBottomBorder ? 'visible' : 'hidden'} className='editor-transcriptPanel-fixedHeader-bottomBorder'/>
      
    </div>
  );
}

export default TranscriptPanelFixedHeader;