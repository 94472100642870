import React, { useState } from 'react';
import Icon from '../misc/Icon';

const RecordWebcamPreviewPreControls = ({duration}) => {
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  
  return (          
    <div className='recordWebcamWindow--preview-preControls'>
      <div className='recordWebcamWindow--preview-preControls-iconContainer'>
        <Icon name='playbackPlay' />
      </div>
      <div className='recordWebcamWindow--preview-preControls-duration'>
        {formatDuration(duration)}
      </div>
    </div>
  );
};

export default RecordWebcamPreviewPreControls;