import React, { useState,useEffect } from 'react';
import { Rnd } from 'react-rnd';
import {webcamSizes as sizes, webcamPositions as positions,SLIDE_WIDTH,SLIDE_HEIGHT,FLOAT_CAMERA_INSET,webcamBorderRadius} from '../../../utils/webcam/webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from  '../../../utils/webcam/calculateWebcamRectForSizeAndPosition'
import {calculateWebcamSizeAndPositionFromRect} from '../../../utils/webcam/calculateWebcamSizeAndPositionFromRect'
import {getWebcamRectForTime} from '../../../utils/webcam/getWebcamRectForTime' 
import {updateWebcamLayoutForTime} from '../../../utils/webcam/updateWebcamLayoutForTime' 

const DRAG_HANDLE_SIZE = 90

const CameraEditor = (props) => {
  
  const {
    currentTime,
    slideClips,
    scalar,
    selectedWebcam,
    updateClipMetadata,
    setIsDraggingWebcam,
    isDraggingWebcam,
    setIsResizingWebcam, 
    setCameraPreviewRect,
    slideClipAtPlayhead,
    isResizingWebcam,
    videoClips,
    videoClipAtPlayhead
} =props


  const clip = selectedWebcam

  const rect = getWebcamRectForTime(clip.metadata.layout, currentTime, slideClips,videoClips)
  const selectedSize = calculateWebcamSizeAndPositionFromRect(rect).size
  const selectedPosition = calculateWebcamSizeAndPositionFromRect(rect).position
  const showPositionOptions = false
  const showSizeOptions = false


  useEffect(() => {
    setCameraPreviewRect(rect);
  }, [slideClipAtPlayhead,videoClipAtPlayhead]);

  const [previewDrop, setPreviewDrop] = useState(null); // Store the size name that would be selected    
  const [previewPosition, setPreviewPosition] = useState(null);


  const saveWebcamLayout = (size,position) => {
    updateWebcamLayoutForTime(
      updateClipMetadata,
      clip,
      size,
      position,
      currentTime,
      slideClips,
      videoClips
    );
  };



  const getBestPosition = (newSize, currentPosition) => {
    // First try to keep the exact same position
    if (newSize.validPositions.includes(currentPosition)) {
      return currentPosition;
    }
    // If exact position not available, look through alternative positions
    const currentPositionObj = positions.find(p => p.name === currentPosition);
    if (currentPositionObj) {
      // Try each alternative position in order
      for (const altPosition of currentPositionObj.altPositions) {
        if (newSize.validPositions.includes(altPosition)) {
          return altPosition;
        }
      }
    }
    // If no alternatives work, fall back to first valid position for new size
    return newSize.validPositions[0];
  };

 const handleSizeChange = (newSize) => {
    const bestPosition = getBestPosition(newSize, selectedPosition);
    // setSelectedSize(newSize);
    //setSelectedPosition(bestPosition);
    setCameraPreviewRect(calculateWebcamRectForSizeAndPosition(newSize.name, bestPosition))
    saveWebcamLayout(newSize.name,bestPosition)
    //setRect(calculateRect(newSize, bestPosition));
  };

  const handlePositionChange = (position) => {
    // setSelectedPosition(position);
    saveWebcamLayout(selectedSize.name,position)
    setCameraPreviewRect(calculateWebcamRectForSizeAndPosition(selectedSize.name, position));
  };

  const getAnchorPosition = (rect, anchor) => {
    switch(anchor) {
      case 'topLeft':
        return { x: rect.x, y: rect.y };
      case 'topRight':
        return { x: rect.x + rect.width, y: rect.y };
      case 'bottomLeft':
        return { x: rect.x, y: rect.y + rect.height };
      case 'bottomRight':
        return { x: rect.x + rect.width, y: rect.y + rect.height };
      default:
        return { x: rect.x, y: rect.y };
    }
  };

  // Calculate all possible position rects for current size
  const positionOptions = selectedSize.validPositions.map(positionName => ({
    positionName,
    rect: calculateWebcamRectForSizeAndPosition(selectedSize.name, positionName)
  }));

  // Get current position's anchor point type
  const currentPositionObj = positions.find(p => p.name === selectedPosition);
  const currentAnchor = currentPositionObj?.anchor || 'topLeft';

  // Calculate all possible size options with their best positions and anchors
  const sizeOptions = sizes.map(size => {
    const bestPosition = getBestPosition(size, selectedPosition);
    const rect = calculateWebcamRectForSizeAndPosition(size.name, bestPosition);
    const anchorPoint = getAnchorPosition(rect, currentAnchor);
    
    return {
      sizeName: size.name,
      rect,
      anchor: anchorPoint
    };
  });

  // Calculate distance between two points
  const getDistance = (point1, point2) => {
    const dx = point1.x - point2.x;
    const dy = point1.y - point2.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  // Find closest anchor point to current drag position
  const findClosestAnchorSize = (dragX, dragY) => {
    let closestSize = null;
    let minDistance = Infinity;

    sizeOptions.forEach(({ sizeName, anchor }) => {
      const distance = getDistance(
        { x: dragX, y: dragY },
        { x: anchor.x, y: anchor.y }
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestSize = sizeName;
      }
    });

    return closestSize;
  };

  const getCenterPoint = (rect) => ({
    x: rect.x + (rect.width / 2),
    y: rect.y + (rect.height / 2)
  });

  const findClosestValidPosition = (dragX, dragY) => {
    // Get center point of currently dragged rect
    const draggedCenter = getCenterPoint({
      x: dragX,
      y: dragY,
      width: selectedSize.width,
      height: selectedSize.height
    });

    // Only consider valid positions for current size
    const validPositionRects = selectedSize.validPositions.map(posName => ({
      positionName: posName,
      rect: calculateWebcamRectForSizeAndPosition(selectedSize.name, posName)
    }));

    let closestPosition = null;
    let minDistance = Infinity;

    validPositionRects.forEach(({ positionName, rect: posRect }) => {
      // Get center point of this position option
      const positionCenter = getCenterPoint(posRect);

      const distance = getDistance(
        draggedCenter,
        positionCenter
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPosition = positionName;
      }
    });

    return closestPosition;
  };


  //
   const calculatePreviewDimensions = (dragX, dragY) => {
    const currentPositionObj = positions.find(p => p.name === selectedPosition);
    const currentAnchor = currentPositionObj?.anchor || 'topLeft';
    
    // Get the fixed point (opposite corner)
    const fixedPoint = {
      x: rect.x,
      y: rect.y
    };

    // Adjust fixed point based on anchor
    switch(currentAnchor) {
      case 'bottomRight':
        fixedPoint.x = rect.x;
        fixedPoint.y = rect.y;
        break;
      case 'topRight':
        fixedPoint.x = rect.x;
        fixedPoint.y = rect.y + rect.height;
        break;
      case 'bottomLeft':
        fixedPoint.x = rect.x + rect.width;
        fixedPoint.y = rect.y;
        break;
      case 'topLeft':
        fixedPoint.x = rect.x + rect.width;
        fixedPoint.y = rect.y + rect.height;
        break;
    }

    // Calculate new dimensions
    let newRect = {
      width: Math.abs(dragX - fixedPoint.x),
      height: Math.abs(dragY - fixedPoint.y),
      x: Math.min(dragX, fixedPoint.x),
      y: Math.min(dragY, fixedPoint.y)
    };

    return newRect;
  };


  const getHandlePosition = (anchor, rect, isFullScreen) => {
    if (isFullScreen) {
    // If full screen, just return the top-left corner of the rect without offset
      return { x: rect.x, y: rect.y };
    }

    const handleSize = DRAG_HANDLE_SIZE;
    const thirdHandle = handleSize / 3;
    
    switch(anchor) {
      case 'topLeft':
        return {
          x: rect.x - thirdHandle,
          y: rect.y - thirdHandle
        };
      case 'topRight':
        return {
          x: rect.x + rect.width - thirdHandle - thirdHandle,
          y: rect.y - thirdHandle
        };
      case 'bottomLeft':
        return {
          x: rect.x - thirdHandle,
          y: rect.y + rect.height - thirdHandle - thirdHandle
        };
      case 'bottomRight':
        return {
          x: rect.x + rect.width - thirdHandle - thirdHandle,
          y: rect.y + rect.height - thirdHandle - thirdHandle
        };
      default:
        return {
          x: rect.x - thirdHandle,
          y: rect.y - thirdHandle
        };
    }
  };

  // Update drag handlers
  const handleResize = (e, data) => {
    const handleSize = DRAG_HANDLE_SIZE / scalar;
    const thirdHandle = handleSize / 3;
    
    const isFullScreen = selectedSize.name === 'fullScreen';

    // Adjust the drag position based on the anchor
    let adjustedX = data.x;
    let adjustedY = data.y;
    
    if (!isFullScreen) {
      switch(currentAnchor) {
        case 'topLeft':
          adjustedX += thirdHandle * scalar ;
          adjustedY += thirdHandle * scalar ;
          break;
        case 'topRight':
          adjustedX += thirdHandle * scalar * 2;
          adjustedY += thirdHandle * scalar;
          break;
        case 'bottomLeft':
          adjustedX += thirdHandle * scalar ;
          adjustedY += thirdHandle * scalar * 2;
          break;
        case 'bottomRight':
          adjustedX += thirdHandle * scalar * 2 ;
          adjustedY += thirdHandle * scalar * 2;
          break;
      }
    }

    const closestSize = findClosestAnchorSize(adjustedX, adjustedY);
    setPreviewDrop(closestSize);
    
    const newRect = calculatePreviewDimensions(adjustedX, adjustedY);
    setCameraPreviewRect(newRect);
  };


  const handleResizeStart = (e, data) => {
    setCameraPreviewRect(rect)
    setIsResizingWebcam(true);    
  };

  const handleResizeStop = (e, data) => {
    setIsResizingWebcam(false);    
    if (previewDrop) {
      const newSize = sizes.find(size => size.name === previewDrop);
      handleSizeChange(newSize);
    }
    setPreviewDrop(null);

  };

  const handlePositionDragStart = () => {
    setCameraPreviewRect(rect)
    setIsDraggingWebcam(true);
  };

  const handlePositionDrag = (e, data) => {
    // Update position in real time
    setCameraPreviewRect({
      ...rect,
      x: data.x,
      y: data.y
    });

    // Find and highlight closest valid position
    const closestPosition = findClosestValidPosition(data.x, data.y);
    setPreviewPosition(closestPosition);
  };

  const handlePositionDragStop = (e, data) => {
    setIsDraggingWebcam(false);    
    if (previewPosition) {
      saveWebcamLayout(selectedSize.name,previewPosition)
      handlePositionChange(previewPosition);
    }
    setPreviewPosition(null);
  };


  const handleBorderRadius = (webcamBorderRadius - 6) / scalar
  // console.log('webcamBorderRadius')
  // console.log(webcamBorderRadius)
  const handleBorderInset = '6%'
  //const handleBorderWidth = 6 / scalar
  const handleBorderWidth = 9

  const getHandleStyles = (anchor) => {    

    switch(anchor) {      
      case 'topRight':
        return {
          transform: 'scaleX(-100%)',
        };
      case 'bottomLeft':
        return {          
          transform: 'scaleY(-100%)',
        };
      case 'bottomRight':
        return {
          transform: 'scaleX(-100%) scaleY(-100%)',
        };
      default:
        return null;
    }
  };





  const handleStyles = getHandleStyles(currentAnchor);
  // Get current anchor position for Rnd initial position
  const currentSizeOption = sizeOptions.find(opt => opt.sizeName === selectedSize.name);
  const currentAnchorPosition = currentSizeOption?.anchor || { x: 0, y: 0 };

  const dragCursor = isDraggingWebcam ? 'grabbing' : 'grab'
  const resizeCursor = isResizingWebcam ? 'grabbing' : 'grab'
  
  

  return (
    <>    
       <div 
        className="cameraEditor-preview"
        data-state-resizing={isResizingWebcam ? 'true' : 'false'}
        data-state-dragging={isDraggingWebcam ? 'true' : 'false'}
        style={{ 
          width: `${rect.width}px`, 
          height: `${rect.height}px`,
          left: `${rect.x}px`,
          top: `${rect.y}px`,
        }}
      >
        Position: x={rect.x}, y={rect.y}
        <br />
        Size: w={rect.width}, h={rect.height}
      </div>


        {(isDraggingWebcam || showPositionOptions) && positionOptions.map(({ positionName, rect: posRect }) => (
          <div 
            key={positionName}
            style={{ 
              width: `${posRect.width}px`, 
              height: `${posRect.height}px`,
              left: `${posRect.x}px`,
              top: `${posRect.y}px`,
            }}
            className='cameraEditor-positionOption'
            data-selected={positionName === selectedPosition}
            data-preview-drop={positionName === previewPosition}
          />
        ))}

            
        {(isResizingWebcam || showSizeOptions) && sizeOptions.map(({ sizeName, rect }) => (
          <div 
            key={sizeName}
            style={{ 
              width: `${rect.width}px`, 
              height: `${rect.height}px`,
              left: `${rect.x}px`,
              top: `${rect.y}px`,
            }}
            className='cameraEditor-sizeOption'
            data-selected={sizeName === selectedSize.name}
            data-preview-drop={sizeName === previewDrop}
          />
        ))}

        {(isResizingWebcam || showSizeOptions) && sizeOptions.map(({ sizeName, anchor }) => (
          <div 
            key={sizeName}
            style={{               
              left: `${anchor.x}px`,
              top: `${anchor.y}px`,
            }}
            className='cameraEditor-sizeAnchor'
            data-selected={sizeName === selectedSize.name}
          />
        ))}

        <Rnd
          position={getHandlePosition(currentAnchor, rect, selectedSize.name === 'fullScreen')}
          size={{
            width: DRAG_HANDLE_SIZE,
            height: DRAG_HANDLE_SIZE,
          }}
          scale={scalar}
          onDragStart={handleResizeStart}
          onDrag={handleResize}
          onDragStop={handleResizeStop}
          enableResizing={false}
          dragAxis="both"
          bounds="parent"
          className={"cameraEditor-dragHandle " + (isDraggingWebcam ? ' cameraEditor-dragHandle--hide ' : ' cameraEditor-dragHandle--show ')}
          style={{                              
            cursor: resizeCursor
          }}
        >
          <div style={handleStyles} className='cameraEditor-dragHandle-iconContainer'>
            <svg width="28" height="28" viewBox="0 0 28 28">
              <defs>
                <path d="M4 24v-4.762C4 10.822 10.822 4 19.238 4H24" id="curvedPath"/>
              </defs>
              <g fill="none" fillRule="evenodd" strokeLinecap="round">
                <use strokeOpacity="0.15" stroke="#000" strokeWidth="8" xlinkHref="#curvedPath"/>
                <use stroke="#FFF" strokeWidth="5.714" xlinkHref="#curvedPath"/>
              </g>
            </svg>
          </div>

        </Rnd>

        {rect.width < SLIDE_WIDTH && 
          <Rnd
            position={{ 
              x: rect.x, 
              y: rect.y 
            }}
            size={{
              width: rect.width,
              height: rect.height,
            }}
            scale={scalar}
            onDragStart={handlePositionDragStart}
            onDrag={handlePositionDrag}
            onDragStop={handlePositionDragStop}
            enableResizing={false}
            dragAxis="both"
            bounds="parent"
            className="cameraEditor-positionHandle"
            style={{
              cursor: dragCursor
            }}
          />
        }
    
    </>
  );
};

export default CameraEditor;