import React, { useState, useEffect, useCallback } from 'react';
import Icon from '../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const cleanupLabel = (label) => {
  return label.replace(/\s*\(.*?\)\s*/g, '').trim();
};

const SettingsDropdown = ({ 
  options, 
  value, 
  onChange, 
  iconName, 
  selectHideVideo,
  setSelectHideVideo,
  isVideo
}) => {
  const activeOption = options && options.find(option => option.value === value);
  const buttonLabel = activeOption ? cleanupLabel(activeOption.label) : 'Select...';
  
  const handleSelect = useCallback((optionValue) => {
    onChange(optionValue);
  }, [onChange]);  

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button className='recordWebcamWindow-devices-btn'>          
            <Icon name={iconName} />          
        </button>
      </DropdownMenu.Trigger>
      
      <DropdownMenu.Content 
        align="start" 
        alignOffset={-20} 
        sideOffset={2} 
        side={'top'} 
        collisionPadding={10} 
        className='dropdownMenu'
      >
        {/* Regular device options */}
        {options && options.map((option) => (
          <DropdownMenu.Item
            key={option.value}
            onSelect={() => handleSelect(option.value)}
            className={`dropdownMenu-item ${option.value === value ? 'dropdownMenu-item--active' : 'dropdownMenu-item--inactive'}`}
          >
            {cleanupLabel(option.label)}
          </DropdownMenu.Item>
        ))}
                
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};


const RecordWebcamToolbarDevices = ({   
  activeCamera, 
  setActiveCamera, 
  activeMicrophone, 
  setActiveMicrophone,
  cameras,
  microphones,  
  isDisplayRecording  
}) => {
  

  return (    
      <div data-visibility-state={isDisplayRecording ? 'hidden' : 'visible'} className='recordWebcamWindow-devices'>        
        <SettingsDropdown
          options={microphones}
          value={activeMicrophone}
          onChange={setActiveMicrophone}
          iconName="microphoneRegular"
        />
        <SettingsDropdown
          options={cameras}
          value={activeCamera}
          onChange={setActiveCamera}
          iconName="cameraRegular"
          isVideo                 
        />
      </div>
  );
};

export default RecordWebcamToolbarDevices;