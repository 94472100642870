import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const RecordWebcamToolbarStartStopBtn = ({ 
  isRecording,  
  onStartRecording,  
  onStopRecording,  
  isReadyToRecord,
  isDisplayRecording
}) => {
  // Animation for the circle (recording off state)
  const circleSpring = useSpring({
    scale: isDisplayRecording ? 0 : 1,
    opacity: isDisplayRecording ? 0 : 1,
    config: {
      tension: 320,
      friction: 24
    }
  });

  // Animation for the square (recording on state)
  const squareSpring = useSpring({
    scale: isDisplayRecording ? 1 : 0,
    opacity: isDisplayRecording ? 1 : 0.5,
    delay: isDisplayRecording ? 250 : 0, // Only delay when entering
    config: {
      tension: 240,
      friction: 24,      
    }

  });

  return (
    <button 
      className='recordWebcamWindow-toolbar-startStopBtn'
      onClick={isDisplayRecording ? onStopRecording : onStartRecording}
      disabled={!isReadyToRecord}
    >      
      <div className='recordWebcamWindow-toolbar-startStopBtn-inner'>
        <animated.div 
          className='recordWebcamWindow-toolbar-startStopBtn-inner-square'
          style={{
            transform: squareSpring.scale.to(s => `translate(-50%, -50%) scale(${s})`),
            opacity: squareSpring.opacity
          }}
        />
        <animated.div 
          className='recordWebcamWindow-toolbar-startStopBtn-inner-circle'
          style={{
            transform: circleSpring.scale.to(s => `translate(-50%, -50%) scale(${s})`),
            opacity: circleSpring.opacity
          }}
        />
      </div>
    </button>
  );
};

export default RecordWebcamToolbarStartStopBtn;