import * as MP4 from "mp4box";

export class Parser {
  #mp4 = MP4.createFile();
  #offset = 0;
  #parser;
  #hasVideoTrack = false;


  constructor() {
    this.#parser = new TransformStream(
      {
        start: this.#start.bind(this),
        transform: this.#transform.bind(this),
        flush: this.#flush.bind(this),
      },
      // Buffer a single sample on either end
      { highWaterMark: 1 },
      { highWaterMark: 1 }
    );
  }

  get writable() {
    return this.#parser.writable;
  }

  get readable() {
    return this.#parser.readable;
  }

  get transformer() {
    return this.#parser;
  }

  get hasVideoTrack() {
    return this.#hasVideoTrack;
  }

  evictSamples(track_id, untilSample) {
    if (track_id === undefined) throw new Error("No track ID provided");
    this.#mp4.releaseUsedSamples(track_id, untilSample);
  }

  #start(controller) {
    this.#mp4.onError = (err) => {
      controller.error(err);
    };

    let tracks;
    const referenceMap = {};
    this.#mp4.onReady = (info) => {
      tracks = info.tracks.filter((track) => track.type === "video");
      // console.log('tracks')
      // console.log(tracks)

      if (tracks.length === 0) {
        this.#hasVideoTrack = false;
      //  controller.error("No video track found");
        return;
      }

     // console.log('HAS VIDEO');
      this.#hasVideoTrack = true;

      // if (tracks.length === 0) {
      //   controller.error("No video track found");
      //   return;
      // }else{
      //   console.log('HAS VIDEO')
      // }


      // We extract the first video track by track ID
      const track = tracks.sort((a, b) => a.id - b.id)[0];
      this.#mp4.setExtractionOptions(track.id, { nbSamples: 1 });
      //console.log("Found the main video track", track);

      // We also extract the reference video track
      // 'valp' is a custom reference type for alpha tracks
      const alphaTrack = track.references.find((ref) => ref.type === "valp");
      if (alphaTrack) {
        const alpha = tracks.find(
          (track) => track.id === alphaTrack.track_ids[0]
        );
        this.#mp4.setExtractionOptions(alpha.id, { nbSamples: 1 });
        referenceMap[track.id] = String(alpha.id);
        track.alphaTrack = alpha;
        //console.log("Found the alpha track", alpha);
      }
    };

    const sampleCache = {};
    this.#mp4.onSamples = (track_id, _, samples) => {
      const tkid = String(track_id);

      if (!sampleCache[tkid]) sampleCache[tkid] = {};
      for (const sample of samples) {
        sampleCache[tkid][sample.number] = sample;
      }

      // Get the main or reference track
      const isMain = referenceMap[tkid] !== undefined;
      const other =
        referenceMap[tkid] ??
        Object.entries(referenceMap)
          .find(([_, v]) => v === tkid)
          ?.map(([k, _]) => k)
          .shift();

      if (!other) {
        // There is no reference track, send the samples as is
        for (const sample of samples) {
          controller.enqueue({
            track: tracks.find((t) => t.id == track_id),
            video: sample,
          });
          delete sampleCache[tkid][sample.number];
        }
      }

      // On each call, check if we have both the main and reference tracks
      if (!sampleCache[tkid] || !sampleCache[other]) return;

      // Get the main track
      const track = isMain
        ? tracks.find((t) => t.id == track_id)
        : tracks.find((t) => t.id == other);

      // Check the common samples
      const mainSamples = Object.keys(sampleCache[tkid]);
      const refSamples = Object.keys(sampleCache[other]);
      const common = mainSamples.filter((sample) =>
        refSamples.includes(sample)
      );

      // Send the common samples
      for (const sample of common) {
        // Get the video and alpha samples
        let video, alpha;
        if (isMain) {
          video = sampleCache[tkid][sample];
          alpha = sampleCache[other][sample];
        } else {
          video = sampleCache[other][sample];
          alpha = sampleCache[tkid][sample];
        }

        // Merge the samples
        video.alphaSample = alpha;

        // Send the samples
        controller.enqueue({ track, video });

        // Clean up the cache
        delete sampleCache[tkid][sample];
        delete sampleCache[other][sample];
      }
    };

    this.#mp4.start();
  }

  #transform(chunk) {
   // console.log("Processing chunk", chunk.length);
    const copy = new Uint8Array(chunk);

    const buffer = copy.buffer;
    buffer.fileStart = this.#offset; // Add the offset to the buffer

    // Parse the data
    this.#mp4.appendBuffer(buffer);
    this.#mp4.flush();

    this.#offset += buffer.byteLength;
  }

  #flush() {
    this.#mp4.flush();
  }
}
