import React, { useState, useEffect, useRef } from 'react';
import Icon from '../misc/Icon';
import RecordWebcamToolbar from './RecordWebcamToolbar';

const MIN_FONT_SIZE = 18;
const MAX_FONT_SIZE = 36;
const RESIZE_DEBOUNCE_MS = 150;

const CONTAINER_PADDING = {
  top: 80,    // To account for title + its spacing
  bottom: 38,
  left: 38,
  right: 38
};

const RecordWebcamInstructions = ({
  videoRef,
  isRecording,
  onStartRecording,
  onStopRecording,
  onRestart,  
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  instructions,
  isReadyToRecord,
  isDisplayRecording  
}) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const measureRef = useRef(null);
  const resizeTimerRef = useRef(null);
  const [fontSize, setFontSize] = useState(MAX_FONT_SIZE);
  const [isSized, setIsSized] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    const calculateIdealFontSize = () => {
      if (!containerRef.current || !measureRef.current) return;

      const container = containerRef.current;
      const measureElement = measureRef.current;
      
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      let low = MIN_FONT_SIZE;
      let high = MAX_FONT_SIZE;
      
      const checkOverflow = (size) => {
        measureElement.style.fontSize = `${size}px`;
        void measureElement.offsetHeight;
        
        const isHeightOverflow = measureElement.scrollHeight > containerHeight;
        const isWidthOverflow = measureElement.scrollWidth > containerWidth;

        return isHeightOverflow || isWidthOverflow;
      };

      while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        
        if (checkOverflow(mid)) {
          high = mid - 1;
        } else {
          low = mid + 1;
        }
      }

      const finalSize = Math.max(MIN_FONT_SIZE, Math.min(high, MAX_FONT_SIZE));
      setFontSize(finalSize);
      setIsSized(true);
      setIsResizing(false);
    };
    
    setIsResizing(true);
    const timer = setTimeout(calculateIdealFontSize, 0);
    
    const handleResize = () => {
      setIsResizing(true);
      setIsSized(false);
      
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }
      
      resizeTimerRef.current = setTimeout(() => {
        calculateIdealFontSize();
      }, RESIZE_DEBOUNCE_MS);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }
    };
  }, [instructions]);

  const paragraphs = instructions ? instructions.split('\n').filter(p => p.trim().length > 0) : [];
  
  return (    
    <>
      <div className='recordWebcamWindow-mainContainer'>
        <div className='recordWebcamWindow-main recordWebcamWindow-main--instructions'>
          <div 
            className='recordWebcamWindow-main--instructions-panel'
            data-font-size-found={isSized}
            data-is-resizing={isResizing}
          >
            <div className='recordWebcamWindow-main--instructions-panel-title'>
              Instructions
            </div>
            <div 
              className='recordWebcamWindow-main--instructions-panel-innerContainer'
              ref={containerRef}
              style={{
                position: 'absolute',
                top: CONTAINER_PADDING.top,
                bottom: CONTAINER_PADDING.bottom,
                left: CONTAINER_PADDING.left,
                right: CONTAINER_PADDING.right,
                overflow: 'hidden',
                whiteSpace: 'pre-wrap'
              }}
            >
              <div 
                className='recordWebcamWindow-main--instructions-panel-innerText'
                ref={textRef}
                style={{
                  fontSize: `${fontSize}px`,
                  opacity: isResizing ? 0 : 1,
                  transition: 'opacity 75ms ease-in-out',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {paragraphs.map((paragraph, index) => (
                  <div 
                    key={index}
                    className='recordWebcamWindow-main--instructions-panel-paragraph'
                    style={{ marginBottom: index === paragraphs.length - 1 ? 0 : '0.7em' }}
                  >
                    {paragraph}
                  </div>
                ))}
              </div>
            </div>

            {/* Hidden measuring element */}
            <div 
              ref={measureRef}
              style={{
                position: 'absolute',
                top: CONTAINER_PADDING.top,
                left: CONTAINER_PADDING.left,
                width: `calc(100% - ${CONTAINER_PADDING.left + CONTAINER_PADDING.right}px)`,
                height: `calc(100% - ${CONTAINER_PADDING.top + CONTAINER_PADDING.bottom}px)`,
                visibility: 'hidden',
                overflow: 'hidden',
                whiteSpace: 'pre-wrap'
              }}
            >
              {paragraphs.map((paragraph, index) => (
                <div 
                  key={index}
                  className='recordWebcamWindow-main--instructions-panel-paragraph'
                  style={{ marginBottom: index === paragraphs.length - 1 ? 0 : '0.7em' }}
                >
                  {paragraph}
                </div>
              ))}
            </div>
          </div>
          <div className='recordWebcamWindow-main--instructions-hSpacer' />
          <div className='recordWebcamWindow-main--instructions-preview-videoContainer'>
            <video 
              ref={videoRef} 
              autoPlay 
              playsInline 
              muted
              className="recordWebcamWindow-main--instructions-preview-video"
            />
          </div>
        </div>
      </div>
      <RecordWebcamToolbar 
        isRecording={isRecording}
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
        onRestart={onRestart}        
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        activeMicrophone={activeMicrophone}
        setActiveMicrophone={setActiveMicrophone}
        cameras={cameras}
        microphones={microphones}
        isReadyToRecord={isReadyToRecord}
        isDisplayRecording={isDisplayRecording}        
      />
    </>
  );
};

export default RecordWebcamInstructions;