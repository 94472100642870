import { useState, useEffect, useRef } from 'react';
import Icon from '../misc/Icon'
import RecordWebcamToolbar from './RecordWebcamToolbar'
import RecordWebcamScriptCameraPreview from './RecordWebcamScriptCameraPreview'
import RecordWebcamScriptTeleSlider from './RecordWebcamScriptTeleSlider'
import RecordWebcamScriptTele from './RecordWebcamScriptTele'

const RecordWebcamScript = ({
  videoRef,
  isRecording,
  onStartRecording,
  onStopRecording,
  onRestart,  
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  script,
  isReadyToRecord,
  isDisplayRecording,  
}) => {
  
  const [teleprompterPace, setTeleprompterPace] = useState(0.5);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });  
  const [teleprompterProgress, setTeleprompterProgress] = useState(0);
  const measureRef = useRef(null);
  const [isDraggingSlider, setIsDraggingSlider] = useState(false);

  useEffect(() => {
    if (!isRecording) {
      setTeleprompterProgress(0); // Reset progress when recording stops
    }
  }, [isRecording]);


  useEffect(() => {
    if (!measureRef.current) return;

    const updateDimensions = () => {
      if (measureRef.current) {
        setDimensions({
          width: measureRef.current.offsetWidth,
          height: measureRef.current.offsetHeight
        });
      }
    };

    // Initial measurement
    updateDimensions();

    // Set up ResizeObserver for container
    const resizeObserver = new ResizeObserver(updateDimensions);
    resizeObserver.observe(measureRef.current);

    // Set up window resize listener
    window.addEventListener('resize', updateDimensions);

    return () => {
      if (measureRef.current) {
        resizeObserver.unobserve(measureRef.current);
      }
      window.removeEventListener('resize', updateDimensions);
    };
  }, [measureRef]); // Add dependency array

    const isPreviewing = !isRecording && isDraggingSlider


  return (    
    <>
      <div className='recordWebcamWindow-mainContainer'>
        <div className='recordWebcamWindow-main'>
          
          <RecordWebcamScriptTeleSlider
            value={teleprompterPace}
            onChange={setTeleprompterPace}
            isRecording={isRecording}
            isDraggingSlider={isDraggingSlider}
            setIsDraggingSlider={setIsDraggingSlider}
          />    

          <RecordWebcamScriptTele
            teleprompterPace={teleprompterPace}
            script={script || ''}
            containerWidth={dimensions.width}
            containerHeight={dimensions.height}
            isRunning={isRecording}
            onProgressChange={setTeleprompterProgress}
            progress={teleprompterProgress}
            isDraggingSlider={isDraggingSlider}
            isDisplayRecording={isDisplayRecording}
          />    

          <div ref={measureRef} className='recordWebcamWindow-main--script-measurer' />

        </div>
      </div>
      <RecordWebcamToolbar 
        isRecording={isRecording}
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
        onRestart={onRestart}        
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        activeMicrophone={activeMicrophone}
        setActiveMicrophone={setActiveMicrophone}
        cameras={cameras}
        microphones={microphones}
        isReadyToRecord={isReadyToRecord}
        isDisplayRecording={isDisplayRecording}        
      />
      <RecordWebcamScriptCameraPreview
        videoRef={videoRef} 
        isDisplayRecording={isDisplayRecording}
      />
      
    </>
  );
};

export default RecordWebcamScript;