import React, {Component} from 'react'
import {Switch,Route,useLocation} from "react-router-dom"
import { connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Mixpanel } from '../Mixpanel'
import ElectronMacDots from '../components/ElectronMacDots'
import ExportProgressTray from '../components/ExportProgressTray'
import AppBG from '../components/AppBG'
import LibraryPageContainer from './LibraryPageContainer'
import RecordWebcamContainer from './RecordWebcamContainer'
import {BrowserRouter as Router} from "react-router-dom"
const history = createBrowserHistory()

import Editor from '../components/editor/Editor'
import EditorWrapperContainer from './EditorWrapperContainer'
import RecordingWidgetContainer from './RecordingWidgetContainer'
import { Toaster } from 'sonner'
import RecordingControls from '../components/recordingWindow/RecordingControls'
import CursorGameContainer from './CursorGameContainer'
import TabBar from '../components/tabBar/TabBar'
import ExportWrapperContainer from './ExportWrapperContainer'
import OrgPusherContainer from './OrgPusherContainer'

//Routing
import MatchAuthenticated from '../components/routing/MatchAuthenticated'
import RedirectAuthenticated from '../components/routing/RedirectAuthenticated'
import LoginContainer from './auth/LoginContainer' 
import SettingsContainer from './settings/SettingsContainer'
import ServerSideExportPage from '../serverSideExport/ServerSideExportPage'
import ToastNotifications from '../components/ToastNotifications'
import AppShortcuts from './AppShortcuts'

import {checkWebVersion} from '../actions/webVersionCheck'




//use HashRouter instead of BrowserRouter
//https://stackoverflow.com/questions/36505404/how-to-use-react-router-with-electron

import {authenticateSession} from '../actions/auth'
import {handleDownloadFileSucess,handleImageDownloadSucess,handleBasicVideoDownloadSucess,handleMusicDownloadSucess} from '../actions/downloads'
import {maybeDownloadFailedRecording} from '../utils/assets/maybeDownloadFailedRecording'
import DynamicFontPreloader from './DynamicFontPreloader'


import {closeAllProjects} from '../actions/projects'
import UpdatesPage from '../components/changelog/UpdatesPage'

class AppContainer extends Component {

	constructor(){
		super()
		Mixpanel.track('app_load')
		if(window.ipcRenderer){
			window.isElectron=true
		}
	}

	componentDidMount() {	
		//authenticate session
		this.props.authenticateSession().then(() => {
		
				//this.props.loadInitialData()
			})

		if (window.ipcRenderer) {
			window.ipcRenderer.receive("log-message", this.logMessage)
			//window.ipcRenderer.receive("deeplink-redirect-message", this.deeplinkRedirect)
			window.ipcRenderer.receive("set-auth-token", this.setAuthToken)

			window.ipcRenderer.receive("file-download-success", this.handleFileDownloadSucess)
			window.ipcRenderer.receive("image-download-success", this.handleImageDownloadSucess)
			window.ipcRenderer.receive("music-download-success", this.handleMusicDownloadSucess)

			window.ipcRenderer.receive("basic-video-download-success", this.handleBasicVideoDownloadSucess)
			

			
			window.ipcRenderer.receive("file-download-error",this.handleFileDownloadError)
			window.ipcRenderer.receive("window-close-initiated",this.handleWindowCloseInitiated)

			window.ipcRenderer.receive("read-file-buffer-error",this.handleReadFileBufferError)
			
			window.ipcRenderer.receive('renderer-log', (message) => {
				console.log(message); // This will log once in the renderer
			});


		}

		this.pingApiInterval=setInterval(() => {
			this.props.checkWebVersion()
  		},60000)
		
	}

	componentWillUnmount() {
		if (window.ipcRenderer) {
			window.ipcRenderer.removeListener('renderer-log');
			window.ipcRenderer.removeListener("log-message", this.logMessage)
			//window.ipcRenderer.removeListener("deeplink-redirect-message", this.deeplinkRedirect)
			window.ipcRenderer.removeListener("set-auth-token", this.setAuthToken)

			window.ipcRenderer.removeListener("file-download-success", this.handleFileDownloadSucess)
			window.ipcRenderer.removeListener("file-download-error",this.handleFileDownloadError)
			window.ipcRenderer.removeListener("window-close-initiated",this.handleWindowCloseInitiated)
			window.ipcRenderer.removeListener("read-file-buffer-error",this.handleReadFileBufferError)

			window.ipcRenderer.removeListener("image-download-success", this.handleImageDownloadSucess)
			window.ipcRenderer.removeListener("music-download-success", this.handleMusicDownloadSucess)

			window.ipcRenderer.removeListener("basic-video-download-success", this.handleBasicVideoDownloadSucess)
		}
		if(this.pingApiInterval){
			clearInterval(this.pingApiInterval)
		}
	}

	handleWindowCloseInitiated=()=>{ //close all tabs to unlock projects
		this.props.closeAllProjects().then(() => {
    // This code will be executed after closeAllProjects has completed
		    if (window.ipcRenderer) {
		      window.ipcRenderer.send('cleanup-done');
		    }
		  }).catch((error) => {
		    console.error('Error in closing projects:', error);
		     if (window.ipcRenderer) {
		      window.ipcRenderer.send('cleanup-done');
		    }
		    // Optionally, handle any errors, such as sending a different IPC message to indicate failure
		  });
	}

	handleImageDownloadSucess=(event,data)=>{

		this.props.handleImageDownloadSucess(event.imageId)
	}


	handleMusicDownloadSucess=(event)=>{
		this.props.handleMusicDownloadSucess(event.trackId)
	}


	handleBasicVideoDownloadSucess=(event,data)=>{
		this.props.handleBasicVideoDownloadSucess(event.fileId)
	}

	handleFileDownloadSucess=(event,data)=>{
		this.props.handleDownloadFileSucess(event.captureId,event.fileName)
	}

	handleFileDownloadError=(event,data)=>{
		//console.log('file download error-----')
		//console.log(event)
	}

	handleReadFileBufferError=(event,data)=>{ // should only need to do this when we are trying to use recoridngs that are not in our org recordings list
		//April 16th take this out
		//maybeDownloadFailedRecording(event.path)
	}

	setAuthToken=(args)=>{
		this.props.authenticateSession()
		//this.setState({token:args})
	}

	logMessage( message){
	//	console.log('Main Process:', message)
	}

	


	render() {
		const { isAuthenticated, willAuthenticate } = this.props
		const authProps = { isAuthenticated, willAuthenticate }


		//const theme = "light"
		const theme = "dark"


		return (
			<div id="app" data-theme={theme}  className={"app " + (window.isElectron ? ' app--electron ' : ' app--web ' )}>				
			 	{/*<DynamicFontPreloader orgBrand={this.props.orgBrand}/>*/}

			 	<Router history={history}>



			 		<AppShortcuts/>
			 		
			 		<ElectronMacDots />

			 		{isAuthenticated &&
			 			<TabBar />
			 		}
			 		{isAuthenticated &&
						<OrgPusherContainer/>
					}

					{isAuthenticated && 

					<SettingsContainer/>
					}

					<ToastNotifications/>

					<ExportProgressTray />

					<Switch>
						<Route path='/server/:id' component={ServerSideExportPage}/>	
						{/*<Route path='/preview/:id' component={SlidePreviewPlaygroundPage}/>	*/}
						
						<RedirectAuthenticated path='/login' component={LoginContainer} {...authProps}/>
						{/*<Route path='/auth/google/callback' component={GoogleAuthCallbackContainer} {...authProps}/>*/}
						{/*<Route path='/scenes' component={ScenesPage}/>*/}
						{/*<Route path='/vo' component={VOClipsPlayground}/>*/}
						
						<Route path='/recording' component={RecordingWidgetContainer}/>
						{/*<Route path='/timeline' component={TimelinePlayground}/>					*/}
						<Route path='/export/:id' component={ExportWrapperContainer}/>
						{/*<Route path='/export' component={ExportContainer}/>*/}
					
						<MatchAuthenticated path='/library/collection/:id' component={LibraryPageContainer}  {...authProps}/>

						<MatchAuthenticated path='/library/:subPage' component={LibraryPageContainer}  {...authProps}/>

						<MatchAuthenticated path='/library' component={LibraryPageContainer}  {...authProps}/>
					
						{/*<Route path='/publish/:id' component={PublishPageContainer}/>*/}
						<Route path='/cursorGame' component={CursorGameContainer}/>

						<Route path='/recording-controls' component={RecordingControls}/>				

						<Route path='/updates' component={UpdatesPage}/>				

						<Route path='/record-webcam' component={RecordWebcamContainer}/>				

						
						
						<MatchAuthenticated path='/project/:id' component={EditorWrapperContainer}  {...authProps}/>

						<MatchAuthenticated path='/' component={LibraryPageContainer}  {...authProps}/>

					</Switch>
	  		</Router>



	  		

	  	</div>
		)
	}
}


const mapStateToProps = (state) => ({
	isAuthenticated: state.authenticationStatus.isAuthenticated,
	willAuthenticate: state.authenticationStatus.willAuthenticate,
	orgBrand:state.orgBrand
});


export default connect(
	mapStateToProps,
	{authenticateSession,
	handleDownloadFileSucess,
	closeAllProjects,
	handleImageDownloadSucess,
	handleBasicVideoDownloadSucess,
	handleMusicDownloadSucess,
	checkWebVersion

	})(AppContainer)

						

