export const adjustClipsDraggingRight = (sortedClips, draggedClipIndex, newEndTime, updatesArray, moveZoomsWithVideoClip, moveAudioWithWebcam, isAudioTrack, audioClipSpacing) => {
	 const needsSpacing = (clip1, clip2) => {
        if (!isAudioTrack) return false;
        if (!clip1 || !clip2) return true;
        
        // Only return false if both clips are webcam, not placeholders, and have same captureId
        if (clip1.type === 'webcam' && clip2.type === 'webcam' && 
            !clip1.metadata.isVariable && !clip2.metadata.isVariable && 
            clip1.captureId === clip2.captureId) {
            return false;
        }
        
        return true;  // All other cases need spacing
    };


    const draggedClip = sortedClips[draggedClipIndex];
    // Initial buffer based on the dragged clip and the next clip
    const initialBuffer = draggedClipIndex + 1 < sortedClips.length && 
        needsSpacing(draggedClip, sortedClips[draggedClipIndex + 1]) ? audioClipSpacing : 0;
    
    let lastEndTime = newEndTime + initialBuffer;
    
    for (let i = draggedClipIndex + 1; i < sortedClips.length; i++) {
        let currentClip = sortedClips[i];
        const initialStartTime = currentClip.relativeStartTime;

        if (currentClip.startTime < lastEndTime) {
            let newStart = lastEndTime;
            currentClip.startTime = newStart;
            currentClip.pinnedStartTime = null;
            updatesArray.push({ 
                clipId: currentClip.id, 
                relativeStartTime: currentClip.relativeStartTime,
                pinnedStartTime: null 
            });

            if (currentClip.type == 'video') {
                const finalStartTime = currentClip.relativeStartTime;
                const shiftAmount = finalStartTime - initialStartTime;
                updatesArray.push(moveZoomsWithVideoClip(currentClip.id, shiftAmount));
            }
            if (currentClip.type == 'webcam') {
                const finalStartTime = currentClip.relativeStartTime;
                const shiftAmount = finalStartTime - initialStartTime;
                updatesArray.push(moveAudioWithWebcam(currentClip.id, shiftAmount));
            }

            // Calculate buffer for next clip based on current clip and next clip
            const nextClip = sortedClips[i + 1];
            const nextBuffer = nextClip && needsSpacing(currentClip, nextClip) ? audioClipSpacing : 0;
            
            lastEndTime = newStart + currentClip.duration + nextBuffer;
        }
    }
    return updatesArray;
};