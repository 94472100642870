import React, { useState } from 'react';
import Icon from '../../misc/Icon';
import DPToggleGrid from './DPToggleGrid';
import DPItemAnimationPanel from './DPItemAnimationPanel';
import DPNumberInput from './DPNumberInput';
import DPSlider from './DPSlider';
import * as Tooltip from '@radix-ui/react-tooltip';
import DPTextInput from './DPTextInput'
import DPSelectMenu from './DPSelectMenu';
import DPCheckbox from './DPCheckbox'


// zoom - min 1, default 1, max 1.5
// xOffset – default 0, min - 1000, max 1000
// yOffset – default 0, min - 1000, max 1000

const borderOptions = [
	{value:'auto',   label:'Auto'},
	{value:'true',    label:'Show'},
	{value:'false', label:'Hide'},
]

const sizeOptions = [
	{value:'xSmallPortait',   label:'Tiny Tall'},
	{value:'xSmallSquare',    label:'Tiny Square'},
	{value:'xSmallLandscape', label:'Tiny Wide'},
	{value:'smallPortait',    label:'Small Tall'},
	{value:'smallSquare',     label:'Small Square'},
	{value:'smallLandscape',  label:'Small Wide'},
	{value:'mediumPortrait',  label:'Medium Tall'},
	{value:'mediumSquare',    label:'Medium Square'},
	{value:'mediumLandscape', label:'Medium Wide'},
	{value:'largePortrait',   label:'Large Tall'},
	{value:'largeSquare',     label:'Large Square'},
	{value:'largeLandscape',  label:'Large Wide'},
	{value:'halfFill',        label:'Half Fill'},
	{value:'overHalfFill',    label:'Over Half Fill'},
	{value:'fullScreen',      label:'Full Screen'}
];

const instructionsOptions = [
	{value:'script',   label:'Script'},		
	{value:'instructions', label:'Instructions'},
]


const EditorDetailPanelWebcam = (props) => {
	const {updateClipMetadata,clip,toggleTrimMode} = props
	const [showWebcamDetails, setShowWebcamDetails] = useState(false);
	const [size, setSize] = useState('Small Tall');
	const [border, setBorder] = useState('auto');

	const instructionsType = clip.metadata.hasInstructions?'instructions':'script'

	// const [instructionsType, setIntructionsType] = useState('script');

	const setInstructionsType=(value)=>{
		let hasInstructions = false 
		if(value=='instructions'){
			hasInstructions=true
		}
		updateClipMetadata(clip.id,{hasInstructions:hasInstructions})
		props.updateTranscripPanelFromTimeline()
	}

	const handleUpdateMetadata = (field,value) => { 
		updateClipMetadata(clip.id,{[field]:value})
	}  

	const handleUpdateColorAdjustment=(field,value)=>{
		let newColorAdjustments = {...clip.metadata.colorAdjustments }
		newColorAdjustments[field]=value
		updateClipMetadata(clip.id,{colorAdjustments:newColorAdjustments})
	}

	// const setHasInstructions = (value) =>{    
	// 	updateClipMetadata(clip.id,{hasInstructions:value})
	// 	//we need to force the transcript panel to update
	// 	props.updateTranscripPanelFromTimeline()
	// }

	const [webcamVariableName, setWebcamVariableName] = useState(
		(clip.metadata.variables && clip.metadata.variables[0]) || ''
	)

	const handleUpdateWebcamVariableName = (variableName) => {
		setWebcamVariableName(variableName)
		updateClipMetadata(clip.id,{variables:[variableName]})
	}  
	
	const setIsMuted=(value)=>{
		handleUpdateMetadata('isMuted',value)
		clip.updateIsMuted(value)//need to actually mute on the clip
	}

	const isVariableWebcam = clip.metadata.isVariable || false 

	const isMuted = clip.metadata.isMuted || false 


	// const handleUpdateVoiceType=(value)=>{
	// 	console.log('uodpate to ',value)
	// 	updateClipMetadata(clip.id,{audioTrack:value})
	// 	props.clip.handleUpdateAudioTrack()
	// }



	return (
		<>
			<div className='editor-detailPanel-header'>
				<div className='editor-detailPanel-header-label'>
					{isVariableWebcam ? 'Input Camera' : 'Camera' }
				</div>
				<div className='editor-detailPanel-header-vSpacer' />
				

				
			{/*}
			 {!isVariableWebcam && 
					<button onClick={() => setIsVariableWebcam()} className='dpButton dpButton--labelOnly dpButton--light'>             
						<div className='dpButton-label'>
							Make Variable
						</div>
					</button>     
				}
				{isVariableWebcam && 
					<button onClick={() => setIsStaticWebcam()} className='dpButton dpButton--labelOnly dpButton--light'> 
						<div className='dpButton-label'>
							Make Static
						</div>
					</button>     
				}
				*/}




				{!isVariableWebcam && 
					<Tooltip.Root delayDuration={0}>
						<Tooltip.Trigger asChild> 
							<button 
								onClick={() => setShowWebcamDetails(!showWebcamDetails)}
								data-active-state={showWebcamDetails}
								className='dpButton dpButton--iconOnly dpButton--detailMode'
							>
								<div className='dpButton-iconContainer'>
									<Icon name='galleryDisplayList' />
								</div>
							</button>   
						</Tooltip.Trigger>        
						<Tooltip.Portal>
							<Tooltip.Content side="left" className="tooltip tooltip--detailsMode">
								<span>{showWebcamDetails ? 'Hide Details' : 'Show Details'}</span>
							</Tooltip.Content>
						</Tooltip.Portal>
					</Tooltip.Root> 
				}
				

			</div>
				{/* <div className='editor-detailPanel-header-alignVSpacer' /> */}
				{/*}
				<DPToggleGrid
					items={filterStyles}
					activeItem={filterStyle}
					onItemClick={setActiveFilterStyle}      
				/>
				*/}

			{isVariableWebcam && 
				<div className='editor-detailPanel-row'>
					<div className='editor-detailPanel-row-label'>
						Provide      
					</div>
					<div className='editor-detailPanel-row-hSpacer' />
					<DPSelectMenu
						value={instructionsType}
						onValueChange={(value)=>{setInstructionsType(value)}}
						options={instructionsOptions}
						width={130}
					/>     
				</div>
			}


				{/*}
				{showVariableWebcamOption &&
					<>
						<div className='editor-detailPanel-divider' />
						
						<div className='editor-detailPanel-row'>
							{!isVariableWebcam && showVariableWebcamOption &&
								<button onClick={() => handleUpdateMetadata('isVariable', true)} className='dpButton dpButton--labelOnly dpButton--light'>             
									<div className='dpButton-label'>
										Make Variable
									</div>
								</button>     
							}
						
							{isVariableWebcam  && showVariableWebcamOption &&
								<button onClick={() => handleUpdateMetadata('isVariable', false)} className='dpButton dpButton--labelOnly dpButton--light'> 
									<div className='dpButton-label'>
										Make Static
									</div>
								</button>     
							}
						</div>
					</>
				}
				*/}
	
				{/*}
				{isVariableWebcam && 
					<>
					<div className='editor-detailPanel-divider' />
					
					<div className='editor-detailPanel-row'>
						<div className='editor-detailPanel-row-label'>
							Variable
						</div>
						
						<div className='editor-detailPanel-row-hSpacer' />            
							<DPTextInput            
								value={webcamVariableName}
								setValue={handleUpdateWebcamVariableName}        
								width={120}
							/>
						</div>
					</>
				}
				{/*{isVariableWebcam && 
					<>
					<div className='editor-detailPanel-divider' />
					
					<div className='editor-detailPanel-row'>
						<div className='editor-detailPanel-row-label'>
							Instructions
						</div>
						
						<div className='editor-detailPanel-row-hSpacer' />            
							<DPTextInput            
								value={variableWebcamInstructions}
								setValue={handleUpdateVariableWebcamInstructions}        
								width={120}
							/>
						</div>
					</>
				}*/}

{/*
				{isVariableWebcam && 
					<>
					<div className='editor-detailPanel-divider' />
					
					<div className='editor-detailPanel-row'>
						
						<div className='editor-detailPanel-row-hSpacer' />            
							<DPCheckbox
								label='Has Instructions'
								checked={clip.metadata.hasInstructions}
								setChecked={setHasInstructions}
							/>

						</div>
					</>
				}*/}


				{/*}
				<div className='editor-detailPanel-row'>
					<div className='editor-detailPanel-row-label'>
						Size              
					</div>
					<div className='editor-detailPanel-row-hSpacer' />
					<DPSelectMenu
						value={size}
						onValueChange={(value)=>{setSize(value)}}
						options={sizeOptions}
						width={130}
					/>     

				</div>
				<div className='editor-detailPanel-divider' />
				*/}
			 
			 {showWebcamDetails && 
			 <>
				<div className='editor-detailPanel-row'>
					<button 
						onClick={toggleTrimMode}          
						className='dpButton dpButton--labelOnly dpButton--fullWidth dpButton--strong'
					>
						<div className='dpButton-label'>
							Edit Trim
						</div>
					</button>
				</div>
				<div className='editor-detailPanel-divider' />
				</>
			}
			


												
			{!isVariableWebcam && 
				<>
				<DPSlider
					min={-1}
					max={1}          
					value={clip.metadata.colorAdjustments.exposure}
					setValue={(value)=>{handleUpdateColorAdjustment('exposure',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
					percentage
					label={showWebcamDetails ? 'Exposure' : 'Brightness'}
					centerZero
					step={0.01}
				/>         
			
				<DPSlider
					min={-1}
					max={1}          
					value={clip.metadata.colorAdjustments.whiteBalance}
					setValue={(value)=>{handleUpdateColorAdjustment('whiteBalance',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
					percentage          
					label={showWebcamDetails ? 'White Balance' : clip.metadata.colorAdjustments.whiteBalance < 0 ? 'Cold' : 'Warm'}
					centerZero
					whiteBalance
					step={0.01}
				/>   
				</>
			}


				{showWebcamDetails &&
					<>
						<DPSlider
							min={-1}
							max={1}          
							value={clip.metadata.colorAdjustments.highlights}
							setValue={(value)=>{handleUpdateColorAdjustment('highlights',value)}} 
							setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
							percentage
							label='Highlights'
							centerZero              
							step={0.01}
						/>
						<DPSlider
							min={-1}
							max={1}          
							value={clip.metadata.colorAdjustments.shadows}
							setValue={(value)=>{handleUpdateColorAdjustment('shadows',value)}} 
							setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
							percentage
							label='Shadows'
							centerZero              
							step={0.01}
						/>
						<DPSlider
							min={-1}
							max={1}          
							value={clip.metadata.colorAdjustments.brightness}
							setValue={(value)=>{handleUpdateColorAdjustment('brightness',value)}} 
							setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
							percentage
							label='Brightness'
							centerZero              
							step={0.01}
						/>
						<DPSlider
							min={-1}
							max={1}          
							value={clip.metadata.colorAdjustments.contrast}
							setValue={(value)=>{handleUpdateColorAdjustment('contrast',value)}} 
							setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
							percentage
							label='Contrast'
							centerZero              
							step={0.01}
						/>
						<DPSlider
							min={-1}
							max={1}          
							value={clip.metadata.colorAdjustments.saturation}
							setValue={(value)=>{handleUpdateColorAdjustment('saturation',value)}} 
							setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
							percentage
							label='Saturation'
							centerZero              
							step={0.01}
						/>


					</>
				}
					
					{/*}
					<DPSlider
						min={-1}
						max={1}          
						value={clip.metadata.colorAdjustments.contrast}
						setValue={(value)=>{handleUpdateColorAdjustment('contrast',value)}} 
						setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}            
						percentage
						label='Contrast'
					/>   
					*/}       


				<div className='editor-detailPanel-divider' />
				<div className='editor-detailPanel-row'>
					<div className='editor-detailPanel-row-label'>
						Border              
					</div>
					<div className='editor-detailPanel-row-hSpacer' />
					<DPSelectMenu
						value={border}
						onValueChange={(value)=>{setBorder(value)}}
						options={borderOptions}
						// width={100}
					/>     

				</div>
				
			 



			




			{showWebcamDetails &&
			<>
			<div className='editor-detailPanel-header-alignVSpacer' />
			{/* <div className='editor-detailPanel-divider' />

			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					Zoom
				</div>
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={0.5}
					max={1.5}          
					value={clip.metadata.zoom ||1}
					setValue={(value)=>{handleUpdateMetadata('zoom',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}              
					iconName='magnifyingGlass'
					width={79}
				/>
			 </div>


			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					X Offset
				</div>        
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={-1000}
					max={1000}          
					value={clip.metadata.xOffset ||0}
					setValue={(value)=>{handleUpdateMetadata('xOffset',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='X'          
					width={79}
				/>
			 </div>

			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					Y Offset
				</div>    
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={-1000}
					max={1000}          
					value={clip.metadata.yOffset ||0}
					setValue={(value)=>{handleUpdateMetadata('yOffset',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='Y'         
					width={79}
				/>
			 </div> */}
				<DPCheckbox
						label='Mute Webcam'
						checked={isMuted}
						setChecked={setIsMuted}
					/>


			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					Start Hidden
				</div>        
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={0}
					max={20}          
					value={clip.metadata.startHiddenDuration ||0}
					setValue={(value)=>{handleUpdateMetadata('startHiddenDuration',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='S'          
					width={79}
					step={0.1}
				/>
			 </div>

			<div className='editor-detailPanel-row'>
				<div className='editor-detailPanel-row-label'>
					End Hidden
				</div>    
				<div className='editor-detailPanel-row-hSpacer' />
				<DPNumberInput 
					min={0}
					max={20}          
					value={clip.metadata.endHiddenDuration ||0}
					setValue={(value)=>{handleUpdateMetadata('endHiddenDuration',value)}} 
					setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
					label='E'         
					width={79}
					step={0.1}
				/>
			 </div>

			 </>
			}
	 

			{showWebcamDetails && 
				<>
				<div className='editor-detailPanel-divider' />
		 
				<DPItemAnimationPanel 
					handleUpdateMetadata={handleUpdateMetadata}
					activeStartTransition={clip.metadata.startTransitionType}
					activeEndTransition={clip.metadata.endTransitionType}
					item={clip}
					itemType='webcam'
				/> 
				</>
			}
		
			{/*<div className='editor-detailPanel-divider' />

				<button onClick={() => handleUpdateVoiceType('original')} className='dpButton dpButton--labelOnly dpButton--light'>             
									<div className='dpButton-label'>
										Make normal
									</div>
								</button>    


				<button onClick={() => handleUpdateVoiceType('jasper')} className='dpButton dpButton--labelOnly dpButton--light'>             
									<div className='dpButton-label'>
										Make fun
									</div>
								</button>  
*/}
		</>
	);
};

export default EditorDetailPanelWebcam;