class TranscriptGroupNodeView {

	constructor(node, view, getPos) {
		this.dom = document.createElement('div');
		this.dom.classList.add('editor-transcriptPanel-transcriptGroup');

		this.bgDiv = document.createElement('div');
		this.bgDiv.classList.add('editor-transcriptPanel-transcriptGroup-bg');

		this.titleDiv = document.createElement('div');
		this.titleDiv.classList.add('editor-transcriptPanel-transcriptGroup-titleOuterContainer');


		this.contentDOM = document.createElement('div');
		this.contentDOM.classList.add('transcript-group-content');

		// Apply initial styles
		this.updateStyles(node.attrs.groupType,node.attrs.isProcessing,node.attrs.hasInstructions);

		this.dom.appendChild(this.bgDiv);
		this.dom.appendChild(this.titleDiv);
		this.dom.appendChild(this.contentDOM);
	}

	
	updateStyles(groupType,isProcessing,hasInstructions) {

		// Remove all possible state classes first
		this.dom.classList.remove(
			'editor-transcriptPanel-transcriptGroup--variableWebcam',
			'editor-transcriptPanel-transcriptGroup--variableWebcam--instructions',
			'editor-transcriptPanel-transcriptGroup--variableWebcam--script',
			'editor-transcriptPanel-transcriptGroup--processing',
			'editor-transcriptPanel-transcriptGroup--cameraRecording',
		);
		///Temp for nicole is processing styling	
		this.bgDiv.style.backgroundColor = '';
		////
		
		// Add the appropriate class
		if (groupType === 'variable-webcam') {
			// this.dom.classList.add('editor-transcriptPanel-transcriptGroup--cameraDraft');
			this.dom.classList.add('editor-transcriptPanel-transcriptGroup--variableWebcam');            
			// Add specific state class based on instructions
			if (hasInstructions) {
				this.dom.classList.add('editor-transcriptPanel-transcriptGroup--variableWebcam--instructions');
			} else {
				this.dom.classList.add('editor-transcriptPanel-transcriptGroup--variableWebcam--script');
			}
		} else if (groupType === 'webcam-recording') {
			this.dom.classList.add('editor-transcriptPanel-transcriptGroup--cameraRecording');
		}
		
		if (isProcessing) {
			this.dom.classList.add('editor-transcriptPanel-transcriptGroup--processing');
			///temp for nicole is processing styling
			this.bgDiv.style.backgroundColor = 'rgba(255, 192, 203, 0.3)';
			/////
		}
	}

	 update(node) {
		this.updateStyles(node.attrs.groupType,node.attrs.isProcessing,node.attrs.hasInstructions);
		return true;
	}
}

export default TranscriptGroupNodeView;