import React, { useState, useEffect, useRef } from 'react';
import RecordRTC from 'recordrtc';
import RecordWebcamClean from './RecordWebcamClean';
import RecordWebcamScript from './RecordWebcamScript';
import RecordWebcamInstructions from './RecordWebcamInstructions';

const RecordWebcamRecord = ({
  script,
  instructions,
  layout,
  onRecordingComplete
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isDisplayRecording, setIsDisplayRecording] = useState(false);
  const [videoStreamReady, setVideoStreamReady] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [microphones, setMicrophones] = useState([]);
  const [activeCamera, setActiveCamera] = useState('');
  const [activeMicrophone, setActiveMicrophone] = useState('');
  const [isWindowVisible, setIsWindowVisible] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  
  const videoRef = useRef(null);
  const mountedRef = useRef(false);

  const [audioAssets, setAudioAssets] = useState({
    start: null,
    stop: null
  });

  // Audio setup effect
  useEffect(() => {
    const startAudio = new Audio('/recordStart.mp3');
    const stopAudio = new Audio('/recordEnd.mp3');
    
    startAudio.volume = 0.05;
    stopAudio.volume = 0.05;

    Promise.all([
      startAudio.load(),
      stopAudio.load()
    ]).then(() => {
      setAudioAssets({
        start: startAudio,
        stop: stopAudio
      });
    });
  }, []);

  // Window visibility listener
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsWindowVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    setIsWindowVisible(!document.hidden); // Initial state

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Cleanup effect
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      if (recorder) {
        recorder.destroy();
      }
      cleanupMediaStream();
    };
  }, []);

  const cleanupMediaStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
    setVideoStreamReady(false);
  };

  // Video element ready state handler
  useEffect(() => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    const handleCanPlay = () => {
      if (mountedRef.current) {
        setVideoStreamReady(true);
      }
    };

    videoEl.addEventListener('loadedmetadata', handleCanPlay);
    videoEl.addEventListener('loadeddata', handleCanPlay);

    return () => {
      videoEl.removeEventListener('loadedmetadata', handleCanPlay);
      videoEl.removeEventListener('loadeddata', handleCanPlay);
    };
  }, [videoRef.current]);

  // Device enumeration - only when window is visible and not in preview mode
  useEffect(() => {
    if (!isWindowVisible || isPreviewMode) return;

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices
        .filter(device => device.kind === 'videoinput')
        .map(device => ({
          value: device.deviceId,
          label: device.label || 'Camera ' + (cameras.length + 1)
        }));
      
      const audioDevices = devices
        .filter(device => device.kind === 'audioinput')
        .map(device => ({
          value: device.deviceId,
          label: device.label || 'Microphone ' + (microphones.length + 1)
        }));

      setCameras(videoDevices);
      setMicrophones(audioDevices);
      
      if (videoDevices.length > 0 && !activeCamera) {
        setActiveCamera(videoDevices[0].value);
      }
      if (audioDevices.length > 0 && !activeMicrophone) {
        setActiveMicrophone(audioDevices[0].value);
      }
    });
  }, [isWindowVisible, isPreviewMode]);

  // Media stream setup - only when window is visible and not in preview mode
  useEffect(() => {
    if (!isWindowVisible || !activeCamera || !activeMicrophone || isPreviewMode) {
      cleanupMediaStream();
      return;
    }

    const constraints = {
      video: {
        deviceId: activeCamera ? { exact: activeCamera } : undefined,
        width: { ideal: 1280 },
        height: { ideal: 720 }
      },
      audio: {
        deviceId: activeMicrophone ? { exact: activeMicrophone } : undefined,
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: 44100,
      }
    };

    let isMounted = true;

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        if (!isMounted || isPreviewMode) {
          // If we switched to preview mode while getting the stream, clean it up
          stream.getTracks().forEach(track => track.stop());
          return;
        }
        
        setMediaStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.load();
        }
      })
      .catch(error => {
        console.error('Error accessing media devices:', error);
        if (isMounted) {
          setVideoStreamReady(false);
        }
      });

    return () => {
      isMounted = false;
      cleanupMediaStream();
    };
  }, [isWindowVisible, activeCamera, activeMicrophone, isPreviewMode]);

  const handleStartRecordingClick = async () => {
    if (!mediaStream || !audioAssets.start) return;

    setIsDisplayRecording(true);
    await audioAssets.start.play();
    await new Promise(resolve => setTimeout(resolve, 550));

    const recordRTC = new RecordRTC(mediaStream, {
      type: 'video',
      mimeType: 'video/webm;codecs=vp9,opus',    
      videoBitsPerSecond: 2000000,
      audioBitsPerSecond: 128000
    });

    recordRTC.startRecording();
    setRecorder(recordRTC);
    setIsRecording(true);
  };

// const handleStopRecordingClick = () => {
//   if (!recorder || !audioAssets.stop) return;

//   setIsDisplayRecording(false);
//   recorder.stopRecording(() => {
//     audioAssets.stop.play();

//     RecordRTC.getSeekableBlob(recorder.getBlob(), async (seekableBlob) => {
//       try {
//         // // If you need to save the file:
//         // const arrayBuffer = await seekableBlob.arrayBuffer();
//         // const result = await window.ipcRenderer.invoke('save-webcam-recording', {
//         //   arrayBuffer,
//         //   captureId: currentCaptureId
//         // });

//         // if (!result.success) {
//         //   throw new Error(result.error);
//         // }

//         // Clean up streams before transitioning to preview mode
//         cleanupMediaStream();
//         setIsPreviewMode(true);

//         // Call the callback that uses the finalized blob
//         onRecordingComplete(seekableBlob);

//         setIsRecording(false);

//         if (recorder.stream) {
//           recorder.stream.stop();
//         }
//         recorder.destroy();
//         setRecorder(null);

//       } catch (err) {
//         console.error('Error saving recording:', err);
//       }
//     });
//   });
// };




  const handleStopRecordingClick = () => {
    if (!recorder || !audioAssets.stop) return;

    setIsDisplayRecording(false);
    recorder.stopRecording(async () => {
      audioAssets.stop.play();
      
      try {
        const blob = recorder.getBlob();
       // const buffer = await blob.arrayBuffer();
        
        //const filePath = await window.ipcRenderer.invoke('save-webcam-recording', buffer);
        
        // Clean up streams before transitioning to preview mode
        cleanupMediaStream();
        setIsPreviewMode(true);
        
        onRecordingComplete(blob);
        
        setIsRecording(false);
        if (recorder.stream) {
          recorder.stream.stop();
        }
        recorder.destroy();
        setRecorder(null);
      } catch (err) {
        console.error('Error saving recording:', err);
      }
    });
  };

  const handleRestartClick = () => {
    if (recorder) {
      recorder.destroy();
      setRecorder(null);
    }
    setIsRecording(false);
    setIsDisplayRecording(false);
    audioAssets.stop.play();
  };

  const isReadyToRecord = (videoStreamReady || isRecording) && mediaStream !== null;

  const sharedProps = {
    videoRef,
    isRecording,
    isDisplayRecording,
    onStartRecording: handleStartRecordingClick,
    onStopRecording: handleStopRecordingClick,
    onRestart: handleRestartClick,
    activeCamera,
    setActiveCamera,
    activeMicrophone,
    setActiveMicrophone,
    cameras,
    microphones,
    isReadyToRecord
  };

  return (    
    <>
      {layout === 'script' && 
        <RecordWebcamScript 
          {...sharedProps}
          script={script}              
        />
      }
      {layout === 'clean' && 
        <RecordWebcamClean 
          {...sharedProps}
        />
      }
      {layout === 'instructions' && 
        <RecordWebcamInstructions
          {...sharedProps}
          instructions={instructions}              
        />
      }
    </>
  );
};

export default RecordWebcamRecord;