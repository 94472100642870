import React, {useState, useEffect} from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../misc/Icon';
import DPTextArea from '../../detailPanel/DPTextArea';
import EditorGeneratePopoverColabPageVariables from './EditorGeneratePopoverColabPageVariables'
import EditorGeneratePopoverColabPageLinks from './EditorGeneratePopoverColabPageLinks'
import EditorGeneratePopoverInfoBox from './EditorGeneratePopoverInfoBox';


const INITIAL_FIELDS = {
  headline: '',
  subtitle: '',
  enableSelectBGMusic: false,
  enableSelectBGColor: false,
};


function EditorGeneratePopoverColabPage({ 
    goBack,
    project,
    updateCollabPageSettings,
    stringVariables,
    variableWebcams,
    updateClipMetadata
  }) {
  
  const pageSettings = project.collab_page_settings

  const [fields, setFields] = useState(() => ({
    ...INITIAL_FIELDS,
    ...project.collab_page_settings,
  }));


  const handleFieldUpdate = (fieldName, value) => {
    const newFields = {
      ...fields,
      [fieldName]: value,
    };
    setFields(newFields);
    updateCollabPageSettings(newFields);
  };
  
  const hideSubtitleOption = true
  const [addPageSubtitle, setAddPageSubtitle] = useState(false);
  const showSubtitleInput = fields.subtitle || (fields.subtitle && fields.subtitle.length > 0)
  const showAddSubtitleButton = !fields.subtitle
  const hasStringVariables = stringVariables && stringVariables.length > 0

 
  return (
    <>
    {/*}
    <div className='generatePopover-header'>
      <button onClick={() => goBack()} className='generatePopover-header-backBtn'>
        <Icon name='chevronRight' />
      </button>
      <div className='generatePopover-header-label'>
        Colab Page
      </div>
      <div className='generatePopover-header-bottomBorder' />
    </div>
    */}
    
    <div className='generatePopover-main'>
      <div className='generatePopover-tabDescription'>
        Send people a link where they can individually record webcam, voice, or make custom selections.
      </div>

      <div className='generatePopover-section generatePopover-section--pageHeader'>

        <div className='generatePopover-section-header'>
          Page Header          
          <EditorGeneratePopoverInfoBox 
            label='You can insert string variables in the header text, like {firstName}.'
            //label='These will appear at the top of the page. It can be long, and is a great place to write instructions or a message.'
          />      
        </div>

        <div className='generatePopover-section--pageHeader-textAreaContainer'>
          <DPTextArea
            value={fields.headline}
            setValue={(value) => handleFieldUpdate('headline', value)}
            placeholder='Use headline to provide context or instructions...'
          />   
        </div>  

        {!hideSubtitleOption && showSubtitleInput &&
          <>
            <div className='generatePopover-section-header generatePopover-section--pageHeader-subtitle'>
              Page Subtitle
            </div>
            <div className='generatePopover-section--pageHeader-textAreaContainer'>
              <DPTextArea
                value={fields.subtitle}
                setValue={(value) => handleFieldUpdate('subtitle', value)}
                placeholder='Add additional context...'
              />   
            </div>  
          </>
        }
        
        {!hideSubtitleOption && !addPageSubtitle && 
          <div className='generatePopover-section-footer'>
            <button 
              onClick={() => setAddPageSubtitle(!addPageSubtitle)}
              className='generatePopover-section-footer-lightBtn'
            >
              {addPageSubtitle ? 'Hide Page Subtitle' : 'Add Page Subtitle'}
            </button>      
          </div>
        }

      </div>

      <div className='generatePopover-divider' />

      <EditorGeneratePopoverColabPageVariables 
        stringVariables={stringVariables} 
        variableWebcams={variableWebcams}
        updateClipMetadata={updateClipMetadata}
        enableSelectBGMusic={fields.enableSelectBGMusic}
        enableSelectBGColor={fields.enableSelectBGColor}
        handleFieldUpdate={handleFieldUpdate}
        />    

      <div className='generatePopover-divider' />

      <EditorGeneratePopoverColabPageLinks 
        stringVariables={stringVariables}
        projectName={project.name}
        projectId={project.id}
      />
    </div>



    




    </>
  );
}

export default EditorGeneratePopoverColabPage;