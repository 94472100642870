import React, {useState, useEffect} from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../misc/Icon';
import EditorGeneratePopoverColabPage from './EditorGeneratePopoverColabPage'
import EditorGeneratePopoverAPI from './EditorGeneratePopoverAPI'

function EditorGeneratePopover({ 
    children,
    projectVariables,
    refreshProjectVariables,
    project,
    updateCollabPageSettings,
    variableWebcams,
    updateClipMetadata
  }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('colabPage');


  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <Popover.Root
      open={popoverOpen} 
      onOpenChange={(open) => {        
        refreshProjectVariables()
        setPopoverOpen(open);
      }} 
      modal={true}
    >
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content 
          sideOffset={4}
          align="end"
          alignOffset={-34}          
          collisionPadding={5}
          className='generatePopover forceDarkTheme'
        >
          

          
            {/*}
            <>      
              <div className='generatePopover-main'>
                <div className='generatePopover-tabs'>
                  <button onClick={() => setActiveTab('colabPage')} className='generatePopover-tabs-tab'>
                    <div className='generatePopover-tabs-tab-iconContainer'>
                      <Icon name='speakerRectangleMedium' />
                    </div>
                    <div className='generatePopover-tabs-tab-main'>
                      <div className='generatePopover-tabs-tab-main-label'>
                        Use Colab Page
                      </div>
                      <div className='generatePopover-tabs-tab-main-description'>
                        Send people a link where they can individually record webcam, voice, or make custom selections.
                      </div>
                    </div>
                    <div className='generatePopover-tabs-tab-chevron'>
                      <Icon name='chevronRight' />
                    </div>
                  </button>

                  <button onClick={() => setActiveTab('api')} className='generatePopover-tabs-tab'>
                    <div className='generatePopover-tabs-tab-iconContainer'>
                      <Icon name='apiPlug' />
                    </div>
                    <div className='generatePopover-tabs-tab-main'>
                      <div className='generatePopover-tabs-tab-main-label'>
                        Use Spreadsheet API
                      </div>
                      <div className='generatePopover-tabs-tab-main-description'>
                        Create videos programmatically from a spreadsheet or Clay table.
                      </div>
                    </div>
                    <div className='generatePopover-tabs-tab-chevron'>
                      <Icon name='chevronRight' />
                    </div>
                  </button>            
                </div>
              </div>
              </>
          }
            */}

            <div className='generatePopover-header'>                          
              <button onClick={() => setActiveTab('colabPage')} data-active-state={activeTab === 'colabPage' ? 'active' : 'inactive'} className='generatePopover-header-tab'>
                <div className='generatePopover-header-tab-label'>
                  Collab Page
                </div>
                <div className='generatePopover-header-tab-bottomBorder' />
              </button>              
              <button onClick={() => setActiveTab('api')} data-active-state={activeTab === 'api' ? 'active' : 'inactive'} className='generatePopover-header-tab'>
                <div className='generatePopover-header-tab-label'>
                  API
                </div>
                <div className='generatePopover-header-tab-bottomBorder' />
              </button>              
            </div>

            
            {activeTab === 'colabPage' &&
              <EditorGeneratePopoverColabPage 
                goBack={() => setActiveTab(null)}
                updateCollabPageSettings={updateCollabPageSettings}
                stringVariables={projectVariables}
                variableWebcams={variableWebcams}
                updateClipMetadata={updateClipMetadata}
                project={project}
              />
            }

            {activeTab === 'api' &&
              <EditorGeneratePopoverAPI
                goBack={() => setActiveTab(null)}
                stringVariables={projectVariables}
                project={project}
              />
            }


        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default EditorGeneratePopover;