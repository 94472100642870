import React, { useRef } from 'react';
import Icon from '../misc/Icon'

const RecordWebcamScriptTeleSlider = ({ 
  value, 
  onChange, 
  isRecording, 
  isDraggingSlider, 
  setIsDraggingSlider 
}) => {
  const sliderWidth = 748;
  const sliderThumbWidth = 220;
  const sliderRef = useRef(null);
  const thumbRef = useRef(null);
  
  // Define increment step (0.1 = 10% of the slider range)
  const incrementStep = 0.15;
  
  const handleIncrement = () => {
    const newValue = Math.min(1, value + incrementStep);
    onChange(newValue);
  };
  
  const handleDecrement = () => {
    const newValue = Math.max(0, value - incrementStep);
    onChange(newValue);
  };
  
  const handleMouseDown = (e) => {      
    e.preventDefault();
    setIsDraggingSlider(true);
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const startX = e.clientX;
    const currentPixelOffset = value * (sliderWidth - sliderThumbWidth);
    
    const onMouseMove = (moveEvent) => {
      const delta = moveEvent.clientX - startX;
      let newPixelPosition = currentPixelOffset + delta;
      
      if (newPixelPosition < 0) newPixelPosition = 0;
      if (newPixelPosition > sliderWidth - sliderThumbWidth) {
        newPixelPosition = sliderWidth - sliderThumbWidth;
      }
      
      const newValue = newPixelPosition / (sliderWidth - sliderThumbWidth);
      onChange(newValue);
    };
    
    const onMouseUp = () => {
      setIsDraggingSlider(false);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
    
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };
  
  const thumbLeft = value * (sliderWidth - sliderThumbWidth);
  const edgeIconWidth = 50;
  const edgeLabelBuffer = 0; // for labels
  const fullEdgeIconSpace = edgeIconWidth + edgeLabelBuffer;
  const edgeIconLeftSpace = thumbLeft > fullEdgeIconSpace;
  const edgeIconRightSpace = sliderWidth - (thumbLeft + sliderThumbWidth) > fullEdgeIconSpace;
  
  const decrementBtnWidth = thumbLeft;
  const incrementBtnWidth = sliderWidth - (thumbLeft + sliderThumbWidth);
  
  return (
    <>

    <div
      ref={sliderRef}
      style={{        
        width: `${sliderWidth}px`,        
      }}
      data-dragging-state={isDraggingSlider ? true : false}
      data-recording-state={isRecording ? true : false}
      className="recordWebcamWindow-main--script-teleSlider"
    > 
      
      <div 
        style={{          
          left: `${thumbLeft}px`,
          width: `${sliderThumbWidth}px`,          
        }}
        className='recordWebcamWindow-main--script-teleSlider-labelContainer'>
        <div className='recordWebcamWindow-main--script-teleSlider-label recordWebcamWindow-main--script-teleSlider-label--hover'>
          Adjust Auto-Scroll Speed
        </div>
        <div className='recordWebcamWindow-main--script-teleSlider-label recordWebcamWindow-main--script-teleSlider-label--drag'>
          Previewing
        </div>
      </div>
      

      <button      
        style={{          
          left: '0px',
          width: `${decrementBtnWidth}px`,                    
        }}
        className='recordWebcamWindow-main--script-teleSlider-decrementBtn'
        onClick={handleDecrement}
      />
      <button      
        style={{          
          right: '0px',
          width: `${incrementBtnWidth}px`,                    
        }}
        className='recordWebcamWindow-main--script-teleSlider-incrementBtn'
        onClick={handleIncrement}
      />
      <button
        ref={thumbRef}        
        style={{          
          left: `${thumbLeft}px`,
          width: `${sliderThumbWidth}px`,          
          cursor: isDraggingSlider ? 'grabbing' : 'grab'
        }}
        className='recordWebcamWindow-main--script-teleSlider-thumb'
        onMouseDown={handleMouseDown}
      >
        <Icon name='teleprompter' />
        
        <div 
          data-space-state={edgeIconLeftSpace ? 'true' : 'false'} 
          style={{width: `${edgeIconWidth}px`}} 
          className='recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon--left'
        >
          <Icon name='rewind' />
        </div>
        <div 
          data-space-state={edgeIconRightSpace ? 'true' : 'false'}  
          style={{width: `${edgeIconWidth}px`}}  
          className='recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon recordWebcamWindow-main--script-teleSlider-thumb-edgeIcon--right'
        >
          <Icon name='fastForward' />
        </div>
      </button>
    </div>
    </>
  );
};

export default RecordWebcamScriptTeleSlider;