const WebcamTranscriptWordNodeSpec = {
    inline: true,
    group: "inline",
    attrs: {
        startTime: { default: 0 },
        endTime: { default: 0 },
        originalWordIndex: { default: 0 }  

    },
    content: "text*",
    marks: "skip", // Allow the skip mark
    toDOM: function(node) {
        return ["span", { 
            class: "editor-transcriptPanel-transcriptGroup--cameraRecording-word",
            "data-start": node.attrs.startTime,
            "data-end": node.attrs.endTime,
            spellcheck: "false",
        }, 0];
    },
    parseDOM: [{ 
        tag: "span.editor-transcriptPanel-transcriptGroup--cameraRecording-word",
        getAttrs: dom => ({
            startTime: parseFloat(dom.getAttribute("data-start")),
            endTime: parseFloat(dom.getAttribute("data-end")),
        })
    }]
};

export default WebcamTranscriptWordNodeSpec;


// const WebcamTranscriptWordNodeSpec = {
//   group: 'inline',
//   inline: true,
//   selectable: true,
//   atom: true,
//   attrs: {
//     text: { default: '' },
//   },
//   toDOM: function(node) {
//     return ['webcamTranscriptWord', { 'data-text': node.attrs.text }, node.attrs.text];
//   },
//   parseDOM: [
//     {
//       tag: 'webcamTranscriptWord',
//       getAttrs(dom) {
//         return { text: dom.getAttribute('data-text') };
//       },
//     },
//   ],
// };
// export default WebcamTranscriptWordNodeSpec;