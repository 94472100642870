import React, { useState,useEffect } from 'react';
import RecordWebcamRecord from '../components/recordWebcamWindow/RecordWebcamRecord';
import RecordWebcamPreview from '../components/recordWebcamWindow/RecordWebcamPreview';
import * as Dialog from '@radix-ui/react-dialog';
import { useDispatch } from 'react-redux';
import {uploadWebcamRecording} from '../actions/webcamRecording'
import {randomID} from '../utils/randomID'



const RecordWebcamContainer = ({webcamRecordModalProps,onClose}) => {
  const dispatch = useDispatch();
  // const [blobUrl, setBlobUrl] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentCaptureId, setCurrentCaptureId] = useState(null); //if you re record we make a new capture id 

  const isOpen = webcamRecordModalProps && webcamRecordModalProps.isOpen
  let clipId 
  let script
  let hasInstructions
  let instructions
  if(isOpen){
    clipId=webcamRecordModalProps.clipId
    script = webcamRecordModalProps.script 
    instructions = webcamRecordModalProps.instructions
  }

  // useEffect(() => {
  //   return () => {
  //     if (blobUrl) {
  //       URL.revokeObjectURL(blobUrl);
  //     }
  //   };
  // }, [blobUrl]);


  let layout
  
  if(!currentCaptureId){
    layout = 'clean'
  }
  if (script && !currentCaptureId) {
    layout = 'script';
  }
  if (instructions && !currentCaptureId) {
    layout = 'instructions';
  }


const onRecordingComplete = async (blob) => {
  const newCaptureId = `${randomID()}`;
  console.log('capture id is', newCaptureId);
  
  try {
    // Convert blob to ArrayBuffer before sending
    const arrayBuffer = await blob.arrayBuffer();
    //lets save it to the filesystem so we have it incase stuff goes wrong
    const result = await window.ipcRenderer.invoke('save-webcam-recording', {
      arrayBuffer,
      captureId: newCaptureId
    });

    if (!result.success) {
      throw new Error(result.error);
    }
    setCurrentCaptureId(newCaptureId);
  } catch (error) {
    console.error('Error saving webcam recording:', error);
    // TODO: Show error to user
  }
};

  const handleReRecord = () => {
  //  window.ipcRenderer.send('set-webcam-window-height', false);
    setCurrentCaptureId(null)
  };


const handleSubmit = async () => {
  if (!currentCaptureId) return;
  try {
    setSubmitLoading(true);
    const result = await window.ipcRenderer.invoke('get-webcam-original-recording-file', currentCaptureId);
    if (!result || !result.success) {
      throw new Error(result?.error || 'Failed to retrieve file');
    }
    const { buffer, name, type } = result.data;
    // Convert the Node.js Buffer into a Uint8Array
    const uint8Array = new Uint8Array(buffer);
    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type });
    // Create a File from the Blob (if you need the File interface)
    const file = new File([blob], name, { type });
    // Now file has name, type, and can be uploaded just like a file from a file input
    await dispatch(uploadWebcamRecording(clipId, file, 10, currentCaptureId));
  } catch (error) {
    console.error('Error uploading webcam recording:', error);
  } 
};



  const handleCloseModal = () => {
    // Reset all state
    // if (blobUrl) {
    // URL.revokeObjectURL(blobUrl);
    // }
    // setBlobUrl(null);
    setSubmitLoading(false);
  };


  //console.log('blobUrl',blobUrl)



  return (
    <Dialog.Root 
      open={isOpen} 
      onOpenChange={(open) => {
        if (!open) {
          handleCloseModal(); // Clean up blob URL
          onClose(); // Call parent close function
        }
      }}
      >
      <Dialog.Portal>
        <Dialog.Overlay className='recordWebcamWindowOverlay forceDarkTheme' />
        <Dialog.Content className={'recordWebcamWindow forceDarkTheme ' + (currentCaptureId ? ' recordWebcamWindow--preview ' : '') + (layout ? `recordWebcamWindow--${layout}` : ' ')}>
          

            
           {isOpen &&
              <>
              {!currentCaptureId ? (
                <RecordWebcamRecord
                  script={script}
                  instructions={instructions}
                  layout={layout}
                  onRecordingComplete={onRecordingComplete}          
                />
              ) : (
                <RecordWebcamPreview
                  currentCaptureId={currentCaptureId}
                  onReRecord={handleReRecord}    
                  submitLoading={submitLoading}
                  handleSubmit={handleSubmit} 
                />
              )}
              </>
            }
            


        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>


    
  );
};


export default RecordWebcamContainer;