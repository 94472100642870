import {TimelineClip} from './TimelineClip'
import {AudioClip} from './AudioClip'
import {VideoClip} from './VideoClip'
import {CodecVideoClip} from './CodecVideoClip'
import {TextSlideClip} from './TextSlideClip'
import {ZoomClip} from './ZoomClip'
import {ChartClip} from './ChartClip'
import {ImageClip} from './ImageClip'
import {SlideClip} from './slide/SlideClip'
import {WebcamClip} from './WebcamClip'
import { createClipPMNode } from './prosemirrorManager/nodeCreators'
import {adjustClipsDraggingLeft} from './dragUtils/adjustClipsDraggingLeft'
import {adjustClipsDraggingRight} from './dragUtils/adjustClipsDraggingRight'
import {getSnappedClipTimes} from './dragUtils/getSnappedClipTimes'
import {dragPullClips} from './dragUtils/dragPullClips'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
 
const MIN_SCENE_DURATION = 5
const PUSH_THRESHOLD = 1
const PUSH_THRESHOLD_PIXELS = 20

function roundToOneDecimal(value) {
	return Math.round(value * 10) / 10;
}

//times are relative to scene start time 
class TimelineScene { 
	constructor({id, startTime, duration,clips,sceneIndex,title},pmManager,transcriptPmManager,handleClipMediaLoaded,debouncedSaveProjectTimeline,projectId,handleVoiceoverAudioFileUpdated,onDurationUpdate,moveClipBetweenScenes,isLastScene,setPreviewingAudioClipId,activeVoice,getTranscript,isExport,handleTextElementFontLoaded,updateTranscriptPanelOnLoadWebcam) {
		this.id = id;
		this.title=title || 'Untitled Scene'
		this.activeVoice = activeVoice
		this.startTime = startTime || 0;
		this.duration = duration;
		this.clips = []
		this._projectId=projectId 
		this.handleVoiceoverAudioFileUpdated=handleVoiceoverAudioFileUpdated
		this.onDurationUpdate=onDurationUpdate 
		this.handleClipMediaLoaded=handleClipMediaLoaded
		this.debouncedSaveProjectTimeline = debouncedSaveProjectTimeline
		this.moveClipBetweenScenes=moveClipBetweenScenes
		this.sceneIndex=sceneIndex
		this.pmManager = pmManager 
		this.transcriptPmManager=transcriptPmManager
		this.isLastScene=isLastScene
		this.setPreviewingAudioClipId=setPreviewingAudioClipId
		this.audioClipSpacing = null
		this.getTranscript=getTranscript
		this.handleTextElementFontLoaded = handleTextElementFontLoaded
		this.dndDirection = null
		this.enableDndPush = false
		this.enableResizePush = false
		this.isExport = isExport 
		this.updateTranscriptPanelOnLoadWebcam = updateTranscriptPanelOnLoadWebcam
	}

	// initScene(isInitialLoad,clips,isPMUndoRedo,audioClipSpacing){
	// 	const scene={id: this.id, startTime: this.startTime, duration: this.duration,sceneIndex:this.sceneIndex,title:this.title}
	// 	this.audioClipSpacing=audioClipSpacing
	// 	if(!isPMUndoRedo && this.pmManager){
	// 		this.pmManager.addSceneToPMDoc(scene,isInitialLoad)
	// 	}
	// 	clips.forEach((clip)=>{this.addClip({...clip,sceneId:this.id},isInitialLoad,isPMUndoRedo)})
	// 	this.calculateSceneDuration()
	// }

	initScene(isInitialLoad, clips, isPMUndoRedo, audioClipSpacing) {
		const scene = {
			id: this.id, 
			startTime: this.startTime, 
			duration: this.duration,
			sceneIndex: this.sceneIndex,
			title: this.title
		}
		this.audioClipSpacing = audioClipSpacing
		if (!isPMUndoRedo && this.pmManager) {
			this.pmManager.addSceneToPMDoc(scene, isInitialLoad)
		}

		clips.forEach((clip) => {
			// Skip placeholder webcam clips during export
			if (this.isExport && clip.type === 'webcam' && clip.metadata.isVariable) {
				return;
			}
			this.addClip({...clip, sceneId: this.id}, isInitialLoad, isPMUndoRedo)
		})
		this.calculateSceneDuration()
	}


	createNewTimelineClip(newClip,voiceOverPlaybackRate){
		const scene = this
		let timelineClip
		let clip = {...newClip}
		if(newClip.startTime){
			clip.startTime = Math.max(newClip.startTime - this.startTime,0) //adjust for scene start time (this is not a TimelineClip so doesnt go through the setter)
		}
		if(newClip.relativeStartTime || newClip.relativeStartTime==0){
			clip.startTime = newClip.relativeStartTime
		}
		if((clip.duration >0 && clip.startTime>=0)){
			if(clip.type=='audio'){
				clip.voiceOverPlaybackRate = voiceOverPlaybackRate
				timelineClip = new AudioClip(clip,scene,this._projectId,this.transcriptPmManager,this.handleVoiceoverAudioFileUpdated,this.setPreviewingAudioClipId,this.activeVoice,this.getTranscript)
			}else if(clip.type=='video'){
				if(this.isExport){
					timelineClip = new CodecVideoClip(clip,scene)
				}else{
					timelineClip = new VideoClip(clip,scene,this.handleClipMediaLoaded,this.updateVideoClipSegments)
				}
			}else if(clip.type=='textSlide'){
				timelineClip = new TextSlideClip(clip,scene)
			}else if(clip.type=='zoom'){
				timelineClip = new ZoomClip(clip,scene)
			}else if(clip.type=='chart'){
				timelineClip = new ChartClip(clip,scene)
			}else if(clip.type=='image') {
				timelineClip = new ImageClip(clip,scene,this.handleClipMediaLoaded)

			}else if(clip.type=='slide') {
				timelineClip = new SlideClip(clip,scene,false,null,this.handleTextElementFontLoaded)
			}
			else if(clip.type=='webcam') {
				if(this.isExport){
					timelineClip = new CodecVideoClip(clip,scene,this.handleClipMediaLoaded)
				}else{
					timelineClip = new WebcamClip(clip,scene,this.handleClipMediaLoaded,this.updateVideoClipSegments,this.updateTranscriptPanelOnLoadWebcam)
				}
			}
			else {
				timelineClip = new TimelineClip(clip,scene)
			}	
		}
		return timelineClip	
	}

	findClipById(clipId) {
		return this.clips.find(clip => clip.id === clipId);		
	}
 
	moveClipIntoScene(clip){
		this.clips.push(clip)
	}

	moveClipOutOfScene(clipId){
		const clipIndex = this.clips.findIndex(s => s.id === clipId);
		if(clipIndex !== -1){
			this.clips.splice(clipIndex, 1);
		}
	}

	changeActiveVoice(activeVoice){
		this.activeVoice= activeVoice
		this.clips.forEach(clip => {
			if(clip instanceof AudioClip) {
				clip.changeActiveVoice(activeVoice)
			}
		})
	}

	updateVideoClipSegments=(clipId)=> {
		const clip = this.findClipById(clipId)
		const message = 'update video clip segments'
		this.pmManager.updateNodeAttrs(clip,message)
	}

	addClipFromTranscriptSync(clip,voiceOverPlaybackRate){
		const timelineClip = this.createNewTimelineClip(clip,voiceOverPlaybackRate)
		if(timelineClip){
			this.clips.push(timelineClip)
			this.pmManager.startAction('addClip')
			this.pmManager.addClipToPMDoc({...clip,voiceOverPlaybackRate:voiceOverPlaybackRate,sceneId:this.id},false)	
			this.debouncedSaveProjectTimeline()
		}
	}

	addClip(clip,isInitialLoad,isPMUndoRedo,voiceOverPlaybackRate) {
		const timelineClip = this.createNewTimelineClip(clip,voiceOverPlaybackRate)
		if(timelineClip){
			this.clips.push(timelineClip)
			if (!isPMUndoRedo && !isInitialLoad) { 
				this.pmManager.startAction('addClip')
			}	
			if(!isInitialLoad){
				if(timelineClip.type=='textSlide' || timelineClip.type=='chart' || timelineClip.type=='video'|| timelineClip.type=='image' || timelineClip.type=='zoom'  || timelineClip.type=='slide'){
					this.resolveConflictsOnInsertClip(timelineClip)	
				}
			}
			if (!isPMUndoRedo && this.pmManager) { 
				this.pmManager.addClipToPMDoc(timelineClip,isInitialLoad)
				if(!isInitialLoad){
					this.pmManager.endAction()
					this.calculateSceneDuration()
				}
			}
		}
	}

	deleteClip(clipId,isPMUndoRedo){
		let clipIdsToDelete=[]
		clipIdsToDelete.push(clipId)
		this.clips.forEach((clip)=>{
			if(clip.type=='zoom' && clip.metadata.parentClip==clipId){
					clipIdsToDelete.push(clip.id)
				}
		})		
		clipIdsToDelete.forEach((id)=>{
			const clipIndex = this.clips.findIndex(s => s.id === id);
			if(clipIndex!==-1){
				const clip = this.clips[clipIndex]
				if (clip.destroy && typeof clip.destroy === 'function') {
					clip.destroy();
				}
				this.clips.splice(clipIndex, 1);
			}
		})
		if(!isPMUndoRedo){
			clipIdsToDelete.forEach((id)=>{
				this.pmManager.deleteNodeFromPmDoc(id)
			})
		}
		this.calculateSceneDuration()
		this.debouncedSaveProjectTimeline()
	}

	resolveConflictsOnInsertClip(newClip){
		const overlapThreshold = 0.5 //determins if we put the clip after existing it overlaps with or move the existing clip
		let sameTrackClips = this.clips.filter(clip => clip.zIndex === newClip.zIndex)
		sameTrackClips.sort((a, b) => a.startTime - b.startTime);
		let newClipEndTime = newClip.startTime + newClip.duration;
		let newClipIndex = sameTrackClips.findIndex(clip => clip.id === newClip.id);

		let updatesArray=[]
		if (newClipIndex > 0) {
			let precedingClip = sameTrackClips[newClipIndex - 1];
			let midpoint = precedingClip.startTime + (precedingClip.duration * overlapThreshold);
			if (newClip.startTime < precedingClip.endTime) {
				if (newClip.startTime < midpoint) {
					// Shift the preceding clip to after the newClip
					let shiftAmount = newClipEndTime - precedingClip.startTime;
					precedingClip.startTime += shiftAmount;
					updatesArray.push({clipId:precedingClip.id,relativeStartTime:precedingClip.relativeStartTime})
					 if(precedingClip instanceof VideoClip) {
						const zoomUpdates = this.moveZoomsWithVideoClip(precedingClip.id,shiftAmount)
						updatesArray = [...updatesArray,...zoomUpdates]
					}
				} else {// Place the newClip after the preceding clip
					newClip.startTime = precedingClip.endTime;
					newClipEndTime = newClip.startTime + newClip.duration;
					updatesArray.push({clipId:newClip.id,relativeStartTime:newClip.relativeStartTime})
				}
			}
		}
		sameTrackClips.sort((a, b) => a.startTime - b.startTime);
		newClipIndex = sameTrackClips.findIndex(clip => clip.id === newClip.id);
		for (let i = newClipIndex + 1; i < sameTrackClips.length; i++) {
			let currentClip = sameTrackClips[i];
			if (currentClip.startTime < newClipEndTime) {
				let shiftAmount = newClipEndTime - currentClip.startTime;
				currentClip.startTime += shiftAmount;
				updatesArray.push({clipId:currentClip.id,relativeStartTime:currentClip.relativeStartTime})
				if(currentClip instanceof VideoClip) {
						const zoomUpdates = this.moveZoomsWithVideoClip(currentClip.id,shiftAmount)
						updatesArray = [...updatesArray,...zoomUpdates]
					}
			}
			newClipEndTime = currentClip.endTime;
		}
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.debouncedSaveProjectTimeline()
	}

	handleDragClip(clip, newStartTime,isDnDMode,isDragPullMode,pixelsPerSec) {
		if(!isDnDMode){
			this.handleNormalDragClip(clip.id, newStartTime,isDragPullMode,pixelsPerSec)
		}
	}

	onDnDDragEnd(dragClip,dropTime,activeDropType){
		if(dropTime || dropTime ==0){
		let isInScene = false 
		if((dropTime >=this.startTime && dropTime <=this.endTime) || dropTime>=this.endTime && this.isLastScene){
			isInScene = true 
		}	
		if(isInScene){
			const originalSceneId = dragClip.scene.id
			const newSceneId = this.id 
			const initialStartTime = dragClip.relativeStartTime

			let updatesArray=[]
			if(originalSceneId != newSceneId){
				const originalScene = dragClip.scene
				this.moveClipIntoScene(dragClip)
				originalScene.moveClipOutOfScene(dragClip.id)
				dragClip.scene = this
				if(dragClip.type=='video'){
					let clipsToMove=[] //need to do this cos if you just loop over and move it doesnt get them all becase the clips array is changing as you loop
					originalScene.clips.forEach((clip)=>{
						if(clip.type=='zoom' && clip.metadata.parentClip==dragClip.id){
							clipsToMove.push(clip)
						}
					})
					clipsToMove.forEach((clip)=>{
						this.moveClipIntoScene(clip)
						originalScene.moveClipOutOfScene(clip.id)
						clip.scene = this
					})
				}
			}
			
			const filteredClips = this.clips.filter(clip => clip.zIndex === dragClip.zIndex)
			
			if(activeDropType=='start'){//if activeDropType is start then put the drag clip start time to the drop time and push and other clips if required
				dragClip.startTime = dropTime
				const shiftAmount = dragClip.relativeStartTime - initialStartTime
				updatesArray.push({clipId:dragClip.id,relativeStartTime:dragClip.relativeStartTime,sceneId:this.id})
				this.clips.forEach((clip)=>{	//if has the same start time as another clip bump it very slightly
					if(clip.id!==dragClip.id && clip.zIndex==dragClip.zIndex){
						if(clip.startTime.toFixed(1)==dragClip.startTime.toFixed(1)){
							clip.startTime += 0.01
						}
					}
					if(clip.type=='zoom' && clip.metadata.parentClip==dragClip.id){
						const newStartTime = Math.max(clip.relativeStartTime+=shiftAmount,0)
						clip.relativeStartTime=newStartTime
						updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,sceneId:this.id})
					}
				})
				//push other clips if there are any conflicts
				let sortedClips = this.getSortedTrackClips(dragClip.zIndex)
				const draggedClipIndex = this.getClipIndex(sortedClips, dragClip.id);
				const newEndTime = dragClip.endTime
				updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, newEndTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam)
			}else{ //active drop type is end we try and put the clip end as close to this time as possible
				const targetStartTime = dropTime - dragClip.duration
				let sortedClips = this.getSortedTrackClips(dragClip.zIndex)
				let totalDurationOfPrecedingClips=0 
				sortedClips.forEach((clip)=>{
					if(clip.id!==dragClip.id && roundToOneDecimal(clip.endTime) <= roundToOneDecimal(dropTime) && clip.zIndex==dragClip.zIndex){
						totalDurationOfPrecedingClips+=clip.duration
					}
				})
				const newStartTime = Math.max(targetStartTime,totalDurationOfPrecedingClips+this.startTime)
				dragClip.startTime = newStartTime

				const shiftAmount = dragClip.relativeStartTime - initialStartTime
				updatesArray.push({clipId:dragClip.id,relativeStartTime:dragClip.relativeStartTime,sceneId:this.id})
				
				this.clips.forEach((clip)=>{
					if(clip.type=='zoom' && clip.metadata.parentClip==dragClip.id){
						const newStartTime = Math.max(clip.relativeStartTime+=shiftAmount,0)
						clip.relativeStartTime=newStartTime
						updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,sceneId:this.id})
					}
				})

				const zIndex = dragClip.zIndex
				let sameTrackClips = this.clips.filter(clip => clip.zIndex === zIndex)
				let clipsBeforeDrop=[]
				let clipsAfterDrop=[]
				sameTrackClips.forEach((clip)=>{
					if(clip.id!=dragClip.id){
						let roundedClipStartTime = roundToOneDecimal(clip.startTime);
	        	let roundedDropTime = roundToOneDecimal(dropTime);
						if(roundedClipStartTime>=roundedDropTime){
							clipsAfterDrop.push(clip)
						}else{
							clipsBeforeDrop.push(clip)
						}
					}
				})
				const sortedClipsBefore = clipsBeforeDrop.sort((a, b) => a.startTime - b.startTime);
				let stopAdjusting = false;
				let previousClipEndTime = newStartTime;
			
				//TODO move zooms with video clips

				for (let i = sortedClipsBefore.length - 1; i >= 0 && !stopAdjusting; i--) {
					let currentClip = sortedClipsBefore[i];
					const initialStartTime = currentClip.relativeStartTime
					let currentClipEndTime = currentClip.startTime + currentClip.duration;
					if (currentClipEndTime > previousClipEndTime) {
						let newStart = previousClipEndTime - currentClip.duration;
						if (newStart < 0) {
							stopAdjusting = true;
							newStart = 0;
						}
						previousClipEndTime = newStart;
						currentClip.startTime = newStart;
						updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime });
						if(currentClip.type=='video') {
							const finalStartTime = currentClip.relativeStartTime
							const shiftAmount = finalStartTime -initialStartTime 
							const zoomUpdates = this.moveZoomsWithVideoClip(currentClip.id,shiftAmount)
							updatesArray = [...updatesArray,...zoomUpdates]
						}
					}
				}
				const sortedClipsAfter = clipsAfterDrop.sort((a, b) => a.startTime - b.startTime);
				let lastEndTime = dragClip.endTime;
				for (let i = 0; i < sortedClipsAfter.length; i++) {
					let currentClip = sortedClipsAfter[i];
					const initialStartTime = currentClip.relativeStartTime
					if (currentClip.startTime < lastEndTime) {
						let newStart = lastEndTime;
						currentClip.startTime = newStart;
						updatesArray.push({ clipId: currentClip.id, relativeStartTime: currentClip.relativeStartTime });
						if(currentClip.type=='video') {
							const finalStartTime = currentClip.relativeStartTime
							const shiftAmount = finalStartTime -initialStartTime 
							const zoomUpdates = this.moveZoomsWithVideoClip(currentClip.id,shiftAmount)
							updatesArray = [...updatesArray,...zoomUpdates]

						}
						lastEndTime = newStart + currentClip.duration;
					}
				}
			}
			this.pmManager.updateMultipleClipFields(updatesArray)
		}
	}
	}

	onDragStart(dragClipZIndex){ 
		this.enableDndPush=false
		this.dndDirection = null
	}

	/////Video clips

	handleUpdateSkipSegmentDuration(clipId,segmentId,newDuration,direction){
		const clip = this.findClipById(clipId)
		clip.updateSkipSegmentDuration(segmentId,newDuration,direction)
		this.pmManager.updateNodeAttrs(clip)
	}

	handleChangeVideoClipPlaybackRate(clipId,playbackRate){
		this.pmManager.startAction('changeClipPlaybackRate')
		const clip = this.findClipById(clipId)
		clip.updateClipPlaybackRate(playbackRate)
		this.pmManager.updateNodeAttrs(clip)
		this.adjustClipsRight(clip,clip.endTime,false)
		this.pmManager.endAction()
	}

	handleChangeSegmentPlaybackRate(clipId,segmentId,playbackRate){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		clip.updateSegmentPlaybackRate(segmentId,playbackRate)
		this.pmManager.updateNodeAttrs(clip)
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let updatesArray=[]
		updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, clip.endTime, updatesArray,this.moveZoomsWithVideoClip)
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.calculateSceneDuration()
	}

	addFreezeFrame(clipId,freezeTime){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		if(clip){
			const videoTime = calulateVideoTimeFromTimelineTime(freezeTime,clip)	
			clip.addFreezeFrame(videoTime)
			this.pmManager.updateNodeAttrs(clip)
			this.adjustClipsRight(clip,clip.endTime,false)
		}
	}

	addSkipSegment(clipId,skipTime){
		const clip = this.findClipById(clipId)
		if(clip){
			const videoTime = calulateVideoTimeFromTimelineTime(skipTime,clip)	
			clip.addSkipSegment(videoTime)
			this.pmManager.updateNodeAttrs(clip)
		}
	}

	toggleSkipSegment(clipId,segmentId,isExpanded){ //TODO i think we want to group these which means a start ts action on drag start
		this.pmManager.startAction('toggleSkipSection')
		const clip = this.findClipById(clipId)
		clip.toggleSkipSegment(segmentId,isExpanded)
		if(isExpanded){
			let sortedClips = this.getSortedTrackClips(clip.zIndex)
			const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
			let updatesArray=[]
			updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, clip.endTime, updatesArray,this.moveZoomsWithVideoClip)
			this.pmManager.updateMultipleClipFields(updatesArray)
		}
		this.pmManager.updateNodeAttrs(clip)
		this.pmManager.endAction()
	}

	removeFreeze(clipId,segmentId){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		clip.removeFreeze(segmentId)
		this.pmManager.updateNodeAttrs(clip)
	}

	removeSkip(clipId,segmentId){ //TODO i think we want to group these which means a start ts action on drag start
		const clip = this.findClipById(clipId)
		clip.removeSkip(segmentId)
		this.pmManager.updateNodeAttrs(clip)
	}

	//TODO combine video and other clips
	handleResizeVideoLeft = (clip, targetNewDuration,pixelsPerSec)=>{
		const originalStartTime = clip.relativeStartTime 
		const targetDeltaDuration = targetNewDuration - clip.duration 
		const targetStartTime = originalStartTime - targetDeltaDuration 
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		//start time can not be lower than duration of preceeding clips
		let newStartTime = this.adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, targetStartTime);

		const ignoreSnapType='end'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newStartTime,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD


		if(sortedClips[draggedClipIndex-1]){ 
			isAlreadySnapped = sortedClips[draggedClipIndex].startTime ==sortedClips[draggedClipIndex-1].endTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if( sortedClips[draggedClipIndex-1].endTime -targetStartTime > pushThreshold){
					this.enableResizePush=true
				}
			}
		}
		if(this.startTime ==sortedClips[draggedClipIndex].startTime){
			isAlreadySnapped=true
		}	

		if(!isAlreadySnapped || this.enableResizePush || targetDeltaDuration<0){
			if(snappedClipTimes.snapType=='start' && (!isAlreadySnapped || targetDeltaDuration<0)){ //for smooth resize push but without the flashing
				newStartTime=snappedClipTimes.startTime
			}
			let deltaDuration = originalStartTime - newStartTime	
			const quietSegments = clip.segments.filter(segment => segment.isQuiet);
				// Calculate the total of the squared durations
			const totalSquaredDuration = quietSegments.reduce((acc, segment) => acc + Math.pow(segment.newDuration, 2), 0)
			quietSegments.forEach(segment => {
				const proportion = Math.pow(segment.newDuration, 2) / totalSquaredDuration;
				const adjustedDurationChange = proportion * deltaDuration;
				const newSegmentDuration = segment.newDuration + adjustedDurationChange;
				const newPlaybackRate = segment.originalDuration / newSegmentDuration;
				// Update each segment's playback rate
				clip.updateSegmentPlaybackRate(segment.id,newPlaybackRate)
			});

			this.pmManager.updateNodeAttrs(clip)
			const disableSnapping = true
			this.adjustClipsLeft(clip,newStartTime,disableSnapping,pixelsPerSec)		
		}			
	}


	handleResizeVideoRight = (clip, targetNewDuration,pixelsPerSec)=>{
		let deltaDuration = targetNewDuration - clip.duration 
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		const targetEndTime = clip.startTime+targetNewDuration 
		let newEndTime = targetEndTime
		const ignoreSnapType='start'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newEndTime-clip.duration,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		
		if(sortedClips[draggedClipIndex+1]){ //TODO This shoul be all clips i think not just the track
			isAlreadySnapped = sortedClips[draggedClipIndex].endTime ==sortedClips[draggedClipIndex+1].startTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if(targetEndTime - sortedClips[draggedClipIndex].endTime > pushThreshold){
					this.enableResizePush=true
				}
			}	
		}
		if(!isAlreadySnapped || this.enableResizePush || deltaDuration<0){

			if(snappedClipTimes.snapType=='end' && (!isAlreadySnapped || deltaDuration<0)){ //for smooth resize push but without the flashing
				newEndTime=snappedClipTimes.endTime
			}
			deltaDuration = newEndTime - clip.endTime 
			const quietSegments = clip.segments.filter(segment => segment.isQuiet);
		// Calculate the total of the squared durations
		const totalSquaredDuration = quietSegments.reduce((acc, segment) => acc + Math.pow(segment.newDuration, 2), 0)
		quietSegments.forEach(segment => {
			const proportion = Math.pow(segment.newDuration, 2) / totalSquaredDuration;
			const adjustedDurationChange = proportion * deltaDuration;
			const newSegmentDuration = segment.newDuration + adjustedDurationChange;
			const newPlaybackRate = segment.originalDuration / newSegmentDuration;
			clip.updateSegmentPlaybackRate(segment.id,newPlaybackRate)
		});
		this.pmManager.updateNodeAttrs(clip)
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);

		let updatesArray=[]
		updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, clip.endTime, updatesArray,this.moveZoomsWithVideoClip)
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.calculateSceneDuration()
	}
	}

	onResizeStart=()=>{
		this.enableResizePush=false
	}

	handleResize = (clipId, targetNewDuration,direction,pixelsPerSec) => {
		const clip = this.findClipById(clipId);
		if(clip.type=='video'){ 
			if(direction =='left'){
				this.handleResizeVideoLeft(clip,targetNewDuration,pixelsPerSec)
			}
			else if(direction =='right'){
				this.handleResizeVideoRight(clip,targetNewDuration,pixelsPerSec)
			}
		}else{
		const minDuration = clip.minDuration || 0.5
		// // // // Ensure new duration does not go below the minimum duration TODO fancy text slide min duration based on animation type and text content
		let newDuration = Math.max(targetNewDuration, minDuration) 
		const adjustClipDuration = true
		if (direction === 'left') {
			const targetStartTime = clip.endTime - newDuration
			this.handleResizeLeftHandle(clip,targetStartTime,pixelsPerSec)
		}	else{
			const targetEndTime = clip.startTime + newDuration
			this.handleResizeRightHandle(clip,targetEndTime,pixelsPerSec)
		}
		}	
		this.debouncedSaveProjectTimeline()
	}

	handleResizeLeftHandle(clip,targetStartTime,pixelsPerSec){
		let updatesArray = [] 
		const endTime = clip.endTime
		const deltaTime = targetStartTime - clip.startTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		sortedClips = sortedClips.filter(c => !c.parentWebcamClip );

		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newStartTime = this.adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, targetStartTime);
		const ignoreSnapType='end'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newStartTime,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		if(sortedClips[draggedClipIndex-1]){ //TODO This shoul be all clips i think not just the track
			isAlreadySnapped = sortedClips[draggedClipIndex].startTime ==sortedClips[draggedClipIndex-1].endTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if( sortedClips[draggedClipIndex-1].endTime -targetStartTime > pushThreshold){
					this.enableResizePush=true
				}
			}
		}
		if(this.startTime ==sortedClips[draggedClipIndex].startTime){
			isAlreadySnapped=true
		}	

		if(!isAlreadySnapped || this.enableResizePush || deltaTime>0){

		if(snappedClipTimes.snapType=='start' && (!isAlreadySnapped || deltaTime>0)){ //for smooth resize push but without the flashing
			newStartTime=snappedClipTimes.startTime
		}else{
			//console.log('DONT SNAP')
		}

		const finalStartTimeDelta = newStartTime - clip.startTime
		clip.startTime = newStartTime 	
		clip.duration = endTime - newStartTime
		
		if(clip.type=='webcam'){
			//plaeholder duration is similar to pinnedstarttime and overrides calced duration
			clip.placeholderDuration = clip.duration
			//when resizing left we will shift child clips of the placeholder to the left (when resizing right do nothing)
			const placeholderAudioClips = this.clips.filter(c => c.parentWebcamClip == clip.id);
			placeholderAudioClips.forEach(pa => {
        pa.startTime += finalStartTimeDelta;
        updatesArray.push({ clipId: pa.id, relativeStartTime: pa.relativeStartTime });
    	});

		}
		updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration,placeholderDuration:clip.placeholderDuration })

		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'

		//updatesArray = adjustClipsDraggingLeft(sortedClips, draggedClipIndex, newStartTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing)
		//updatesArray = adjustClipsDraggingLeft(sortedClips, draggedClipIndex, newStartTime, updatesArray,this.moveZoomsWithVideoClip)
		updatesArray = adjustClipsDraggingLeft(sortedClips, draggedClipIndex, newStartTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing)
		this.pmManager.updateMultipleClipFields(updatesArray)
	}
	}

	handleResizeRightHandle(clip,targetEndTime,pixelsPerSec){
		let updatesArray = [] 
		const deltaTime = targetEndTime - clip.endTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		//exclude the placholder audio clips
		sortedClips = sortedClips.filter(c => !c.parentWebcamClip );
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newEndTime = targetEndTime
		const ignoreSnapType='start'
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newEndTime-clip.duration,clip,this.clips,this.startTime,this.endTime,ignoreSnapType)	
		let isAlreadySnapped=false //prevents lumpy resize push stuff
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		if(sortedClips[draggedClipIndex+1]){ //TODO This shoul be all clips i think not just the track
			isAlreadySnapped = sortedClips[draggedClipIndex].endTime ==sortedClips[draggedClipIndex+1].startTime 
			if(isAlreadySnapped && !this.enableResizePush){
				if(targetEndTime - sortedClips[draggedClipIndex].endTime > pushThreshold){
					this.enableResizePush=true
				}
			}	
		}
		if(!isAlreadySnapped || this.enableResizePush || deltaTime<0){

		if(snappedClipTimes.snapType=='end' && (!isAlreadySnapped || deltaTime<0)){ //for smooth resize push but without the flashing
			newEndTime=snappedClipTimes.endTime
		}
		clip.duration = newEndTime - clip.startTime
		if(clip.type=='webcam'){
			//plaeholder duration is similar to pinnedstarttime and overrides calced duration
			clip.placeholderDuration = clip.duration
		}
		updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration,placeholderDuration:clip.placeholderDuration })

		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'
		updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, newEndTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing)
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.calculateSceneDuration()
	}
	}

	handleNormalDragClip(clipId, newStartTime,isDragPullMode,pixelsPerSec) {
		const clip = this.findClipById(clipId)
		newStartTime = Math.max(newStartTime, this.startTime) //can't drag beyond scene start time in normal drag mode
		if (!clip || clip.startTime==newStartTime) return		
		const isMovingLeft = newStartTime < clip.startTime;
		const adjustClipDuration = false
		if(isMovingLeft){
			this.adjustClipsLeft(clip,newStartTime,false,pixelsPerSec,isDragPullMode)
		}else{
			this.adjustClipsRight(clip,newStartTime+clip.duration,false,pixelsPerSec,isDragPullMode)
		}
		this.debouncedSaveProjectTimeline()
	}

	moveZoomsWithVideoClip=(clipId,shiftAmount)=>{
		let updates=[]
		this.clips.forEach((clip)=>{
			if(clip.type=='zoom' && clip.metadata.parentClip==clipId){
				const newStartTime = Math.max(clip.startTime+=shiftAmount,0)
				clip.startTime=newStartTime
				updates.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime})
			}
		})		
		return updates
	}

	updateTextSlideText(clipId,wordsArray,docJson,text) {
		const clip = this.findClipById(clipId)
		if (clip) {
			let textHasChanged = clip.metadata.text !==text
			clip.metadata = {...clip.metadata,text:text,docJson:docJson}
			clip.wordsArray = wordsArray
			clip.calculateMinSlideDuration()
			if(clip.duration < clip.minDuration){
				//this.handleSlideClipDurationLessThanMinimum(clip)
			}
			if(textHasChanged){
				this.pmManager.updateNodeAttrs(clip)
			}
		}
	}

	//For both dragging and resizing to the left
	//for resizing adjustClipDuration is true.  for drag its false 
	adjustClipsLeft(clip,targetStartTime,disableSnapping,pixelsPerSec,isDragPullMode){
		if(this.dndDirection != 'left'){
			this.dndDirection = 'left'
			this.enableDndPush=false
		}

		let updatesArray = [] //for prosemirror
		const initialStartTime = clip.relativeStartTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		
		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'
		if(isAudioTrack){ //exclude the audio clips for placeholder webcam
			sortedClips = sortedClips.filter(c => !c.parentWebcamClip );
		}

		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);

		let newStartTime = this.adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, targetStartTime,isAudioTrack,this.audioClipSpacing);
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newStartTime,clip,this.clips,this.startTime,this.endTime)	
		let isAlreadySnapped = false 
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD
		if(!disableSnapping && clip.type!='audio' && clip.type!='webcam'){
			if(sortedClips[draggedClipIndex-1]){
				isAlreadySnapped = sortedClips[draggedClipIndex].startTime ==sortedClips[draggedClipIndex-1].endTime 
				if(isAlreadySnapped){
					if(!this.enableDndPush){
						if( sortedClips[draggedClipIndex-1].endTime -targetStartTime > pushThreshold){
							this.enableDndPush=true
						}
					}
				}
			}
			if(this.startTime ==sortedClips[draggedClipIndex].startTime){
				isAlreadySnapped=true
			}
			if(!isAlreadySnapped){
				newStartTime =snappedClipTimes.startTime
			}
		}

		if(!isAlreadySnapped || this.enableDndPush){ //if cant push dont do anything
			clip.startTime = newStartTime 	
			if(clip.type=='audio' || clip.type=='webcam'){
				clip.pinnedStartTime = newStartTime
			}
			const finalStartTime = clip.relativeStartTime
			const shiftAmount = finalStartTime -initialStartTime 
			if(clip.type=='audio' || clip.type=='webcam'){
				updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration,pinnedStartTime:clip.pinnedStartTime })
				if(clip.type=='webcam') {
					const webcamAudioUpdates = this.moveAudioWithWebcam(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...webcamAudioUpdates]
				}

			}else{
				updatesArray.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,duration:clip.duration })
			 	if(clip instanceof VideoClip) {
					const zoomUpdates = this.moveZoomsWithVideoClip(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...zoomUpdates]
				}
			}
			updatesArray = adjustClipsDraggingLeft(sortedClips, draggedClipIndex, newStartTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing,shiftAmount,isDragPullMode)
			
			if(isDragPullMode){
				const allClips = this.getSortedClips()
				updatesArray=dragPullClips(allClips,clip,initialStartTime,shiftAmount,updatesArray)
			}
			this.pmManager.updateMultipleClipFields(updatesArray)
			this.calculateSceneDuration()
		}
	}

	adjustClipsRight(clip,targetEndTime,disableSnapping,pixelsPerSec,isDragPullMode){
		if(this.dndDirection != 'right'){
			this.dndDirection = 'right'
			this.enableDndPush=false
		}
		let updatesArray = [] 
		const initialStartTime = clip.relativeStartTime
		let sortedClips = this.getSortedTrackClips(clip.zIndex)
		const isAudioTrack = clip.type=='audio' || clip.type=='webcam'
		if(isAudioTrack){ //exclude the audio clips for placeholder webcam
			sortedClips = sortedClips.filter(c => !c.parentWebcamClip );
		}
		const draggedClipIndex = this.getClipIndex(sortedClips, clip.id);
		let newEndTime = targetEndTime
		const snappedClipTimes = getSnappedClipTimes(pixelsPerSec,newEndTime-clip.duration,clip,this.clips,this.startTime,this.endTime)	
		let isAlreadySnapped = false 
		const pushThreshold = PUSH_THRESHOLD_PIXELS/pixelsPerSec || PUSH_THRESHOLD

		if(clip.type!='audio' && clip.type!='webcam'){
			if(sortedClips[draggedClipIndex+1]){
				isAlreadySnapped = sortedClips[draggedClipIndex].endTime ==sortedClips[draggedClipIndex+1].startTime 
				if(isAlreadySnapped){
					if(!this.enableDndPush){
						if(targetEndTime -  sortedClips[draggedClipIndex+1].startTime  > pushThreshold){
								this.enableDndPush=true
							}
						}
					}
				}
				//TODO snap to scene end
			if(!isAlreadySnapped){
				newEndTime =snappedClipTimes.endTime
			}
	}
		
		if(!isAlreadySnapped || this.enableDndPush){ 
			clip.startTime = newEndTime - clip.duration
			if(clip.type=='audio' || clip.type=='webcam'){
				clip.pinnedStartTime = clip.startTime
			}
			const finalStartTime = clip.relativeStartTime
			const shiftAmount = finalStartTime -initialStartTime 

			if(clip.type=='audio' || clip.type=='webcam'){
				updatesArray.push({ clipId: clip.id, duration:clip.duration,pinnedStartTime:clip.pinnedStartTime,relativeStartTime: clip.relativeStartTime })
				if(clip.type=='webcam') {
					const webcamAudioUpdates = this.moveAudioWithWebcam(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...webcamAudioUpdates]
				}

			}else{
				updatesArray.push({ clipId: clip.id, duration:clip.duration,relativeStartTime: clip.relativeStartTime})
				 if(clip instanceof VideoClip) {
					const zoomUpdates = this.moveZoomsWithVideoClip(clip.id,shiftAmount)
					updatesArray = [...updatesArray,...zoomUpdates]
				}
				
			}
		
			updatesArray = adjustClipsDraggingRight(sortedClips, draggedClipIndex, newEndTime, updatesArray,this.moveZoomsWithVideoClip,this.moveAudioWithWebcam,isAudioTrack,this.audioClipSpacing)
		if(isDragPullMode){
			const allClips = this.getSortedClips()
			updatesArray=dragPullClips(allClips,clip,initialStartTime,shiftAmount,updatesArray)
		}
		this.pmManager.updateMultipleClipFields(updatesArray)

		this.calculateSceneDuration()
		}
	}


	//todo maybe for webcam audio time should be relative to parent webcam clip
	moveAudioWithWebcam=(clipId,shiftAmount)=>{
		let updates=[]
		this.clips.forEach((clip)=>{
		if(clip.type=='audio' && clip.parentWebcamClip==clipId){
			const newStartTime = Math.max(clip.startTime+=shiftAmount,0)
			clip.startTime=newStartTime
			updates.push({ clipId: clip.id, relativeStartTime: clip.relativeStartTime,pinnedStartTime:clip.pinnedStartTime})
			}
		})		
		return updates
	}


  getSortedClips() {
	  return this.clips.sort((a, b) => a.startTime - b.startTime);
  }

	getSortedTrackClips(zIndex) {
		let sameTrackClips = this.clips.filter(clip => clip.zIndex === zIndex);
		return sameTrackClips.sort((a, b) => a.startTime - b.startTime);
	}

  getClipIndex(clips, clipId) {
	  return clips.findIndex(c => c.id === clipId);
  }

	// adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, newStartTime,isAudioTrack,audioClipSpacing) {
	// 	let buffer = 0 //gap between clips
	// 	if(isAudioTrack){
	// 		buffer = audioClipSpacing
	// 	}
	// 	const totalDurationOfPrecedingClips = sortedClips.slice(0, draggedClipIndex)
	// 		.reduce((acc, c) => acc + c.duration + buffer, 0);
	// 	return Math.max(newStartTime, totalDurationOfPrecedingClips+this.startTime);
	// }

	adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, newStartTime, isAudioTrack, audioClipSpacing) {
    let totalDurationOfPrecedingClips = 0;
    if (isAudioTrack) {
        const precedingClips = sortedClips.slice(0, draggedClipIndex);
        for (let i = 0; i < precedingClips.length; i++) {
            const currentClip = precedingClips[i];
            totalDurationOfPrecedingClips += currentClip.duration;
            
            // Check if we need spacing after this clip (if it's not the last clip)
            if (i < precedingClips.length - 1) {
                const nextClip = precedingClips[i + 1];
                const requiresSpacing = currentClip.metadata.isVariable || 
                    nextClip.metadata.isVariable ||
                    currentClip.type !== 'webcam' || 
                    nextClip.type !== 'webcam' || 
                    currentClip.captureId !== nextClip.captureId;
                    
                if (requiresSpacing) {
                    totalDurationOfPrecedingClips += audioClipSpacing;
                }
            }
        }
    } else {
        totalDurationOfPrecedingClips = sortedClips.slice(0, draggedClipIndex)
            .reduce((acc, c) => acc + c.duration, 0);
    }
    return Math.max(newStartTime, totalDurationOfPrecedingClips + this.startTime);
}


  //Webcam clips with the same capture id can be next to each other without spacing
		// adjustStartTimeForPrecedingClips(sortedClips, draggedClipIndex, newStartTime, isAudioTrack, audioClipSpacing) {
		// 	let totalDurationOfPrecedingClips = 0;
		// 	if (isAudioTrack) {
		// 		const precedingClips = sortedClips.slice(0, draggedClipIndex);
		// 		for (let i = 0; i < precedingClips.length; i++) {
		// 			const currentClip = precedingClips[i];
		// 			totalDurationOfPrecedingClips += currentClip.duration;
		// 			// Add spacing if it's not the last clip and the next clip doesn't share webcam captureId
		// 			if (i < precedingClips.length - 1) {
		// 				const nextClip = precedingClips[i + 1];
		// 				if (!(currentClip.type === 'webcam' && nextClip.type === 'webcam' && 
		// 					currentClip.captureId === nextClip.captureId)) {
		// 					totalDurationOfPrecedingClips += audioClipSpacing;
		// 				}
		// 			}
		// 		}
		// 	} else {
		// 		totalDurationOfPrecedingClips = sortedClips.slice(0, draggedClipIndex)
		// 			.reduce((acc, c) => acc + c.duration, 0);
		// 	}
		// 	return Math.max(newStartTime, totalDurationOfPrecedingClips + this.startTime);
		// }

	calculateSceneDuration(isDnDMode){
		let maxEndTime = 0;
		this.clips.forEach(clip => {
			if (clip.endTime > maxEndTime) {
				maxEndTime = clip.endTime;
				if(clip.type=='audio'){
					maxEndTime +=this.audioClipSpacing
				}
			}
		});
		maxEndTime -=this.startTime 
		//if empty make it 5 seconds otherwise no min duration 
		this.duration = maxEndTime > 0 ? maxEndTime : MIN_SCENE_DURATION;
		if(this.onDurationUpdate){
			this.onDurationUpdate()
		}
	}

	getClipsAsJson() {
		return this.clips.map(clip => clip.toJSON());
	}

	toJSON() {
		const clips = this.getClipsAsJson()
		return {
		  id: this.id,
		  title:this.title,
		  clips:clips,
		  duration:this.duration,

		  sceneIndex:this.sceneIndex,
		  sceneStartTime:this.startTime

		};
  }

 get endTime() {
		return this.startTime+this.duration
	}

	destroy() {
		this.clips.forEach(clip => {
			if (clip.destroy && typeof clip.destroy === 'function') {
				clip.destroy();
			}
		});
		this.clips = null;
	}


}


export { TimelineScene }


