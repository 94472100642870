import React, { useState } from 'react'
import Icon from '../../misc/Icon'
import EditorDetailPanelText from './EditorDetailPanelText'
import EditorDetailPanelWebcam from './EditorDetailPanelWebcam'
import EditorDetailPanelImage from './EditorDetailPanelImage'
import EditorDetailPanelScreen from './EditorDetailPanelScreen'
import EditorDetailPanelVideo from './EditorDetailPanelVideo'
import EditorDetailPanelBasicVideo from './EditorDetailPanelBasicVideo'
import EditorDetailPanelMobile from './EditorDetailPanelMobile'
import EditorDetailPanelSlide from './EditorDetailPanelSlide'
import EditorDetailPanelLayoutGroup from './EditorDetailPanelLayoutGroup'
import EditorDetailPanelChart from './charts/EditorDetailPanelChart'
import EditorDetailPanelMixedSelection from './EditorDetailPanelMixedSelection'

const EditorDetailPanel = (props) => {

  const {selectedSlideItems,activePanelClip,focusedSlideElement} = props

  let detailPanelType = null
  let selectedElement = null;
  let selectedLayoutGroupId = null

  if (activePanelClip && activePanelClip.type === 'slide') {
    detailPanelType = 'slide';
    if(selectedSlideItems.length==1 && selectedSlideItems[0].type=='layoutGroup'){
      detailPanelType='layoutGroup'
      selectedLayoutGroupId = selectedSlideItems[0].id
      //selectedLayoutGroup = 
    }

    else if (selectedSlideItems.length === 1 && selectedSlideItems[0].type=='element') {
      const selectedElementId = selectedSlideItems[0].id;
      selectedElement = activePanelClip.elements.find(element => element.id === selectedElementId);
      if (selectedElement) {
      if(selectedElement.type=='image'){
        detailPanelType='image'
      }
      if(selectedElement.type=='text'){
        detailPanelType='text'
      }
      if(selectedElement.type=='chart'){
        detailPanelType='chart'
      }
    } else {
      console.warn('Selected element not found in activePanelClip.elements');
      }
    } 
  }

  if (activePanelClip && activePanelClip.type === 'video' && !activePanelClip.isBasicVideo && !activePanelClip.isDeviceRecording) {
    detailPanelType = 'screen';
  }
  if (activePanelClip && activePanelClip.type === 'video' && !activePanelClip.isBasicVideo && activePanelClip.isDeviceRecording) {
    detailPanelType = 'mobile';
  }
  if (activePanelClip && activePanelClip.type === 'webcam') {
    detailPanelType = 'webcam';
  }
  if (activePanelClip && activePanelClip.isBasicVideo) {
    detailPanelType = 'basicVideo';
  }

  const handleDevButtonClick = () => {
    setCurrentClipTypeIndex((prevIndex) => (prevIndex + 1) % clipTypes.length)
  }

  if(selectedSlideItems.length>1){
    detailPanelType='mixedSelection'
  }


  return (      
    <div data-detail-panel-type={detailPanelType ? detailPanelType : 'none'} className='editor-detailPanel'>
      {detailPanelType === 'slide' &&
        <EditorDetailPanelSlide
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideAlignment={props.updateSlideAlignment}
          slideClip={props.activePanelClip}
          updateSlideElementAnimationIndex={props.updateSlideElementAnimationIndex}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          updateSlideBackgroundColor={props.updateSlideBackgroundColor}
          clipId={activePanelClip.id}
          projectId={props.projectId}
        />
      }      
      {detailPanelType === 'text' && 
      	<EditorDetailPanelText 
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          clipId={activePanelClip.id}
          element={selectedElement}
          updateSlideElementZOrder={props.updateSlideElementZOrder}
          slideElementTextSelectionInfo={props.slideElementTextSelectionInfo}
          focusedSlideElement={focusedSlideElement}
          updateSlideTextElementTextProperties={props.updateSlideTextElementTextProperties}
      	/>
      }

       {detailPanelType === 'chart' && 
        <EditorDetailPanelChart 
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          handleDimensionsUpdatedFromDetailPanel={props.handleDimensionsUpdatedFromDetailPanel}
          projectBackgroundId={props.projectBackgroundId}
          slideBackgroundId={activePanelClip.background}
          clipId={activePanelClip.id}
          element={selectedElement}
        />
      }
     {detailPanelType === 'webcam' &&
	      <EditorDetailPanelWebcam 
          key={activePanelClip.id}
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateClipMetadata={props.updateClipMetadata}
          clip={activePanelClip}
          toggleTrimMode={props.toggleTrimMode}
          updateTranscripPanelFromTimeline={props.updateTranscripPanelFromTimeline}
	      />
    	}

    	{detailPanelType === 'screen' &&
	      <EditorDetailPanelScreen
          clip={activePanelClip}
	      	isDragResizingNumberInput={props.isDragResizingNumberInput}
      		setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}    
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          trimMode={props.trimMode}
          toggleTrimMode={props.toggleTrimMode}
          updateClipMetadata={props.updateClipMetadata}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          defaultMotionStyle={props.defaultMotionStyle}
          projectBackgroundId={props.projectBackgroundId}
          updateClipBackgroundColor={props.updateClipBackgroundColor}
          changeVideoClipPlaybackRate={props.changeVideoClipPlaybackRate}      
	      />
    	}

      {detailPanelType === 'mobile' &&
        <EditorDetailPanelMobile
          clip={activePanelClip}
          isDragResizingNumberInput={props.isDragResizingNumberInput}          
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          trimMode={props.trimMode}
          toggleTrimMode={props.toggleTrimMode}
          updateClipMetadata={props.updateClipMetadata}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          defaultMotionStyle={props.defaultMotionStyle}
          projectBackgroundId={props.projectBackgroundId}
          updateClipBackgroundColor={props.updateClipBackgroundColor}        
          changeVideoClipPlaybackRate={props.changeVideoClipPlaybackRate}      
        />
      }



      {detailPanelType === 'basicVideo' &&
        <EditorDetailPanelBasicVideo
          isDragResizingNumberInput={props.isDragResizingNumberInput}
          setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          updateClipMetadata={props.updateClipMetadata}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
          updateClipBackgroundColor={props.updateClipBackgroundColor}
          clip={activePanelClip}
          toggleTrimMode={props.toggleTrimMode}
        />
      }




    	{detailPanelType === 'video' &&
	      <EditorDetailPanelVideo
	      	isDragResizingNumberInput={props.isDragResizingNumberInput}
      		setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateClipAnimationSettings={props.updateClipAnimationSettings}
	      />
    	}

      
      {detailPanelType === 'image' && 
      	<EditorDetailPanelImage 
          key={selectedElement.id}
      		isDragResizingNumberInput={props.isDragResizingNumberInput}
      		setIsDragResizingNumberInput={props.setIsDragResizingNumberInput}
          updateSlideElementMetadata={props.updateSlideElementMetadata}
          handleDimensionsUpdatedFromDetailPanel={props.handleDimensionsUpdatedFromDetailPanel}
          clipId={activePanelClip.id}
          element={selectedElement}
          updateSlideElementZOrder={props.updateSlideElementZOrder}
          insertImageFromRecent={props.insertImageFromRecent}
          handleSlideImageFileUpload={props.handleSlideImageFileUpload}
          showReplaceImageMediaModal={props.showReplaceImageMediaModal}
          handleSetShowReplaceImageMediaModal={props.handleSetShowReplaceImageMediaModal}
      	/>
      }

      {detailPanelType === 'layoutGroup' && 
        <EditorDetailPanelLayoutGroup
          slide={activePanelClip}
          layoutGroupId={selectedLayoutGroupId}
          updateLayoutGroupField={props.updateLayoutGroupField}
          ungroupLayoutGroup={props.ungroupLayoutGroup}
     
        />
      }





      {detailPanelType === 'mixedSelection' && 
        <EditorDetailPanelMixedSelection
          groupSlideItems={props.groupSlideItems}
        />
      }


              
    </div>
  )
}

export default EditorDetailPanel