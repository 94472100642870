import {TimelineScene} from './TimelineScene'
import {saveProjectTimeline} from '../utils/projectUtils/saveProjectTimeline'
import debounce from 'lodash/debounce'
import {randomID} from '../utils/randomID'
import {getBackgroundMusicTrackForId} from '../utils/backgroundMusic/getBackgroundMusicTrackForId'
import {calculateTimelineTimeFromVideoTime} from './utils/calculateTimelineTimeFromVideoTime'
import {calulateVideoTimeFromTimelineTime} from './utils/calulateVideoTimeFromTimelineTime'
import {calculateTrimmedSegments} from './utils/calculateTrimmedSegments'
import { getFontForTextStyle } from '../utils/brands/getFontForTextStyle'
import {fadeVolumeTowardsEnd} from './utils/fadeVolumeTowardsEnd'
import { ProseMirrorManager } from './prosemirrorManager/ProseMirrorManager'
import {AudioClip} from './AudioClip'
import {VideoClip} from './VideoClip'
import {CodecVideoClip} from './CodecVideoClip'
import {TextSlideClip} from './TextSlideClip'
import {WebcamClip} from './WebcamClip'
import {findActiveSegmentForVideoClip} from './utils/findActiveSegmentForVideoClip'
import {createProjectSettingsPmNode} from './prosemirrorManager/nodeCreators'
import {createVideoClipObjFromCaptureId} from './clipObjCreators/createVideoClipObjFromCaptureId'
import {estimateAudioDuration} from '../utils/estimateAudioDuration'
import {convertSlideNodeToJSON} from './utils/convertSlideNodeToJSON'
import {getVoiceForId} from '../utils/voiceover/getVoiceForId'
import {webcamClipDefaultMetadata} from '../utils/webcam/webcamConfigs'
import {getTranscriptForWebcamCaptureId} from '../utils/webcam/getTranscriptForWebcamCaptureId'
import { getFaceBoxForWebcamCaptureId } from '../utils/webcam/getFaceBoxForWebcamCaptureId'

const TICK_LENGTH=20
const SYNC_INTERVAL = 200 //was 500 might want to play with this
const BACKGROUND_TRACK_VOLUME=0.08
const INFINITE_TIMELINE = true
const MIN_SPLIT_CLIP_DURATION=0.5 //if split clip is less than this then delete it

function makeSceneTemplate(){
  return{
    id: randomID(), 
    sceneIndex:0,
    startTime:0,
    duration:5,
    clips: []
  }
}

//Scenes have an index
//scene start time is calculated based on scenes before it when do calculateDuration
//We save the clip start time relative to the scene
//but for use in the app start time is a calculated field and absolute time on timeline
//most operations go through the scene which handles things like resolving clip conflicts and stuff

const AUDIO_CLIP_SPACING=0.15 //gap between clips

class Timeline {
	
	constructor(isExport,projectId,activeVoice,backgroundMusicTrack,onTimeUpdate,handlePlaybackEnded,handleClipMediaLoaded,setPMDocForDevMode,projectBackgroundId,handleUpdateProjectBackground,voiceoverPlaybackRate,backgroundMusicVolume,pmManager,transcriptPmManager,handleVoiceoverAudioFileUpdated,setPreviewingAudioClipId,showCaptions,handleTextElementFontLoaded,handleCreateVoiceMatchForProject) {
		this._projectBackground = null
		this._currentTime = 0;
		this._isPlaying = false;
		this._playbackInterval = null;
		this._syncInterval = null
		this._onTimeUpdate = onTimeUpdate
		this._handlePlaybackEnded = handlePlaybackEnded
		this.handleClipMediaLoaded=handleClipMediaLoaded
		this.handleVoiceoverAudioFileUpdated=handleVoiceoverAudioFileUpdated
		this._projectId = projectId
		this._activeVoice = activeVoice 
		this.voiceoverPlaybackRate=voiceoverPlaybackRate
		this._backgroundMusicTrack = backgroundMusicTrack
		this.setPMDocForDevMode=setPMDocForDevMode
		this.setPreviewingAudioClipId=setPreviewingAudioClipId

		this.projectBackgroundId = projectBackgroundId
		this.handleUpdateProjectBackgroundOnUndo=handleUpdateProjectBackground
		this.backgroundMusicVolume=backgroundMusicVolume|| BACKGROUND_TRACK_VOLUME
		this.debouncedSaveProjectTimeline = debounce(this.handleSaveProjectTimeline, 1000);
		this.maxTimelineDurationSeconds = 120
		this.backgroundMusicElement=null
		if(this._backgroundMusicTrack){
			this.initBackgroundMusic();
		}

		this.currentVideoSegment = null

		this._scenes = [];
		this.isDnDMode = false //command 
		this.isDragPullMode = false //command shift
		this.isDragging =false 
		this.dragClipId = null

		this.dragClipZIndex = null
		this.pmManager = pmManager
		this.transcriptPmManager=transcriptPmManager 
		this.isExport = isExport
		this.handleTextElementFontLoaded=handleTextElementFontLoaded
		this.handleCreateVoiceMatchForProject=handleCreateVoiceMatchForProject
		this.variables=[]
		this.showCaptions = showCaptions

		this.uniqueWebcamCaptureIds = [];
	}

	getUniqueNonVariableWebcamCaptureIds() {
	  const uniqueCaptureIds = new Set(
	    this.clips
	      .filter(clip => clip.type === 'webcam' && !clip.metadata.isVariable)
	      .map(clip => clip.captureId)
	  );
	  return Array.from(uniqueCaptureIds);
	}

	checkUniqueWebcamCaptureIdsChange() {
		console.log('check for unique capture ids--------')
	  const oldIds = this.uniqueWebcamCaptureIds;
	  const newIds = this.getUniqueNonVariableWebcamCaptureIds();
	  
	  // Compare oldIds and newIds. Here we can just compare their sorted strings for simplicity.
	  const oldIdsSorted = oldIds.slice().sort().join(',');
	  const newIdsSorted = newIds.slice().sort().join(',');
	  
	  if (oldIdsSorted !== newIdsSorted) {
	    this.uniqueWebcamCaptureIds = newIds; // Update the stored IDs
	    this.handleUniqueWebcamIdsChanged(newIds, oldIds);
	  }
	}

	handleUniqueWebcamIdsChanged=(newIds,oldIds)=>{
		if(this.handleCreateVoiceMatchForProject){
			this.handleCreateVoiceMatchForProject(newIds)
		}
	//	this.handleCreateVoiceMatchForProject()
	}

	applyVoiceMatchResult(result){
		console.log('apply voice match result')
		this.updateActiveVoice(result.voiceId)
		this.clips.forEach((clip)=>{
			if(clip.type=='webcam'){
				clip.metadata.audioTransformation={
					type:'voiceMatch',
					voiceId:result.voiceId
				}
				clip.handleUpdateAudioTrack()
			}
		})
	}


	//Some utils
	findClipForId(clipId) {
		for (const scene of this._scenes) {
			for (const clip of scene.clips) {
				if (clip.id === clipId) {
					return clip;
				}
			}
		}
		return null;
	}

	findSceneForId(sceneId) {
		for (const scene of this._scenes) {
			if (scene.id == sceneId) {
				return scene;
			}	
		}
		return null;
	}

	findLastScene(){
		this._scenes.sort((a, b) => a.startTime - b.startTime)
		return this._scenes[this._scenes.length-1]
	}

	findSceneForCurrentTime() {
		for (const scene of this._scenes) {
			if (this._currentTime >= scene.startTime && this._currentTime < scene.endTime) {
				return scene;
			}
		}
		if(this._currentTime == this.duration){
			return this._scenes[this._scenes.length-1]
		}
		return null;
	}

	findSceneForTime(time) {
		for (const scene of this._scenes) {
			if (time >= scene.startTime && time <= scene.endTime) {
				return scene;
			}
		}
		if(time >= this.duration){
			return this._scenes[this._scenes.length-1]
		}
		return null;
	}

	toggleShowCaptions=()=>{
		this.showCaptions=!this.showCaptions
	}

	async initScenes(scenes){//This happens on load timeline
		scenes.forEach((scene)=>{
			this.addScene(scene, true)
		})
		this.calculateDuration()
		this.calculateUniqueVariables()
	//	this.checkUniqueWebcamCaptureIdsChange()
	}

	moveClipBetweenScenesOnSplit=(clip,newSceneId,startTimeOffset,updatesArray)=>{
		let originalScene = clip.scene
		let newScene = this.findSceneForId(newSceneId)
		clip.scene = newScene
		clip.startTime -= startTimeOffset
		newScene.moveClipIntoScene(clip)
		originalScene.moveClipOutOfScene(clip.id)
		updatesArray.push({clipId:clip.id,relativeStartTime:clip.relativeStartTime,sceneId:newSceneId})
	}

	

mergeScene=(sceneId,mergeDirection)=>{ //this merges the scene after sceneId with sceneId unless mergeDirection is "before" when it merges this scene with the one before it
    const actionScene = this.findSceneForId(sceneId)
    const sceneIndex = actionScene.sceneIndex 
    let scene
    if(mergeDirection=='before' && sceneIndex==0 && this.scenes.length==1){
        scene=actionScene
        scene.title='Default title'
        let updatesArray=[]
        updatesArray.push({
            type:'updateTitle',
            sceneId:sceneId,
            title:'Default title'
        })
        this.pmManager.updateMultipleClipFields(updatesArray)
        this.pmManager.endAction()
        this.updateTranscriptFromTimeline()
    }else{
        let sceneToMerge 
        if(mergeDirection=='before'){
            scene = this.scenes[sceneIndex-1]
            sceneToMerge = actionScene
        }else{
            scene = actionScene
            sceneToMerge = this.scenes[sceneIndex+1]
        }

        if(sceneToMerge){
            this.pmManager.startAction('mergeScene')
            let updatesArray=[]
            
            // Get all audio clips from both scenes and sort them by their current positions
            const allAudioClips = [
                ...scene.clips.filter(clip => (clip instanceof AudioClip)),
                ...sceneToMerge.clips.filter(clip => (clip instanceof AudioClip))
            ].sort((a, b) => {
                // Sort by absolute timeline position
                return (a.scene.startTime + a.relativeStartTime) - (b.scene.startTime + b.relativeStartTime);
            });

            // Get all non-audio clips that need to be moved
            const nonAudioClips = sceneToMerge.clips.filter(clip => !(clip instanceof AudioClip));
            
            // Move all clips from sceneToMerge to scene
            [...nonAudioClips, ...sceneToMerge.clips.filter(clip => (clip instanceof AudioClip))].forEach(clip => {
                clip.scene = scene;
                clip.relativeStartTime += scene.duration;
                scene.moveClipIntoScene(clip);
                sceneToMerge.moveClipOutOfScene(clip.id);
                updatesArray.push({
                    clipId: clip.id,
                    relativeStartTime: clip.relativeStartTime,
                    sceneId: clip.scene.id
                });
            });

            // Reassign clipIndex values to maintain proper order
            allAudioClips.forEach((clip, index) => {
                clip.clipIndex = index;
                updatesArray.push({
                    clipId: clip.id,
                    clipIndex: index
                });
            });

            this.pmManager.updateMultipleClipFields(updatesArray)
            this.deleteScene(sceneToMerge.id)
            this.pmManager.endAction()
            this.updateTranscriptFromTimeline()
            this.calculateAudioTrackSpacing()
            this.recalculateSceneDurations()
        }
    }
}

	

	updateTranscriptPanelOnLoadWebcam=()=>{
		this.updateTranscriptFromTimeline()
	}


	addSceneAfterScene=(sceneId)=>{
		let sceneBefore = this.findSceneForId(sceneId)
		const sceneIndex = sceneBefore.sceneIndex 
		const sceneAfter = this.scenes[sceneIndex+1]
		let newScene = makeSceneTemplate()
		newScene.startTime=sceneBefore.startTime 
		newScene.sceneIndex = sceneAfter.sceneIndex 
		sceneAfter.sceneIndex+=1 
		sceneAfter.startTime +=newScene.duration
		this.addScene(newScene,false)
	}

	splitScene(targetSplitTime,presetSceneId,newSceneTitle){
		const splitTime = targetSplitTime || this._currentTime
		this.pmManager.startAction('splitScene')

		const currentScene = this.findSceneForTime(splitTime)
		let clipsToMove=[]
		currentScene.clips.forEach((clip)=>{
			if(clip.startTime >= splitTime){
				clipsToMove.push(clip)
			}
		})
		let updatesArray=[]
		const startTimeOffset = splitTime - currentScene.startTime
		let newSceneId
		if(currentScene){
			const currentSceneIndex = currentScene.sceneIndex
			//bunp all other scenes by +1 
			this._scenes.forEach(scene => {
				//here do it
				if(scene.title=='Default title'){
					scene.title='Untitled Scene'
					let updatesArray=[]
					updatesArray.push({
						type:'updateTitle',
						sceneId:scene.id,
						title:'Untitled Scene'
					})
				}

				if (scene.sceneIndex > currentSceneIndex) {
					scene.sceneIndex += 1;
					this.pmManager.updateSceneIndex(scene.id,scene.sceneIndex)
				}
			});
			//then add a new one
			let scene = makeSceneTemplate()
			if(presetSceneId){
				scene.id=presetSceneId 
				scene.title = newSceneTitle || 'Untitled Scene'
			}
			scene.startTime = this.duration 
			scene.sceneIndex = currentSceneIndex+1
			this.addScene(scene,false)
			newSceneId = scene.id
		}
		//move the clips between scenes
		clipsToMove.forEach((clip)=>{
			this.moveClipBetweenScenesOnSplit(clip,newSceneId,startTimeOffset,updatesArray)
		})
		//do the 
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.recalculateSceneDurations()
		this.pmManager.endAction()
		this.updateTranscriptFromTimeline()
		this.calculateAudioTrackSpacing()
	}

	updateTranscriptFromTimeline(){
		const audioTrackClips = this.clips.filter(clip => clip.zIndex==-1)
		this.transcriptPmManager.updateTranscriptFromTimeline(audioTrackClips,this._scenes)
		this.calculateAudioTrackSpacing()
	}

	deleteScene(sceneId,isPMUndoRedo){
		if(!isPMUndoRedo){
			this.pmManager.startAction('deleteScene')
		}
		const sceneIndex = this._scenes.findIndex(s => s.id === sceneId)
		if(sceneIndex !== -1){
			const scene = this._scenes[sceneIndex];
			const clipIdSet = new Set(scene.clips.map(clip => clip.id));
			clipIdSet.forEach((clipId)=>{
				scene.deleteClip(clipId,isPMUndoRedo)
			})
			if (scene.destroy && typeof scene.destroy === 'function') {
				scene.destroy();
			}
			this._scenes.splice(sceneIndex, 1);
			if(!isPMUndoRedo){
				this.pmManager.deleteNodeFromPmDoc(sceneId)
			}
		}
		this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
		//lets redo the scene indexes
		this._scenes.forEach((scene,i)=>{
			scene.sceneIndex = i 
			if(!isPMUndoRedo){
				this.pmManager.updateSceneIndex(scene.id,i)
				}
			})
		this.calculateDuration()
		if(!isPMUndoRedo){
			this.pmManager.endAction()
		}
		this.calculateUniqueVariables()
		this.updateTranscriptFromTimeline()
		this.debouncedSaveProjectTimeline()
	}

	getSceneAudioTrackClips(sceneId){
		/// type audio with parentWebcamClip 
		const audioTrackClips = this.clips
			.filter(clip => (
				clip.sceneId == sceneId &&
				(clip.type === 'audio' || clip.type=='webcam') && !clip.parentWebcamClip))
			.sort((a, b) => a.clipIndex - b.clipIndex);


		return audioTrackClips

	}

	addSceneFromTranscriptPanel(splitSceneId,splitClipId,splitClipIndex){
		const newSceneId = randomID()
		const scene = this.findSceneForId(splitSceneId)
		const originalTitle = scene.title

		if(originalTitle=='Default title'){
			//we just update the name to untitled scene and dont actually split
			let updatesArray=[]
			updatesArray.push({
				type:'updateSceneTitle',
				sceneId:splitSceneId,
				title:'Untitled Scene'
			})
			this.updateTimelineFromTranscript(updatesArray)
			this.transcriptPmManager.focusSceneHeader(splitSceneId)
			this.updateTranscriptFromTimeline()
		}
		
		if(splitClipIndex!=0 || originalTitle!='Default title'){
		
			let targetSplitTime
			if(scene){
				if(splitClipIndex==0){
					targetSplitTime = scene.startTime + 0.01
				}else{
					const audioTrackClips = this.getSceneAudioTrackClips(splitSceneId)
					const splitClip = audioTrackClips[splitClipIndex-1]
					//const audioClips = scene.clips.filter(clip => (clip.type=='audio'))
					//const audioClip = audioClips[Math.min(splitClipIndex-1,audioClips.length-1)] //there can be less clips than in the transcript cos empty ones dont count TODO handle lots of empty transcript chunks when splitting so find the right place to split				
				//	const jsonString = JSON.stringify(audioClip, null, 2);
					this.deleteClipById(splitClipId)	
					targetSplitTime = splitClip.endTime
				}
				this.splitScene(targetSplitTime,newSceneId)
			}
			this.calculateAudioTrackSpacing()
			this.updateTranscriptFromTimeline()
			this.transcriptPmManager.focusSceneHeader(newSceneId)
		}
	}

	addNewScene(){ //adds a scene at the hend of the project
		let scene = makeSceneTemplate()
		scene.startTime = this.duration 
		scene.sceneIndex = this._scenes.length
		this.addScene(scene,false)
		this.transcriptPmManager.focusSceneHeader(scene.id)
	}

	getTranscript = () => {
		const audioClips = this.clips.filter(clip => clip instanceof AudioClip);
		const sortedAudioClips = audioClips.sort((a, b) => a.startTime - b.startTime);
		const transcript = sortedAudioClips.map(clip => {
			if (clip.metadata && clip.metadata.text) {
				return clip.metadata.text.trim();
			}
			return '';
		}).join(' ');
		return transcript;
	}

	addScene(scene,isInitialLoad,isPMUndoRedo) {
		const timelineScene = new TimelineScene(scene,this.pmManager,this.transcriptPmManager,this.handleClipMediaLoaded,this.debouncedSaveProjectTimeline,this._projectId,this.handleVoiceoverAudioFileUpdated,this.onSceneDurationChange,this.moveClipBetweenScenes,this.isLastScene,this.setPreviewingAudioClipId,this._activeVoice,this.getTranscript,this.isExport,this.handleTextElementFontLoaded,this.updateTranscriptPanelOnLoadWebcam )		
		timelineScene.initScene(isInitialLoad,scene.clips,isPMUndoRedo,AUDIO_CLIP_SPACING)
		this._scenes.push(timelineScene);
		if(!isInitialLoad){
			this.updateTranscriptFromTimeline()
			this.calculateDuration()
			this.debouncedSaveProjectTimeline()
		}		
	}

	addClip(clip,isInitialLoad,isPMUndoRedo){
		let scene 
		const isWebcamRecording = clip.type=='webcam' && !clip.metadata.isVariable
		if(clip.sceneId){
			scene = this.findSceneForId(clip.sceneId)
		}else{
			scene=this.findSceneForCurrentTime() 
			if(!scene){
				if(this._currentTime > this._duration){
					scene=this.findLastScene()
				}
			}
		}
		if(scene){
			if(isWebcamRecording){
				//calculate the clipIndex need to do this before we calculate audio track spacing in updateTranscriptFromTimeline
				const sceneClips = this.clips.filter(clip => clip.sceneId === scene.id);
				const audioTrackClips = sceneClips
				.filter(clip => clip.type === 'audio' || (clip.type=='webcam' && !clip.metadata.isVariable))
				.sort((a, b) => a.startTime - b.startTime);

				let webcamClipIndex = 0 
				if(audioTrackClips.length){
					const clipBefore = audioTrackClips
						.filter(existingClip => existingClip.startTime <= clip.startTime)
						.pop()
					const clipAfter = audioTrackClips
						.find(existingClip => existingClip.startTime > clip.startTime)

					if (clipBefore) {
						// Put it 0.5 after the previous clip's index
						webcamClipIndex = audioTrackClips.indexOf(clipBefore) + 0.5
					} else if (clipAfter) {
						// Put it 0.5 before the next clip's index
						webcamClipIndex = audioTrackClips.indexOf(clipAfter) - 0.5
					}
				}
			//	console.log(`calculated index is ${webcamClipIndex}`)
				 clip.clipIndex = webcamClipIndex	
			}



			scene.addClip(clip,isInitialLoad,isPMUndoRedo,this.voiceoverPlaybackRate)
			this.debouncedSaveProjectTimeline()
		}else{
			console.log('cant find scene to add clip')
		}

		if(isWebcamRecording){
			this.updateTranscriptFromTimeline()
		}
	//	this.checkUniqueWebcamCaptureIdsChange()
	}

	deleteClipById(clipId) {
		const clip = this.findClipForId(clipId)
		if(clip){
			this.deleteClip(clip)
		//	if(clip.type=='webcam' && !clip.metadata.isVariable){
			if(clip.type=='webcam'){
				this.updateTranscriptFromTimeline()
			}

		}
		this.calculateUniqueVariables()
		this.debouncedSaveProjectTimeline()
	}

	async initBackgroundMusic() {
		const track = getBackgroundMusicTrackForId(this._backgroundMusicTrack)
		if(track){
			if(track.isUpload){
				const trackData = await ipcRenderer.invoke('read-background-music-file', this._backgroundMusicTrack);
				this.backgroundMusicElement = new Audio(trackData);
				this.backgroundMusicElement.preload = 'auto';
				this.backgroundMusicElement.load();
				this.backgroundMusicElement.volume = this.backgroundMusicVolume
			}else{
				this.backgroundMusicElement = new Audio()
				this.backgroundMusicElement.preload = 'auto'; 
				this.backgroundMusicElement.src = track.src;
				this.backgroundMusicElement.load();
				this.backgroundMusicElement.volume = this.backgroundMusicVolume
			}
			this.backgroundMusicElement.loop = true
		}
	}

///// PLAYBACK //////
	play() {
		if (this._isPlaying) return	
		if (this._currentTime >= this._duration) {
			this._currentTime = this._duration;
			this.pause();
			this._handlePlaybackEnded();
			this._onTimeUpdate(this._currentTime)
      return;
    }	
		this._isPlaying = true
		// if(this.backgroundMusicElement){
		// 	this.backgroundMusicElement.play()
		// }
		if (this.backgroundMusicElement) {
        this.backgroundMusicElement.play().catch(error => {
            console.warn('Background music playback failed:', error);
            // Continue timeline playback even if background music fails
        });
    }
		
		let lastUpdateTime = performance.now();
		const updatePlayback = () => {
			let now = performance.now();
			let deltaTime = (now - lastUpdateTime) / 1000;
			lastUpdateTime = now;
			this._currentTime = parseFloat((this._currentTime + deltaTime).toFixed(2));
			if (this.backgroundMusicElement) {
				const newVolume = fadeVolumeTowardsEnd(this._currentTime, this._duration,this.backgroundMusicVolume);
				this.backgroundMusicElement.volume = newVolume;
			}
			if (this._currentTime >= this._duration) {
				this._currentTime = this._duration
				this.pause(); // Automatically pause when the end is reached
				this._handlePlaybackEnded();
			} else {
				this.updateClipsPlaybackState();
			}
			if (this._onTimeUpdate) {
				this._onTimeUpdate(this._currentTime); // Update parent component
			}
		};
		updatePlayback();
    this._playbackInterval = setInterval(updatePlayback, TICK_LENGTH);
		this._syncInterval = setInterval(() => {
			this.synchronizeTimeline();
		}, SYNC_INTERVAL);
	}


	updateClipsPlaybackState() {
		this.clips.forEach(clip => {
			if (this._currentTime >= clip.startTime && this._currentTime < clip.endTime) {
				if (clip instanceof VideoClip  || clip instanceof CodecVideoClip || clip instanceof WebcamClip) {
					let activeSegment = findActiveSegmentForVideoClip(this._currentTime, clip) 
					if(activeSegment){
						const currentSegment = activeSegment.segment 
						const isAfterCollapsedSkipSegment = activeSegment.isAfterCollapsedSkipSegment
						if(isAfterCollapsedSkipSegment && currentSegment.id != this.currentVideoSegment){
							clip.seek(this._currentTime)
						}

						if(currentSegment && currentSegment.isQuiet){
							if(!clip.video.paused || currentSegment.id !== this.currentVideoSegment){
								if(!currentSegment.isManualFreeze){ //don't seek for manual freeze zones
									clip.seek(clip.startTime+ currentSegment.startTime + ((currentSegment.originalDuration - 0.000000000001) / (currentSegment.playbackRate * clip.clipPlaybackRate )))
								}else{
									clip.seek(this._currentTime)
								}
	            	clip.pause();
	            	this.currentVideoSegment = currentSegment.id
							}
						}
						else if (clip.video.paused) {
							clip.playFromCurrentTime(this._currentTime);
							this.currentVideoSegment = currentSegment.id
							this.synchronizeTimeline();
						}	else{
							this.currentVideoSegment=currentSegment.id
						}
					}else{

					}
				}
				 else if (clip instanceof AudioClip && clip.audio.paused) {
					clip.playFromCurrentTime(this._currentTime);
				}
			} else {
				if (clip instanceof AudioClip && !clip.audio.paused ||
					clip instanceof VideoClip && !clip.video.paused || 
					clip instanceof WebcamClip && !clip.video.paused) {
						clip.pause();
					}
				}
			});
		} 

	synchronizeTimeline() {
		this.clips.forEach(clip => {
			if(clip instanceof VideoClip && !clip.video.paused && !clip.isBasicVideo) { //TODO do we need to sync thing
				if(this._currentTime>=clip.startTime && this._currentTime<clip.startTime+clip.duration){
					const videoCurrentTime = clip.video.currentTime
					const timelineTime = calculateTimelineTimeFromVideoTime(videoCurrentTime,clip)
					if(clip.captureId!='990947292'){ //hacky thing for clay bug
						this._currentTime = timelineTime
					}
				//	console.log(`timeline time is------ ${timelineTime}`)
					
				}
			}
		});
	}

	pause() {
		if (!this._isPlaying) return;
		this._isPlaying = false;
		if(this.backgroundMusicElement){
			this.backgroundMusicElement.pause()
		}
		clearInterval(this._playbackInterval);
		this._playbackInterval = null;
		clearInterval(this._syncInterval);
		this._syncInterval = null;
		this.clips.forEach(clip => {
			if (clip instanceof AudioClip || clip instanceof VideoClip || clip instanceof WebcamClip) {
				clip.seek(this._currentTime);
				clip.pause();
			}	
		});
	}
	
	seek(time) {
		const wasPlaying = this._isPlaying;
		if (wasPlaying) {
			this.pause();
		}
		this._currentTime =Math.max(time, 0) //allow seeking beyond video

		if(this.backgroundMusicElement){
			this.backgroundMusicElement.currentTime = this._currentTime;
		}
		if (this._onTimeUpdate) {
			this._onTimeUpdate(this._currentTime); // Update parent component
		}
		if (wasPlaying) {
			this.play();
		}
		this.clips.forEach(clip => {
			if (clip instanceof AudioClip || clip instanceof VideoClip || clip instanceof WebcamClip || clip instanceof CodecVideoClip) {
				clip.seek(this._currentTime);
			} 
		});
	}

////////////////////////////// PROSEMIRROR ////////////////////////
	createPMDoc() {
		if(this.pmManager){
			let contentArray = [];
			const projectSettingsNode = createProjectSettingsPmNode(this.projectBackgroundId, this.voiceoverPlaybackRate);
			contentArray.push(projectSettingsNode);
			this.pmManager.createDocument(contentArray)
		}
	}

///// DRAG /////
	onDragStart(clip,isDnDMode,isDragPullMode) {
		this.isDnDMode = isDnDMode
		this.isDragPullMode = isDragPullMode
		this.pmManager.onDragStart()
		this.isDragging = true 
		this.dragClipId=clip.id
		this.dragClipZIndex=clip.zIndex
		this._scenes.forEach((scene)=>{
			scene.onDragStart(this.dragClipZIndex)
		})
	}

	onDragEnd(dragClip,dropTime,activeDropType) {
		if(this.isDnDMode && dropTime||dropTime==0){
			this._scenes.forEach((scene)=>{
				scene.onDnDDragEnd(dragClip,dropTime,activeDropType)
			})
		}
		this.recalculateSceneDurations()
		this.pmManager.onDragEnd()
		this.isDragging = false 
		this.dragClipId=null
		this.dragClipZIndex = null
		this.debouncedSaveProjectTimeline();
		this.isDnDMode=false
		this.isDragPullMode = false
	}

	handleDragClip(sceneId, clip, newStartTime,metaKeyIsPressed,pixelsPerSec) {
		this._scenes.forEach((scene)=>{
			scene.handleDragClip(clip,newStartTime,this.isDnDMode,this.isDragPullMode,pixelsPerSec)
		})
	}


///RESIZE //////
	onResizeStart = (clip) => {   
		this.isDragging = true 
		this.dragClipId=clip.id 
		this.pmManager.startAction('resize')
		this._scenes.forEach((scene)=>{
				scene.onResizeStart()
			})
	};

	onResizeStop = () => {
		this.pmManager.endAction()
		this.isDragging = false 
		this.dragClipId=null
		this.debouncedSaveProjectTimeline()
	};

	onResize(clip, newDuration,direction,pixelsPerSec) {
		const scene = this._scenes.find(scene => scene.id === clip.sceneId);
		scene.handleResize(clip.id,newDuration,direction,pixelsPerSec)
	}

	undo() {
		if (this.pmManager.undo()) {
			this.recalculateTimeline();  // Additional logic to recalculate the timeline if necessary
		} 
	}

	redo() {
		if (this.pmManager.redo()) {
			this.recalculateTimeline();  // Additional logic to recalculate the timeline if necessary
		} 
	}

	moveClipBetweenScenesOnUndo=(clip,newSceneId)=>{
		let originalScene = clip.scene
		let newScene = this.findSceneForId(newSceneId)
		clip.scene = newScene
		///TODO figure out when this happens 
		if(newScene){
			newScene.moveClipIntoScene(clip)
		}if(originalScene){
			originalScene.moveClipOutOfScene(clip.id)
		}
	}

	updateSlideClip(existingClip, updatedSlideClip) {
		if (existingClip.scene.id !== updatedSlideClip.sceneId) {
			this.moveClipBetweenScenesOnUndo(existingClip, updatedSlideClip.sceneId);
		}
		existingClip.updateFromJSON(updatedSlideClip)
	}

	updateRegularClip(clip, attrs) {
		if (clip.scene.id !== attrs.sceneId) {
			this.moveClipBetweenScenesOnUndo(clip, attrs.sceneId);
		}
		for (const key in attrs) {
			if (JSON.stringify(clip[key]) !== JSON.stringify(attrs[key])) {
				if (key === 'metadata') {
					clip.metadata = { ...attrs.metadata };
				} else if (key === 'voiceoverPlaybackRate') {
					clip.changeVoiceoverPlaybackRate(attrs[key]);
				} else {
					clip[key] = attrs[key];
				}
			}
		}
	}
 
 recalculateTimeline() { //on undo/redo we force recalc the timeline
		const isInitialLoad = false
		const isPMUndoRedo=true
		const sceneIdSet = new Set(this._scenes.map(scene => scene.id));
		const clipIdSet = new Set(this.clips.map(clip => clip.id));
			
		let clipsToAdd = []	
		this.pmManager.editorState.doc.descendants(node => {
    if (node.type.name.includes('Clip')) {
      if (!clipIdSet.has(node.attrs.id)) {
        let newClip;
        if (node.type.name === 'slideClip') {
          newClip = convertSlideNodeToJSON(node);
        } else {
          newClip = node.attrs;
        }
        clipsToAdd.push(newClip);
      }
    }
  });

		this.pmManager.editorState.doc.descendants(node => {
			if(node.attrs.type=='scene'){
				if (!sceneIdSet.has(node.attrs.id)) {// Create a new clip based on the node's attributes
					let newScene = {...node.attrs, clips: []}
					// Filter clips that belong to this scene, add them, and remove from clipsToAdd
					clipsToAdd = clipsToAdd.filter(clip => {
						if (clip.sceneId === node.attrs.id) {
							newScene.clips.push(clip);
						return false; // Remove this clip from clipsToAdd
						}
						return true; // Keep this clip in clipsToAdd
					});
					this.addScene(newScene,isInitialLoad,isPMUndoRedo);
				}
			}
			else if(node.attrs.type=='settings'){
				const isUndo=true
				this.handleUpdateProjectBackgroundOnUndo(node.attrs.projectBackgroundId,isUndo)
				if(node.attrs.voiceoverPlaybackRate){
					this.voiceoverPlaybackRate=node.attrs.voiceoverPlaybackRate
				}
			}
		});

		this.clips.forEach(clip => {
			let existsInPM = false;
			this.pmManager.editorState.doc.descendants(node => {
				if (node.attrs.id === clip.id) {
				existsInPM = true;
				if(node.type.name =='slideClip'){
					this.updateSlideClip(clip,convertSlideNodeToJSON(node))
				}else{
					this.updateRegularClip(clip,node.attrs)
				}
			}
			})
			if(!existsInPM){
				this.deleteClip(clip,isPMUndoRedo)
			}
		})
	
		//reload all audio clips
		this.clips.forEach(clip => {
			if(clip.type=='audio'){
				clip.reloadAudio()
			}
		});		
		this._scenes.forEach(scene => {
			let existsInPM = false;
			this.pmManager.editorState.doc.descendants(node => {
				if (node.attrs.id === scene.id) {
					existsInPM = true;	
					for (const key in node.attrs) {
						if (JSON.stringify(scene[key])!=JSON.stringify(node.attrs[key])) {
							scene[key] = node.attrs[key];
						}
					}
				}
			});
			if (!existsInPM) {
				this.deleteScene(scene.id,isPMUndoRedo);
			}
		});

		clipsToAdd.forEach((clip)=>{
			this.addClip(clip,isInitialLoad,isPMUndoRedo);
		})

		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
		this.updateTranscriptFromTimeline()
		this.calculateAudioTrackSpacing()
	}

	updateNodeMetadata(clipId, newMetadata) {
		this.pmManager.updateNodeMetadata(clipId,newMetadata)
	}

////// Project Settings ///////// e.g. bgColor, voiceoverPlaybackRate

	updateProjectBackground(projectBackgroundId){
		this.pmManager.updateProjectSetting('projectBackgroundId',projectBackgroundId);
		this.projectBackgroundId = projectBackgroundId;
	}

///////////// Clips stuff
	async addVideoClipFromCaptureId(captureId,isDevice,isScreenRecording,motionStyle) {
		const newClip = await createVideoClipObjFromCaptureId(captureId,isDevice,isScreenRecording,motionStyle,this._currentTime)
		this.addClip(newClip)
	}

	resolveConflicts(newClip) {
		//console.log('resolve conflicts')
	}


	deleteClip(clip,isPMUndoRedo) {
		const scene = this.findSceneForId(clip.sceneId)
		if(scene){
			scene.deleteClip(clip.id,isPMUndoRedo)
			this.calculateUniqueVariables()
			this.debouncedSaveProjectTimeline()
	//		this.checkUniqueWebcamCaptureIdsChange()
		}else{
			console.log(`CANT FIND SCENE TO DELETE CLIP!`)
		}
	}

	deleteClipFromPmDoc(clipId) {
		this.pmManager.deleteNodeFromPmDoc(clipId)
	}

	updateTextSlideText(clipId,wordsArray,docJson,text) {
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.scene.updateTextSlideText(clipId,wordsArray,docJson,text)
		}	
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
	}

	updateProjectDefaultMotionStyle(motionStyle){
		this.clips.forEach(clip => {
			if (clip instanceof TextSlideClip){
				clip.updateProjectDefaultMotionStyle(motionStyle)
				clip.calculateMinSlideDuration()
				if(clip.duration < clip.minDuration){
					this.handleSlideClipDurationLessThanMinimum(clip)
				}
			}
			if (clip instanceof VideoClip){
				if(clip.metadata.isAutoMotionStyle){
					clip.metadata.motionStyle=motionStyle
				}
			}
			if (clip instanceof ChartClip){
				clip.updateProjectDefaultMotionStyle(motionStyle)
			}
		})
	}

/////Animation 
	updateClipMetadata(clipId, settings) {
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata = {...clip.metadata,...settings};
			if(clip.type=='textSlide'){
				clip.calculateMinSlideDuration()
				if(clip.duration < clip.minDuration){
					this.handleSlideClipDurationLessThanMinimum(clip)
				}
				this.calculateDuration();
			}
			//this.updateNodeAttrs(clip)
			this.pmManager.updateNodeAttrs(clip)
			this.debouncedSaveProjectTimeline()
		}
	}

	updateSlideClipTextStyle(clipId,newTextStyle){
		const clip = this.clips.find(clip => clip.id === clipId)
		if(clip){
			let newMetadata={...clip.metadata}
			newMetadata.textStyle=newTextStyle 
			let font = getFontForTextStyle(newTextStyle) 
    	newMetadata.fontFamily=font.fontFamily
    	newMetadata.fontWeight=font.fontWeight
    	newMetadata.fontSize=font.fontSize
    	newMetadata.lineHeight = font.lineHeight
    	newMetadata.letterSpacing = font.letterSpacing
    	this.updateClipMetadata(clipId,newMetadata)
		}
	}

	handleSlideClipDurationLessThanMinimum(clip){
		//if the slide clip duration is less than required do some stuff here
		clip.duration = clip.minDuration;
		this.resolveConflicts(clip)
	}

	updateClipAnimationSettings(clipId, settings,isPreview) {
		//const clip = this._clips.find(clip => clip.id === clipId);
		const clip = this.findClipForId(clipId)
		if (clip) {
			clip.metadata = {...clip.metadata,...settings};
			if(clip.type!='video'){
				clip.calculateMinSlideDuration()
				if(!isPreview){ //dont change the clip duration if you are just previewing
					if(clip.duration < clip.minDuration){
						this.handleSlideClipDurationLessThanMinimum(clip)
					}
				}
			}
			this.calculateDuration();
			this.debouncedSaveProjectTimeline()
			this.updateNodeMetadata(clipId,clip.metadata)
		}
	}


///Background music
	async updateBackgroundMusic(trackId){
		this._backgroundMusicTrack = trackId
		if(this.backgroundMusicElement){
			this.backgroundMusicElement.src = '';
			this.backgroundMusicElement.load();
			this.backgroundMusicElement = null
		}
		if(trackId){
			await this.initBackgroundMusic(trackId)
			this.backgroundMusicElement.currentTime = this._currentTime;
			if(this._isPlaying){
				this.backgroundMusicElement.play()
			}
		}
		this.debouncedSaveProjectTimeline()
	}

	updateBackgroundMusicVolume(newVolume){
		this.backgroundMusicVolume = newVolume
		this.backgroundMusicElement.newVolume = newVolume;
		this.debouncedSaveProjectTimeline()
	} 
	
////Video Clip actions
	addFreezeFrame(clipId,freezeTime){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.addFreezeFrame(clipId,freezeTime)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
	}

	addSkipSegment(clipId,skipTime){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.addSkipSegment(clipId,skipTime)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
	}

	toggleSkipSegment(clipId,segmentId,isExpanded){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.toggleSkipSegment(clipId,segmentId,isExpanded)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
	}

	removeFreeze(clipId,segmentId){
		const clip = this.clips.find(s => s.id === clipId);
		clip.scene.removeFreeze(clipId,segmentId)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()	
	}

	removeSkip(clipId,segmentId){
		const clip = this.clips.find(s => s.id === clipId);
		clip.scene.removeSkip(clipId,segmentId)
		this.debouncedSaveProjectTimeline()	
	}

	updateSkipSegmentDuration(clipId,segmentId,newDuration,direction){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.handleUpdateSkipSegmentDuration(clipId,segmentId,newDuration,direction)
		this.debouncedSaveProjectTimeline()
	}

	updateVideoSegmentTimeStretch(clipId,segmentId,playbackRate){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.handleChangeSegmentPlaybackRate(clipId,segmentId,playbackRate)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
	}

	changeVideoClipPlaybackRate(clipId,playbackRate){
		const clip = this.clips.find(s => s.id === clipId)
		clip.scene.handleChangeVideoClipPlaybackRate(clipId,playbackRate)
		this.recalculateSceneDurations()
		this.debouncedSaveProjectTimeline()
	}

	splitVideoClip(clip,splitTime){
		this.pmManager.startAction('splitVideoClip')
		const videoTime = calulateVideoTimeFromTimelineTime(splitTime,clip)
		//step 1 update the trim end for the first clip
		const newTrimEnd = videoTime
		let metadata ={...clip.metadata}
		metadata.trimStart=videoTime
		metadata.trimEnd=clip.metadata.trimEnd

		const newRecordingSegments = clip.recordingSegments
		const newTrimmedSegments = calculateTrimmedSegments(newRecordingSegments,metadata.trimStart,metadata.trimEnd,clip.clipPlaybackRate)

		let newClip = {
			id: randomID(),
			clipPlaybackRate:clip.clipPlaybackRate,
			captureId: clip.captureId,
			videoId:clip.videoId,
			isBasicVideo:clip.isBasicVideo,
			isScreenRecording:clip.isScreenRecording,
			isDeviceRecording:clip.isDeviceRecording,
			type: 'video',
			startTime: splitTime,
			duration: clip.duration-(splitTime-clip.startTime),
			zIndex: 0,
			metadata: metadata,
			recordingSegments:newRecordingSegments,
			segments:newTrimmedSegments
		};

		const zoomClips = this.clips.filter(c => c.type === 'zoom' && c.metadata.parentClip === clip.id);
		
		if(splitTime - clip.startTime > MIN_SPLIT_CLIP_DURATION ){ 
			this.updateClipTrimValues(clip.id,clip.metadata.trimStart,newTrimEnd,true)
		}else{
			this.deleteClip(clip)
		}

		if(newClip.duration > MIN_SPLIT_CLIP_DURATION){
			this.addClip(newClip)
			//if zoom is after the split put it on the new clip
			zoomClips.forEach((zoomClip)=>{
				if(zoomClip.startTime >= splitTime){
					zoomClip.metadata.parentClip=newClip.id 
					this.pmManager.updateNodeAttrs(zoomClip)
				}
			})
		}else{
			// console.log('dont add new clip cos its too small')
		}		
		this.pmManager.endAction()
	}

	splitWebcamClip(clip,splitTime){
		this.pmManager.startAction('splitWebcamClip')
		const videoTime = calulateVideoTimeFromTimelineTime(splitTime,clip)
		//step 1 update the trim end for the first clip
		const newTrimEnd = videoTime
		let metadata ={...clip.metadata}
		metadata.trimStart=videoTime
		metadata.trimEnd=clip.metadata.trimEnd

		const newRecordingSegments = clip.recordingSegments

		const newTrimmedSegments = calculateTrimmedSegments(newRecordingSegments,metadata.trimStart,metadata.trimEnd,clip.clipPlaybackRate)

		let newClip = {
			id: randomID(),
			type:'webcam',
			captureId:clip.captureId,
			clipPlaybackRate:clip.clipPlaybackRate,
			startTime: splitTime,
			duration: clip.duration-(splitTime-clip.startTime),
			zIndex: -1,
			metadata: metadata,
			recordingSegments:newRecordingSegments,
			segments:newTrimmedSegments
		};
		
		if(splitTime - clip.startTime > MIN_SPLIT_CLIP_DURATION ){ 
			this.updateClipTrimValues(clip.id,clip.metadata.trimStart,newTrimEnd,true)
		}else{
			this.deleteClip(clip)
		}

		if(newClip.duration > MIN_SPLIT_CLIP_DURATION){
			this.addClip(newClip)
		}else{
			// console.log('dont add new clip cos its too small')
		}		
		this.pmManager.endAction()
	}



	updateClipTrimValues(clipId,trimStartTime,trimEndTime){
		const clip = this.clips.find(s => s.id === clipId);
		clip.updateTrimValues(trimStartTime,trimEndTime);
		const message = 'update clip trim values'
		this.pmManager.updateNodeAttrs(clip,message)
		this.debouncedSaveProjectTimeline()		
		if(clip.type=='webcam'){
			this.updateTranscriptFromTimeline()
		}
	}

//////
	setTextSlideTextColor(clipId,textColorId){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata.textColorId=textColorId
			this.debouncedSaveProjectTimeline()
			this.updateNodeMetadata(clipId,clip.metadata)
		}
	}

	setClipBackgroundId(clipId,backgroundId){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata.backgroundId=backgroundId
			this.debouncedSaveProjectTimeline()
			this.updateNodeMetadata(clipId,clip.metadata)
		}
	}

	////CHART
	updateChartClip(clipId,metadata){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.metadata=metadata
			this.updateNodeMetadata(clipId,clip.metadata)
			this.debouncedSaveProjectTimeline()
		}
	}

	updateImageWithUploadResponse(clipId,elementId,response){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
		clip.updateImageWithUploadResponse(elementId,response)
		//Update the metadata but make it not undoable
		this.pmManager.updateNodeMetadataSilent(elementId, {
			imgSrc: response.delivery_url,
			semiTransparent: response.semi_transparent
		});
		this.debouncedSaveProjectTimeline()
		}
	}

	updateWebcamWithUploadResponse=async(clipId,captureId,response)=>{
		// //with the new webcam flow we need to swap from source webcam captureId to new processed captureId
		const webcamData = response.webcam
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.isUploadingVideo = false
			clip.metadata.isVariable = false
			clip.metadata.hasInstructions = false 
			clip.metadata.instructions = ''
			clip.captureId=webcamData.capture_id
			clip.duration = webcamData.duration
			clip.metadata.applyFaceBox = true
			clip.recordingDuration = webcamData.duration
			clip.metadata.originalWidth = webcamData.original_width 
			clip.metadata.originalHeight = webcamData.original_height
			clip.metadata.displayWidth=webcamData.default_display_width	
			clip.metadata.audioTransformation={type:"normalized"}//default use the audio normalised on
			//get the transcript
			const transcript = await getTranscriptForWebcamCaptureId(captureId)
			clip.metadata.transcript = transcript 
			//get the face box
			const faceBox = await getFaceBoxForWebcamCaptureId(captureId)
			console.log('face box is')
			console.log(faceBox)
			clip.metadata.faceBox = faceBox
			clip.finishUpload()

			//we need to delete all audio clips that have this clip as the parent webcam (for script tts)
			this.clips.forEach((clip)=>{
				if(clip.parentWebcamClip==clipId){
					this.deleteClip(clip)
				}
			})

			this.debouncedSaveProjectTimeline()
			this.updateTranscriptFromTimeline()
		}
	}

	updateVideoWithUploadResponse(clipId,videoId,response){
		const clip = this.clips.find(clip => clip.id === clipId);
		if (clip) {
			clip.videoId=videoId
			clip.metadata.originalWidth = response.original_width 
			clip.metadata.originalHeight = response.original_height
			clip.metadata.displayWidth=response.default_display_width
			clip.metadata.semiTransparent = response.semi_transparent
			clip.metadata.originalFileName = response.original_filename
			if(clip.type=='webcam'){
				console.log('here set duration in webcam')
				clip.duration = response.duration
			}
			
			clip.finishUpload()
			this.debouncedSaveProjectTimeline()
		}
	}


///// save
	getClipsAsJson() {
		return this.clips.map(clip => clip.toJSON());
	}

	getScenesAsJson(){
		return this._scenes.map(scene => scene.toJSON());
	}


	calculateUniqueVariables() {
		const allVariables = new Set();
		if(this._scenes){
			this._scenes.forEach(scene => {
				scene.clips.forEach(clip => {
					if (clip.type == 'slide' && clip.metadata && Array.isArray(clip.metadata.variables)) {
						clip.metadata.variables.forEach(variable => {
						allVariables.add(variable);
					});
					}
					if (clip.type === 'audio') {
						const text = clip.metadata.text;
						const variableRegex = /{{(.*?)}}/g;
						let match;
						while ((match = variableRegex.exec(text)) !== null) {
							allVariables.add(match[1]);
						}
					}
				});
			});
			this.variables = Array.from(allVariables);
		}
	}

/////// Audio clips

	addClipFromTranscriptSync(clip,sceneId){
		const scene = this.findSceneForId(sceneId)
		if(scene){
			scene.addClipFromTranscriptSync(clip,this.voiceoverPlaybackRate)
		}
	}

	mergeSceneFromTranscriptSync=(sceneId)=>{ //this merges the scene sceneId with teh scene before it
		//console.log('merge scene')
		const sceneToMerge = this.findSceneForId(sceneId)
		const sceneIndex = sceneToMerge.sceneIndex 
		if(sceneIndex!=0){
			const scene = this.scenes[sceneIndex-1]
			let updatesArray=[]
			const clipIdSet = new Set(sceneToMerge.clips.map(clip => clip.id));
			clipIdSet.forEach((clipId)=>{
				const clip =this.findClipForId(clipId)
				clip.scene = scene 
				clip.relativeStartTime+=scene.duration
				scene.moveClipIntoScene(clip)
				sceneToMerge.moveClipOutOfScene(clip.id)
				updatesArray.push({clipId:clip.id,relativeStartTime:clip.relativeStartTime,sceneId:clip.scene.id})
			})
			this.pmManager.updateMultipleClipFields(updatesArray)
			this.deleteScene(sceneToMerge.id)
		}else{
			this.updateTranscriptFromTimeline()
		}
	}

	///here
	//TODO check moving between scenes- not sur ethis is working properly
	updateTimelineFromTranscript(updatesArray){
		this.pmManager.startAction('transcriptSync')

		updatesArray.forEach((update)=>{
			
			if(update.type=='createAudioClip' && update.text!=='#'){
				const text = update.text 
				const estimatedDuration = estimateAudioDuration(text)/1000
				const newClip = {
					id: update.clipId,
					type:"audio",
					startTime:0,
					originalDuration:estimatedDuration,
					duration:estimatedDuration,
					name:"voiceClip",
					requiresUpdate:true,
					parentWebcamClip:update.parentWebcamClip,
					metadata:{
						text:text
					},
					zIndex:-1,
					sceneId:12,
					clipIndex:update.clipIndex
				}
				const sceneId = update.sceneId 
				this.addClipFromTranscriptSync(newClip,sceneId)		
			}
			else if(update.type=='deleteClip'){
				this.deleteClip(update.clip)		
			}
			
			else if(update.type=='updateAudioClip'){
				const clip = this.clips.find(s => s.id === update.clipId);
				if(clip){
					clip.clipIndex = update.clipIndex
					clip.indexInParentClip = update.indexInParentClip
					clip.parentWebcamClip = update.parentWebcamClip
					const oldText = clip.metadata.text
					const newText = update.text
					if(oldText!==newText){
						clip.metadata.text = newText;
						const estimatedDuration=estimateAudioDuration(newText)/1000
			 			clip.originalDuration = estimatedDuration
			 			clip.voiceoverPlaybackRate = this.voiceoverPlaybackRate
						clip.duration = estimatedDuration / this.voiceoverPlaybackRate				
						clip.handleTextUpdated(newText,estimatedDuration)
					}
					this.pmManager.updateNodeAttrs(clip)	
				}
			}


			else if(update.type=='updateSceneTitle'){
				const scene = this._scenes.find(s => s.id === update.sceneId);
				const newTitle = update.title
				scene.title = newTitle
				this.pmManager.updateNodeAttrs(scene)
			}

			else if(update.type=='mergeScene'){
				this.mergeSceneFromTranscriptSync(update.sceneId)
			}

			else if(update.type=='createVariableWebcamClip'){
				const newClip = {
					id: update.clipId,
					type:"webcam",
					clipIndex:update.clipIndex,
					startTime:0,
					originalDuration:3,
					duration:3,
					zIndex:-1,
					sceneId:update.sceneId,
					metadata:{...webcamClipDefaultMetadata,hasInstructions:update.hasInstructions,isVariable:true}
				}
				this.addClipFromTranscriptSync(newClip,update.sceneId)	
			}
			else if(update.type=='updateVariableWebcamClip'){
				const clip = this.clips.find(s => s.id === update.clipId);
				if(clip){
					clip.clipIndex = update.clipIndex
					clip.metadata.instructions=update.instructions
					clip.metadata.hasInstructions=update.hasInstructions
					this.pmManager.updateNodeAttrs(clip)	
				}
			}
			else if(update.type=='updateWebcamRecording'){
				const clip = this.clips.find(s => s.id === update.clipId);
				if(clip){
					clip.clipIndex = update.clipIndex
					clip.updateChunksAndSkippedWords(update.chunks,update.skippedWords)
					this.pmManager.updateNodeAttrs(clip)
				}
			}
		})
		this.pmManager.endAction()
		this.calculateAudioTrackSpacing()
		this.debouncedSaveProjectTimeline()
	}





	handleTranscriptDnd(dropClipId, dropPosition) {
		const clip = this.findClipForId(dropClipId);
		if (!clip) {
			return;
		}

    const originalScene = clip.scene;
    const targetScene = this.findSceneForId(dropPosition.sceneId);
		if (!targetScene) {
			return;
		}
    this.pmManager.startAction('moveAudioClip');
    const originalIndex = clip.clipIndex;
		let newClipIndex = dropPosition.clipIndex; //lets add fractional indexes then reorder and assign new indexes
		if (dropPosition.dropType === 'after') {
			newClipIndex += 0.5;
		} else {
			newClipIndex -= 0.5;
		}
   
    if (originalScene.id === targetScene.id) {
			// Moving within the same scene
			const audioClips = originalScene.clips.filter(c => c.type === 'audio');
			clip.clipIndex = newClipIndex
			 // Sort clips by their (potentially fractional) indexes
			audioClips.sort((a, b) => a.clipIndex - b.clipIndex);
			// Reassign integer indexes
			audioClips.forEach((c, index) => {
				c.clipIndex = index;
				this.pmManager.updateNodeAttrs(c);
			});

    } else {
    	 originalScene.moveClipOutOfScene(clip.id);
        // Moving to a different scene
        originalScene.clips.filter(c => c.type === 'audio' && c.clipIndex > originalIndex).forEach(c => {
            c.clipIndex -= 1;
            this.pmManager.updateNodeAttrs(c);
        });
        
        clip.clipIndex = newClipIndex
        targetScene.moveClipIntoScene(clip);

        const audioClips = targetScene.clips.filter(c => c.type === 'audio'); 
        audioClips.sort((a, b) => a.clipIndex - b.clipIndex);

				// Reassign integer indexes
				audioClips.forEach((c, index) => {
					c.clipIndex = index;
					this.pmManager.updateNodeAttrs(c);
				});
    }

    // Move the clip
    clip.scene = targetScene;
    clip.clipIndex = newClipIndex;
    //targetScene.moveClipIntoScene(clip);
    this.pmManager.updateNodeAttrs(clip);
    this.pmManager.endAction();
    this.calculateAudioTrackSpacing();
    this.recalculateSceneDurations();
    this.updateTranscriptFromTimeline();
    this.debouncedSaveProjectTimeline();
}

	//new set the clip index on clips 
	//this was happening through transcript panel updates but now we can add webcam on timeline we need to recalc indexes
	//If you have 2 webcam clips after each other and the have the same clip.captureId then the minimum spacing between them is 0
	calculateAudioTrackSpacing() {
		let updatesArray = []
		const makeAudioTrackUpdateObj=(clip)=>{
			return{
				clipId: clip.id,
				relativeStartTime: clip.relativeStartTime,
				pinnedStartTime: clip.pinnedStartTime,
				duration: clip.duration,
				clipIndex:clip.clipIndex,
				minDuration:clip.minDuration
			}
		}

		this._scenes.forEach((scene) => {			

			const sceneAudioTrackClips = this.getSceneAudioTrackClips(scene.id)
			let previousClipEndTime = scene.startTime-AUDIO_CLIP_SPACING;
			let previousClip = null;

			sceneAudioTrackClips.forEach((clip, i) => {
				// const requiresSpacing = !previousClip || 
				// 	previousClip.type !== 'webcam' || 
				// 	clip.type !== 'webcam' || 
				// 	previousClip.captureId !== clip.captureId;

				const requiresSpacing = !previousClip || 
					previousClip.type !== 'webcam' || 
					clip.type !== 'webcam' || 
					previousClip.captureId !== clip.captureId ||
					previousClip.metadata.isVariable ||
					clip.metadata.isVariable;

				//const requiresSpacing = true

				const spacing = requiresSpacing ? AUDIO_CLIP_SPACING : 0;

				if (clip.pinnedStartTime) {
					if (clip.pinnedStartTime < previousClipEndTime + 0.01){
						clip.pinnedStartTime = null
						clip.startTime = previousClipEndTime + spacing;
					}
				}
				else{ //not pinned
					clip.startTime = previousClipEndTime + spacing;
				}
				//We need to reset the index because when we insert webcam on timeline we give it a non integer index in between the clips we insert it between
				clip.clipIndex = i 

				if(clip.type=='webcam' && clip.metadata.isVariable){
					//we need to work out the new duration of the webcam from its child clips
					let placeholderClipDuration = 0
					let minDuration = 1
					const placeholderAudioClips = this.clips.filter(c => c.parentWebcamClip == clip.id);
					placeholderAudioClips.sort((a, b) => a.indexInParentClip - b.indexInParentClip);
					if(placeholderAudioClips.length==0){
						placeholderClipDuration = 5
					}
					else{
						let previousPlaceholderAudioEndTime = clip.startTime - spacing
						let placeholderClipEndTime
						//make sure to reset clipIndex and pinnedStartTime for the placeholder audio clips incase we joined normal chunks into the placeholder
						placeholderAudioClips.forEach(pa=>{
							pa.startTime = previousPlaceholderAudioEndTime + spacing
							pa.pinnedStartTime = null 
							pa.clipIndex = null //might not need this 
							updatesArray.push(makeAudioTrackUpdateObj(pa))
							previousPlaceholderAudioEndTime =pa.endTime 
							placeholderClipEndTime=pa.endTime + spacing
						})
						placeholderClipDuration = placeholderClipEndTime - clip.startTime
						minDuration = placeholderClipDuration
					}
		
					clip.minDuration = minDuration
					if(clip.placeholderDuration){
						if(clip.placeholderDuration<placeholderClipDuration){
							clip.placeholderDuration = null
							clip.duration = placeholderClipDuration
						}else{	

							//calced duration is less that the one set so leave it
						}
					}else{
						clip.duration = placeholderClipDuration
					}
				}
				
				previousClipEndTime = clip.endTime
				previousClip = clip
				updatesArray.push(makeAudioTrackUpdateObj(clip))
			})
		})
		const preventUndo = true;
		this.pmManager.updateMultipleClipFields(updatesArray, preventUndo);
		this.recalculateSceneDurations();
	}


///// change active voice
	updateActiveVoice(activeVoice){
		this._activeVoice= activeVoice
		this._scenes.forEach((scene)=>{
			scene.changeActiveVoice(activeVoice)
		})
	}



	updateSlideElementAnimationIndex(clipId,elementId,newIndex){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.updateSlideElementAnimationIndex(elementId,newIndex)
		}
		this.debouncedSaveProjectTimeline()
	}



	updateVoiceoverPlaybackRate(rate){
		this.pmManager.startAction('voiceoverPlaybackRate')
		this.pmManager.updateProjectSetting('voiceoverPlaybackRate',rate);
		this.voiceoverPlaybackRate= rate

		let updatesArray=[]
		this.clips.forEach(clip => {
			if(clip instanceof AudioClip) {
				clip.changeVoiceoverPlaybackRate(rate)
				updatesArray.push({clipId:clip.id,duration:clip.duration,voiceoverPlaybackRate:rate})
			}
		})
		this.pmManager.updateMultipleClipFields(updatesArray)
		this.pmManager.endAction()
		this.calculateAudioTrackSpacing()
		this.debouncedSaveProjectTimeline()
	}


	isLastScene=(sceneId)=>{
    if (!this._scenes.length) return false; // Return false if there are no scenes
    this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
    return this._scenes[this._scenes.length - 1].id === sceneId;
	}

	recalculateSceneDurations = () =>{
		this._scenes.forEach((scene)=>{
			scene.calculateSceneDuration()
		})
		this.calculateDuration()
	}


	onSceneDurationChange = () => {
		this.calculateDuration()
	}

///////// SLIDES ///////////////////
	saveSlideChanges=(clip)=>{
		this.calculateUniqueVariables()
		this.pmManager.syncSlideClip(clip)
		this.debouncedSaveProjectTimeline()
	}

	handleSlideDragOrResizeStart(){
		this.pmManager.onSlideDragResizeStart()
	}

	handleSlideDragOrResizeEnd(){
		this.pmManager.onSlideDragResizeEnd()
	}

	async addSlideElement(clipId, type, isVariable,newElementId) {
    const clip = this.findClipForId(clipId);
    if (clip) {
      await clip.addSlideElement(type, isVariable,newElementId);
      this.saveSlideChanges(clip);
    }
  }

	duplicateSlideItems(clipId,duplicateItemIds){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.duplicateSlideItems(duplicateItemIds)
			this.saveSlideChanges(clip)
		}
	}

	deleteSlideItems(clipId,items){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.deleteItems(items)
			this.saveSlideChanges(clip)
		}
	}

	groupSlideItems(clipId,slideItems,groupingType,newLayoutGroupId){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.groupSlideItems(slideItems,groupingType,newLayoutGroupId)
			this.saveSlideChanges(clip)
		}
	}

	ungroupSlideLayoutGroup(clipId,layoutGroupId){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.ungroupLayoutGroup(layoutGroupId)
			this.saveSlideChanges(clip)	
		}
	}

	updateSlideLayoutGroupType(clipId,layoutGroupId,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateLayoutGroupType(layoutGroupId,value)
			this.saveSlideChanges(clip)
		}
	}

	updateSlideLayoutGroupField(clipId,layoutGroupId,field,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateLayoutGroupField(layoutGroupId,field,value)
			this.saveSlideChanges(clip)
		}
	}

	alignSlideItems(clipId,slideItems,alignType,alignValue){
		const clip = this.findClipForId(clipId);
		if(clip){
			clip.alignSlideItems(slideItems,alignType,alignValue)
			this.saveSlideChanges(clip)
		}
	}

	updateSlideTextElementText(lettersArray,text,docJson,clipId,elementId){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementText(elementId,lettersArray,text,docJson)

			this.saveSlideChanges(clip)
		}
	}

	updateSlideAlignment(clipId,alignment,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateSlideAlignment(alignment,value)
			this.saveSlideChanges(clip)	
		}
	}
	
	updateSlideElementMetadata(clipId,elementId,newMetadata){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementMetadata(elementId,newMetadata)
			this.saveSlideChanges(clip)		
		}
	}

	updateSlideElementField(clipId,elementId,field,value){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementField(elementId,field,value)
			this.saveSlideChanges(clip)		
		}
	}

	updateSlideTextElementTextProperties(clipId,elementId,textStyle,newTextProperties){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateTextElementTextProperties(elementId,textStyle,newTextProperties)
			this.saveSlideChanges(clip)		
		}
	}


	alignSlideElements(clipId,selectedSlideElements,alignType,alignValue){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.alignSlideElements(selectedSlideElements,alignType,alignValue)
			this.saveSlideChanges(clip)		
		}
	}

	updateImageElementImage(clipId,imgObj,replaceElementId){
		const clip = this.findClipForId(clipId)
		clip.updateImageElementImage(imgObj,replaceElementId)
		this.saveSlideChanges(clip)	
	}

	addImageElementToSlide(clipId,imgObj,elementId,dropPosition){
		const clip = this.findClipForId(clipId)
		clip.addImageElementFromRecent(imgObj,elementId,dropPosition)
		this.saveSlideChanges(clip)
	}

	updateSlideElementZOrder(clipId,elementId,updateType){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.updateElementZOrder(elementId,updateType)
			this.saveSlideChanges(clip)
		}
	}

	useSlideTemplate(clipId, template){
		const clip = this.findClipForId(clipId)
		if(clip){
			clip.useSlideTemplate(template)
			this.saveSlideChanges(clip)		
		}
	}

	////Webcam
	// setIsWebcamMode(isWebcamMode){
	// 	console.log('yo yo yo here ')
	// 	this.isWebcamMode = isWebcamMode
	// 	this.transcriptPmManager.setIsWebcamMode(isWebcamMode)
	// 	this.calculateAudioTrackSpacing()
	// 	this.debouncedSaveProjectTimeline()
	// }


	calculateDuration() {
		let cumulativeDuration = 0;
		this._scenes.sort((a, b) => a.sceneIndex - b.sceneIndex);
		this._scenes.forEach((scene, index) => {
			if (index === 0) {
				scene.startTime = 0;
			} else {
				scene.startTime = cumulativeDuration;
			}
			cumulativeDuration += scene.duration;
		})
		this._duration = cumulativeDuration;
		if(INFINITE_TIMELINE){
    	const adjustedClipEnd = cumulativeDuration + 30; // Add 30 seconds to ensure a minimum of 30 seconds interval
    	const nextHalfMinute = Math.ceil(adjustedClipEnd / 30) * 30;
	    this.maxTimelineDurationSeconds = Math.max(120, nextHalfMinute)
		}
	}

	handleSaveProjectTimeline(){
		if(this.calculateUniqueVariables){
			this.calculateUniqueVariables()
		}
		if(this._scenes){
			const voiceObj = getVoiceForId(this._activeVoice)
			let providerId=this._activeVoice
			if(voiceObj){
				providerId = voiceObj.providerId
			}

			const scenesJson = this.getScenesAsJson();
			let timelineData={}
			timelineData.activeVoice = this._activeVoice

			timelineData.providerId = providerId


			timelineData.variables =this.variables
			timelineData.voiceoverPlaybackRate=this.voiceoverPlaybackRate
			timelineData.scenes = scenesJson
			timelineData.backgroundMusicTrack = this._backgroundMusicTrack
			timelineData.backgroundMusicFadeEffect=this._backgroundMusicFadeEffect || false
			timelineData.backgroundMusicVolume=this.backgroundMusicVolume || BACKGROUND_TRACK_VOLUME
			timelineData.showCaptions=this.showCaptions
			saveProjectTimeline(this._projectId,timelineData,this.duration)
		}
	}

	destroy() {
		if (this._isPlaying) {
			this.pause();
		}
		if (this._playbackInterval) {
			clearInterval(this._playbackInterval);
			this._playbackInterval = null;
		}
		if (this._syncInterval) {
			clearInterval(this._syncInterval);
			this._syncInterval = null;
		}
		if(this.backgroundMusicElement){
			this.backgroundMusicElement.src = '';
    	this.backgroundMusicElement.load();
		}
		this._scenes.forEach(scene => {
			if (scene.destroy && typeof scene.destroy === 'function') {
				scene.destroy();
			}
		});
		this._scenes = null;
		if(this.pmManager){
			this.pmManager.destroy()
		}
		if(this.transcriptPmManager){
			this.transcriptPmManager.destroy()
		}
	}

	get duration() {
		return this._duration;
	}

	get currentTime() {
		return this._currentTime;
	}

	get isPlaying() {
		return this._isPlaying;
	}

	get clips() {
		const clips= this._scenes.sort((a, b) => a.startTime - b.startTime)
			.flatMap((scene, sceneIndex) => 
				scene.clips.map(clip => (
					clip
				))
			);
		return clips
	}

	get activeVoice() {
		return this._activeVoice ;
	}

	get backgroundMusicTrack() {
		return this._backgroundMusicTrack ;
	}

	get scenes() {
		return this._scenes
			.sort((a, b) => a.startTime - b.startTime)
	}

}

export { Timeline }



