import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Plane } from '@react-three/drei';
import * as THREE from 'three';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import WebcamVideoVideoMaterial from './WebcamVideoVideoMaterial'
import ExportWebcamVideoMaterial from './ExportWebcamVideoMaterial'
import { createDropShadow } from './WebcamVideoDropShadow'; // Import the drop shadow function
import defaultVideoAnimationValues from '../utils/animations/defaultVideoAnimationValues'
import renderOrders from '../renderOrders'

const SCENE_WIDTH = 1920
const SCENE_HEIGHT = 1080

const WebcamVideoVideo = ({clip, time, localTime, visible, initialProperties, animationStates, animationParams, startTransitionType, endTransitionType,showWebcamStatic, FPS, isPlaying, videoDisplayWidth, videoDisplayHeight, videoDisplayRadius}) => {
 


  const displayWidth = videoDisplayWidth
  const displayHeight = videoDisplayHeight  




  const originalVideoWidth = clip.metadata.originalWidth
  const originalVideoHeight = clip.metadata.originalHeight

  const deviceRef = useRef();  
  const frameRef = useRef();  
  const shadowRef = useRef();  
  const frameShadowRef = useRef();  

  const [opacity, setOpacity] = useState(1);
  
  let displayShadow = true

    
      

  // auto stuff
  let webcamDisplayRadius = 20;
  let frameBorderWidth = 10
  if((displayWidth > 0.49 * SCENE_WIDTH) || (displayHeight > 0.95 * SCENE_HEIGHT)){    
    webcamDisplayRadius = 0
    displayShadow = false
  }  

  if((displayWidth > 680) || (displayHeight > 700)){
    frameBorderWidth = 0    
  }  
    if(displayWidth == 1200){ // overhalf float
    frameBorderWidth = 0
    webcamDisplayRadius = 16;
  } 

  if((displayWidth < 360) || (displayHeight < 360)){
    frameBorderWidth = 6    
    webcamDisplayRadius = 16;
  }  

  const webcamFrameDisplayRadius = webcamDisplayRadius + frameBorderWidth

  const meshWidth = displayWidth - (frameBorderWidth * 2)
  const meshHeight = displayHeight - (frameBorderWidth * 2)
  

  //
  // DEVICE GEOMETRY  
  const deviceGeometry = useMemo(() => {
    const shape = createRoundedRectShape(meshWidth, meshHeight, webcamDisplayRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
      uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [meshWidth, meshHeight, webcamDisplayRadius]);

  //
  // FRAME GEOMETRY  
  const frameGeometry = useMemo(() => {
    const shape = createRoundedRectShape(displayWidth, displayHeight, webcamFrameDisplayRadius);
    const geometry = new THREE.ShapeGeometry(shape);        
    // Compute UV mapping for the geometry using the provided logic that works
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      // Map x, y vertex position to uv coordinates
      uvs[i * 2] = (vertex.x + displayWidth / 2) / displayWidth;
      uvs[i * 2 + 1] = (vertex.y + displayHeight / 2) / displayHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return geometry;
  }, [displayWidth, displayHeight, webcamFrameDisplayRadius]);


  //
  // SHADOW SETUP
  
  let shadowXOffset = 0;
  let shadowYOffset = -8;
  let shadowBlur = 0;
  let shadowSpread = -14;  
  let shadowColor = 'rgba(0,0,0,1)';  
  //const shadowOpacity = 0.44;    
  let shadowOpacity = 0.84; // this is wrong

  if(frameBorderWidth > 0){
    shadowYOffset = frameBorderWidth - 3
    shadowSpread = -1;
    shadowBlur = 3
    shadowOpacity = 0.84
  }

   const shadowMesh = useMemo(() => {
    const shadowOptions = {
      xOffset: shadowXOffset,
      yOffset: shadowYOffset,
      blur: shadowBlur,
      spread: shadowSpread,
      color: shadowColor,
      opacity: shadowOpacity,
    };
    return createDropShadow(meshWidth, meshHeight, shadowOptions, webcamDisplayRadius);
  }, [meshWidth, meshHeight, shadowXOffset, shadowYOffset, shadowBlur, shadowSpread, shadowOpacity]);

   // 
   // FRAME SHADOW

  const frameShadowXOffset = 0;
  const frameShadowYOffset = -8;
  const frameShadowBlur = 18;
  const frameShadowSpread = -12;  
  const frameShadowColor = 'rgba(0,0,0,1)';  
  //const shadowOpacity = 0.44;  
  const frameShadowOpacity = 0.24; // this is wrong

   const frameShadowMesh = useMemo(() => {
    const frameShadowOptions = {
      xOffset: frameShadowXOffset,
      yOffset: frameShadowYOffset,
      blur: frameShadowBlur,
      spread: frameShadowSpread,
      color: frameShadowColor,
      opacity: frameShadowOpacity,
    };
    return createDropShadow(displayWidth, displayHeight, frameShadowOptions, webcamFrameDisplayRadius);
  }, [displayWidth, displayHeight, frameShadowXOffset, frameShadowYOffset, frameShadowBlur, frameShadowSpread, frameShadowOpacity]);


  // console.log('animationStates')
  // console.log(animationStates)
  // console.log('----')
  
  //
  // APPLY ANIMATIONS
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);
      if (deviceRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showWebcamStatic){
            state=defaultVideoAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              deviceRef.current.position[axis] = state;
            } else if (property === 'scale' && deviceRef.current.scale) {
              deviceRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              deviceRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && deviceRef.current.material) {
              //deviceRef.current.material.opacity = state;            
              setOpacity(state);
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates,showWebcamStatic]);


    useEffect(() => {    
      const frameIndex = Math.floor(localTime * FPS);
      if (frameRef.current) {      
        Object.keys(animationStates).forEach(property => {
          let state = animationStates[property][frameIndex]; 
          if(showWebcamStatic){
            state=defaultVideoAnimationValues[property]
          }       
          if (state !== undefined) {
            if (property.startsWith('position')) {
              const axis = property.slice('position'.length).toLowerCase();
              frameRef.current.position[axis] = state;
            } else if (property === 'scale' && frameRef.current.scale) {
              frameRef.current.scale.set(state, state, state);
            } else if (property.startsWith('rotation')) {
              const axis = property.slice('rotation'.length).toLowerCase();
              frameRef.current.rotation[axis] = state;
            } else if (property === 'opacity' && frameRef.current.material) {
              //deviceRef.current.material.opacity = state;            
              setOpacity(state);
            }
          }
        });
      }    
    }, [localTime,startTransitionType,endTransitionType,animationStates,showWebcamStatic]);


    // Animate Shadow
    useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);

      if (shadowRef.current) {
        // Initialize default values
        let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0;

        // Check if the animation states for specific properties exist and assign values
        if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
          animatedOpacity = animationStates['opacity'][frameIndex];
        }
        if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
          animatedScale = animationStates['scale'][frameIndex];
        }
        if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
          animatedPosX = animationStates['positionX'][frameIndex];
        }
        if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
          animatedPosY = animationStates['positionY'][frameIndex];
        }

        // Apply animated values to the shadow mesh
        const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / meshWidth);
        shadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

        // Apply animated position combined with static offsets to the shadow mesh
        shadowRef.current.position.x = animatedPosX + shadowXOffset;
        shadowRef.current.position.y = animatedPosY + shadowYOffset;
        
        // Set the shadow's opacity based on animated opacity and Leva's opacity control
        shadowRef.current.material.opacity = shadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);        

        if(showWebcamStatic){
          shadowRef.current.position.x = shadowXOffset
          shadowRef.current.position.y = shadowYOffset
          shadowRef.current.position.z = 0
          shadowRef.current.material.opacity = shadowOpacity          
        }
      }
    }, [localTime, FPS, shadowXOffset, shadowYOffset, shadowSpread, shadowOpacity, meshWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams), showWebcamStatic]);

    //
    // FRAME SHADOW ANIMATIONS
  
     useEffect(() => {
      
      const frameIndex = Math.floor(localTime * FPS);

      if (frameShadowRef.current) {
        // Initialize default values
        let animatedOpacity = 0, animatedScale = 1, animatedPosX = 0, animatedPosY = 0;

        // Check if the animation states for specific properties exist and assign values
        if (animationStates['opacity'] && animationStates['opacity'][frameIndex] !== undefined) {
          animatedOpacity = animationStates['opacity'][frameIndex];
        }
        if (animationStates['scale'] && animationStates['scale'][frameIndex] !== undefined) {
          animatedScale = animationStates['scale'][frameIndex];
        }
        if (animationStates['positionX'] && animationStates['positionX'][frameIndex] !== undefined) {
          animatedPosX = animationStates['positionX'][frameIndex];
        }
        if (animationStates['positionY'] && animationStates['positionY'][frameIndex] !== undefined) {
          animatedPosY = animationStates['positionY'][frameIndex];
        }

        // Apply animated values to the shadow mesh
        const scaleWithShadowSpread = animatedScale * (1 + shadowSpread * 2 / meshWidth);
        shadowRef.current.scale.set(scaleWithShadowSpread, scaleWithShadowSpread, scaleWithShadowSpread);

        // Apply animated position combined with static offsets to the shadow mesh
        shadowRef.current.position.x = animatedPosX + frameShadowXOffset;
        shadowRef.current.position.y = animatedPosY + frameShadowYOffset;
        
        // Set the shadow's opacity based on animated opacity and Leva's opacity control
        shadowRef.current.material.opacity = frameShadowOpacity * (animatedOpacity * animatedOpacity * animatedOpacity);        

        if(showWebcamStatic){
          shadowRef.current.position.x = frameShadowXOffset
          shadowRef.current.position.y = frameShadowYOffset
          shadowRef.current.position.z = 0
          shadowRef.current.material.opacity = frameShadowOpacity          
        }
      }
    }, [localTime, FPS, frameShadowXOffset, frameShadowYOffset, frameShadowSpread, frameShadowOpacity, displayWidth, startTransitionType,endTransitionType,JSON.stringify(animationParams), showWebcamStatic]);

  


    // console.log('WEBCAMCLIP')
    // console.log(clip)

    const isVariable = clip.metadata.isVariable
    let placeholderBG = "rgb(170,170,170)";


  let isFullScreen = false
  if(displayWidth === SCENE_WIDTH){
    isFullScreen = true
  }

  let webcamRenderOrder = renderOrders.webcamVideo
  if(isFullScreen){
    webcamRenderOrder = renderOrders.webcamVideoFullScreen
  }


  return (
    <>      

     
        
          {displayShadow && 
            <primitive 
              object={shadowMesh}
              ref={shadowRef} 
              visible={visible}     
              renderOrder={renderOrders.webcamVideoShadow}
            /> 
          }
          
          {frameBorderWidth > 0 && 
            <primitive 
              object={frameShadowMesh}
              ref={frameShadowRef} 
              visible={visible}     
              renderOrder={renderOrders.webcamVideoFrameShadow}
            /> 
          }
              
          <mesh        
            ref={deviceRef}
            position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
            scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
            geometry={deviceGeometry}
            visible={visible}            
            renderOrder={webcamRenderOrder}
          >

          {isVariable &&    
            <meshBasicMaterial 
              color={placeholderBG}
              transparent
              opacity={opacity}
            />
          }

          

         {clip.isExport && !isVariable &&
           <ExportWebcamVideoMaterial 
              codecVideoClip={clip}  
              meshWidth={meshWidth}
              meshHeight={meshHeight}
              zoom={clip.metadata.zoom}
              xOffset={clip.metadata.xOffset}
              yOffset={clip.metadata.yOffset}
              colorAdjustments={clip.metadata.colorAdjustments}
              renderOrder={webcamRenderOrder}
              videoAssetWidth={clip.metadata.originalWidth}
              videoAssetHeight={clip.metadata.originalHeight}
              opacity={opacity}
              faceBox={clip.metadata.faceBox}
              applyFaceBox={clip.metadata.applyFaceBox || false}
            />             


          }

          {!clip.isExport && !isVariable &&
            <WebcamVideoVideoMaterial 
              videoElement={clip.video}
              meshWidth={meshWidth}
              meshHeight={meshHeight}
              faceBox={clip.metadata.faceBox}
              applyFaceBox={clip.metadata.applyFaceBox || false}
              colorAdjustments={clip.metadata.colorAdjustments}
              renderOrder={webcamRenderOrder}
              videoAssetWidth={clip.metadata.originalWidth}
              videoAssetHeight={clip.metadata.originalHeight}
             // videoAssetWidth={200}
             // videoAssetHeight={400}
              opacity={opacity} // update to animated
              currentTime={time}
              transcript={clip.metadata.transcript}
              startTime={clip.startTime}
              //lutPath={'/luts/bwLUT.png'}
              //lutPath={'/luts/nightLUT.png'}
              //lutPath={'/luts/neutralLUT.png'}
              //lutPath={'/luts/Bourbon64.cube'}
              //lutPath={'/luts/Chemical168.cube'}
              //lutPath={'/luts/Portrait10.cube'}
            />              
          }



          </mesh>

          {frameBorderWidth > 0 &&
            
              <mesh        
                  ref={frameRef}
                  position={[initialProperties.positionX, initialProperties.positionY, initialProperties.positionZ]}
                  scale={[initialProperties.scale, initialProperties.scale, initialProperties.scale]}
                  geometry={frameGeometry}
                  visible={visible}            
                  renderOrder={renderOrders.webcamVideoFrame}
                >
                
                  <meshBasicMaterial 
                    color="rgb(248,248,252)"
                    transparent
                    opacity={1}
                    // opacity={0.7}
                  />
                
            </mesh>
          }


      

    </>
  );
};

export default WebcamVideoVideo;
