import React, { useState, useEffect } from 'react';
import Icon from '../misc/Icon'
import RecordWebcamToolbar from './RecordWebcamToolbar'

const RecordWebcamScriptCameraPreview = ({
  videoRef,    
  isDisplayRecording
}) => {

  const [expanded, setExpanded] = useState(false);

  return (    
    <>
    <div data-visibility-state={isDisplayRecording ? 'hidden' : 'visible'} data-expanded-state={expanded ? 'expanded' : 'default'} onClick={() => setExpanded(!expanded)} className='recordWebcamWindow-main--script-preview-videoContainer'>
      <video 
        ref={videoRef} 
        autoPlay 
        playsInline 
        muted
        className="recordWebcamWindow-main--script-preview-video"
      />      
    </div>
    
    </>
  );
};

export default RecordWebcamScriptCameraPreview;