import React, { useState } from 'react';
import Icon from '../../../misc/Icon';
import * as Tooltip from '@radix-ui/react-tooltip';  

function EditorGeneratePopoverInfoBox({   
  label
}) {

  return (
    <Tooltip.Root delayDuration={0} >
    <Tooltip.Trigger asChild> 
      <div className='generatePopover-infoBoxBtn'>      
        <Icon name='infoOutline' />
      </div>
    </Tooltip.Trigger>                      
      <Tooltip.Content side="top" align="end" sideOffset={-6} alignOffset={0} className="tooltip tooltip--infoBox">
        {label}
      </Tooltip.Content>              
  </Tooltip.Root> 
  );
}

export default EditorGeneratePopoverInfoBox;