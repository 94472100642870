const blobCache = {};


export const fetchWebcamVideo = async (captureId,audioTransformation={}) => {
  let videoURL
  const cacheKey = `${captureId}-${audioTransformation.type}`;
  if (blobCache[cacheKey]) {
    return blobCache[cacheKey];
  } else {
    if(window.isElectron){
      const videoBuffer = await ipcRenderer.invoke('get-webcam-file-buffer', captureId,audioTransformation);
      const videoBlob = new Blob([videoBuffer], { type: 'video/webm' });
      videoURL = URL.createObjectURL(videoBlob);
      blobCache[cacheKey] = videoURL;
      return videoURL;
    }
  }
};






