import {webcamSizes as sizes, webcamPositions as positions, SLIDE_WIDTH, SLIDE_HEIGHT, FLOAT_CAMERA_INSET,defaultSlideClipWebcamLayout,defaultVideoClipWebcamLayout} from './webcamConfigs'
import {calculateWebcamRectForSizeAndPosition} from './calculateWebcamRectForSizeAndPosition'


export function getWebcamRectForTime(webcamLayout, currentTime, slideClips, videoClips) {
 

  const defaultRect = calculateWebcamRectForSizeAndPosition(
    webcamLayout.size,
    webcamLayout.position
  );

  // Check for slide clips first
  if (slideClips?.length > 0) {
    const overlappingSlideClip = slideClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );

    if (overlappingSlideClip) {
      const slideWebcamLayout = overlappingSlideClip.metadata.webcamLayout || defaultSlideClipWebcamLayout;
      return calculateWebcamRectForSizeAndPosition(
        slideWebcamLayout.size,
        slideWebcamLayout.position
      );
    }
  }

  // If no overlapping slide clip, check video clips
  if (videoClips?.length > 0) {
    const overlappingVideoClip = videoClips.find(
      clip => currentTime >= clip.startTime && currentTime <= clip.endTime
    );

    if (overlappingVideoClip) {
      const videoWebcamLayout = overlappingVideoClip.metadata.webcamLayout || defaultVideoClipWebcamLayout;
      return calculateWebcamRectForSizeAndPosition(
        videoWebcamLayout.size,
        videoWebcamLayout.position
      );
    }
  }


  // Fallback to default if no overlapping clips found
  return defaultRect;
}






// export function getWebcamRectForTime(webcamLayout, currentTime, slideClips,videoClips) {
//   const defaultRect = calculateWebcamRectForSizeAndPosition(webcamLayout.size, webcamLayout.position);
//   // If no slide clips, return default
//   if (!slideClips || slideClips.length === 0) {
//     return defaultRect
//   }
//   const overlappingSlideClip = slideClips.find(clip => 
//     currentTime >= clip.startTime && currentTime <= clip.endTime
//   );
//   if (!overlappingSlideClip) {
//     return defaultRect
//   }else{
//     let slideRect
//     const slideWebcamLayout = overlappingSlideClip.metadata.webcamLayout
//     if(slideWebcamLayout){
//       slideRect=calculateWebcamRectForSizeAndPosition(slideWebcamLayout.size, slideWebcamLayout.position);
//     }else{
//       slideRect=calculateWebcamRectForSizeAndPosition(defaultSlideClipWebcamLayout.size, defaultSlideClipWebcamLayout.position); 
//     }
//     return slideRect
//   }
  
// }