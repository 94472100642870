import timelineSchema from '../../timelineProsemirror/schema/timelineSchema';
import cloneDeep from 'lodash/cloneDeep'

export const createClipPMNode = (clip) => {
	switch (clip.type) {
	case 'zoom':
		return createZoomClipPMNode(clip);
	case 'audio':
		return createAudioClipPMNode(clip);
	case 'image':
		return createImageClipPMNode(clip);
	case 'textSlide':
		return createTextSlideClipPMNode(clip);
	case 'slide':
		return createSlideClipPMNode(clip);
	case 'chart':
		return createChartClipPMNode(clip);
	case 'video':
		return createVideoClipPMNode(clip);
	case 'webcam':
		return createWebcamClipPMNode(clip);
	default:
		throw new Error('Unsupported clip type');
	}
};


export const createProjectSettingsPmNode=(projectBackgroundId,voiceoverPlaybackRate)=>{
	const node = timelineSchema.nodes.projectSettings.create({
		projectBackgroundId: projectBackgroundId,
		voiceoverPlaybackRate:voiceoverPlaybackRate
	});
	return node
}



export const createScenePMNode=(scene)=>{
	const node = timelineSchema.nodes.scene.create({
		id: scene.id, 
		title:scene.title,
		sceneIndex:scene.sceneIndex
	})
	return node
}


//for slide clips we make a node for the clip, for each layout group and for each element


const createSlideClipPMNode = (clip) => {
  const nodeAttrs = {
    id: clip.id,
    type: clip.type,
    relativeStartTime: clip.relativeStartTime,
    duration: clip.duration,
    metadata: {...clip.metadata},
    zIndex: clip.zIndex,
    sceneId: clip.sceneId
  };

  const content = createSlideClipContent(clip.layout);
  return timelineSchema.nodes.slideClip.create(nodeAttrs, content);
};


const createSlideClipContent = (layout) => {
  if (!layout || typeof layout !== 'object') {
    return []; // Return an empty array if layout is not defined or not an object
  }
  return [createLayoutGroupNode(layout)]; // Wrap in array as we expect to return an array
};

const createLayoutGroupNode = (group) => {
  if (!group || typeof group !== 'object') {
    return null;
  }

  const nodeAttrs = {
    id: group.id,
    depth: group.depth,
    type: group.type,
    hAlign: group.hAlign,
    vAlign: group.vAlign,
    x: group.x,
    y: group.y,
    bottomMargin:group.bottomMargin,
    rightMargin:group.rightMargin,
  };

  const content = (group.children || []).map(child => {
    if (child.isLayoutGroup) {
      return createLayoutGroupNode(child);
    } else {
      return createSlideElementNode(child);
    }
  });

  return timelineSchema.nodes.layoutGroup.create(nodeAttrs, content);
};


const createSlideElementNode = (element) => {
  const nodeAttrs = {
    id: element.id,
    bottomMargin: element.bottomMargin,
    rightMargin: element.rightMargin,
    x: element.x,
    y: element.y,
    height: element.height,
    width: element.width,    
    metadata: {...element.metadata},
    type: element.type
  };

  let content = null;
  if (element.type === 'text' && element.text) {
    content = timelineSchema.text(element.text);
  }

  return timelineSchema.nodes.slideElement.create(nodeAttrs, content);
};



const createZoomClipPMNode =(clip)=>{
	const node = timelineSchema.nodes.zoomClip.create({
		id: clip.id,
		type: clip.type,
		relativeStartTime: clip.relativeStartTime,
		duration: clip.duration,
		metadata:{...clip.metadata},
		zIndex: clip.zIndex,
		sceneId:clip.sceneId
	});
	return node
}

const createAudioClipPMNode=(clip)=>{
	const node = timelineSchema.nodes.audioClip.create({
		id: clip.id,
		type: clip.type,
		startTime: clip.startTime,
		relativeStartTime:clip.relativeStartTime,
		pinnedStartTime:clip.pinnedStartTime,
		duration: clip.duration,
		metadata:{...clip.metadata},
		parentWebcamClip:clip.parentWebcamClip,
		voiceoverPlaybackRate:clip.voiceoverPlaybackRate,
		zIndex: clip.zIndex,
		sceneId:clip.sceneId,
		clipIndex:clip.clipIndex,
		indexInParentClip:clip.indexInParentClip
	});
	return node
}


const createImageClipPMNode=(clip)=>{
	const node = timelineSchema.nodes.imageClip.create({
		id: clip.id,
		type: clip.type,
		imageId:clip.imageId,
		relativeStartTime: clip.relativeStartTime,
			//startTime: clip.startTime,
		duration: clip.duration,
		metadata: clip.metadata,
		wordsArray: clip.wordsArray,
		zIndex: clip.zIndex,
		sceneId:clip.sceneId
	});
	return node
}

const createTextSlideClipPMNode=(clip)=>{
	const node = timelineSchema.nodes.textSlideClip.create({
		id: clip.id,
		type: clip.type,
		relativeStartTime: clip.relativeStartTime,
		duration: clip.duration,
		metadata: clip.metadata,
		wordsArray: clip.wordsArray,
		zIndex: clip.zIndex,
		sceneId:clip.sceneId
	});
	return node
}


const createChartClipPMNode=(clip)=>{
	const node = timelineSchema.nodes.chartClip.create({
		id: clip.id,
		type: clip.type,
		relativeStartTime: clip.relativeStartTime,
			//startTime: clip.startTime,
		duration: clip.duration,
		metadata: clip.metadata,
		zIndex: clip.zIndex,
		sceneId:clip.sceneId
	});
	return node
}

const createWebcamClipPMNode=(clip)=>{
	const node = timelineSchema.nodes.webcamClip.createAndFill({
		id:clip.id,
		isUploadingVideo:clip.isUploadingVideo,
		captureId:clip.captureId,
		fileName:clip.fileName,
		placeholderDuration:clip.placeholderDuration,
		minDuration:clip.minDuration,
		recordingSegments:cloneDeep(clip.recordingSegments),
		segments:cloneDeep(clip.segments),
		name:clip.name,
		type:clip.type,
		relativeStartTime: clip.relativeStartTime,
		pinnedStartTime:clip.pinnedStartTime,
		duration:clip.duration,
		metadata:{...clip.metadata},
		zIndex:clip.zIndex,
		clipDuration:clip.duration,
		clipIndex:clip.clipIndex,
		sceneId:clip.sceneId
	})

	return node
}




const createVideoClipPMNode=(clip)=>{
	const node = timelineSchema.nodes.videoClip.createAndFill({
		id:clip.id,
		captureId:clip.captureId,
		isUploadingVideo:clip.isUploadingVideo,
		isScreenRecording:clip.isScreenRecording,
		videoId:clip.videoId,
		fileName:clip.fileName,
		isBasicVideo:clip.isBasicVideo,
		recordingSegments:cloneDeep(clip.recordingSegments),
		segments:cloneDeep(clip.segments),
		name:clip.name,
		type:clip.type,
		relativeStartTime: clip.relativeStartTime,
		duration:clip.duration,
		metadata:{...clip.metadata},
		zIndex:clip.zIndex,
		clipDuration:clip.duration,
		sceneId:clip.sceneId
	})
	return node
}
