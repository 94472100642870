const blobCache = {};

export const fetchWebcamAudio = async (captureId) => {
  const audioPath = `webcamRecordings/${captureId}/audio_normalized.mp3`;
  
  // Return cached buffer if available
  if (blobCache[audioPath]) {
    return blobCache[audioPath];
  }

  // try {
  //   if (window.isElectron) {
      // Fetch audio buffer from filesystem through IPC
      const audioBuffer = await window.ipcRenderer.invoke('get-webcam-audio', captureId, 'audio_normalized');
      
      // Convert array buffer to Buffer if needed
    //const audioBuffer = Buffer.from(audioData);
      
      // Cache the buffer
      blobCache[audioPath] = audioBuffer;
      
      return audioBuffer;
  //   } else {
  //     // Web version - fetch from cloud URL
  //     const response = await fetch(`https://your-cloud-url/${captureId}/audio_normalized.mp3`);
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
      
  //     // Get array buffer from response
  //     const arrayBuffer = await response.arrayBuffer();
  //     const audioBuffer = Buffer.from(arrayBuffer);
      
  //     // Cache the buffer
  //     blobCache[audioPath] = audioBuffer;
      
  //     return audioBuffer;
  //   }
  // } catch (error) {
  //   console.error('Error fetching audio:', error);
  //   throw error;
  // }
};

// Example usage:
// const audioBuffer = await fetchWebcamAudio('capture123');