import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { animated, useSpring } from '@react-spring/three';
import renderOrders from '../renderOrders';
import createRoundedRectShape from '../utils/createRoundedRectShape';
import { calulateVideoTimeFromTimelineTime } from '../../timeline/utils/calulateVideoTimeFromTimelineTime';
import WebcamVideoVideoMaterial from './WebcamVideoVideoMaterial';

const WebcamVideoPreview = (props) => {
  const { clip, time, isDraggingWebcam, isResizingWebcam, cameraPreviewRect } = props;

  const [isDelayedDraggingResizing, setIsDelayedDraggingResizing] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const dragging = isDraggingWebcam || isResizingWebcam;

    if (dragging) {
      // Started dragging/resizing: set delayed state to true immediately
      setIsDelayedDraggingResizing(true);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    } else {
      // Stopped dragging/resizing: set delayed state to false after 400ms
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsDelayedDraggingResizing(false);
      }, 500);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isDraggingWebcam, isResizingWebcam]);

  // Then derive immediate from the states:
  const immediate = !(isDelayedDraggingResizing && !isDraggingWebcam && !isResizingWebcam);


  //console.log('inAnimationWindow', inAnimationWindow)

  const isVariable = clip.metadata.isVariable;
  let startHiddenDuration = clip.metadata.startHiddenDuration || 0;
  let endHiddenDuration = clip.metadata.endHiddenDuration || 0;
  const relativeTime = time - clip.startTime;
  const localTime = clip.metadata.isVariable ? relativeTime : calulateVideoTimeFromTimelineTime(time, clip);

  let visible = false;
  if (!clip.metadata.isVariable) {
    if (localTime >= clip.metadata.trimStart && localTime < clip.metadata.trimEnd) {
      visible = true;
    }
    if (clip.isUploadingVideo && relativeTime >= clip.startTime && relativeTime < clip.endTime) {
      visible = true;
    }
    if (relativeTime < startHiddenDuration || relativeTime > clip.duration - endHiddenDuration) {
      visible = false;
    }
  } else {
    if (time >= clip.startTime && time < clip.endTime) {
      visible = true;
    }
  }

  const SCENE_WIDTH = 1920;
  const SCENE_HEIGHT = 1080;

  const rect = cameraPreviewRect || {};
  const left = rect.x;
  const top = rect.y;
  const displayWidth = rect.width;
  const displayHeight = rect.height;

  let frameBorderWidth = 10;
  if ((displayWidth > 0.49 * SCENE_WIDTH) || (displayHeight > 0.95 * SCENE_HEIGHT)) {
    // large webcam conditions
  }
  if ((displayWidth > 680) || (displayHeight > 700)) {
    frameBorderWidth = 0;
  }
  if ((displayWidth < 360) || (displayHeight < 360)) {
    frameBorderWidth = 6;
  }
  if ((displayWidth < 20) || (displayHeight < 20)) {
    frameBorderWidth = 0;
  }

  const [currentRadius, setCurrentRadius] = useState(20);
  const radiusTimeoutRef = useRef(null);

  useEffect(() => {
    if (isResizingWebcam) {
      setCurrentRadius(20);
      if (radiusTimeoutRef.current) clearTimeout(radiusTimeoutRef.current);
    } else {
      radiusTimeoutRef.current = setTimeout(() => {
        let targetRadius = 20;
        if ((displayWidth > 0.49 * SCENE_WIDTH) || (displayHeight > 0.95 * SCENE_HEIGHT)) {
          targetRadius = 0;
        }
        if ((displayWidth < 360) || (displayHeight < 360)) {
          targetRadius = 16;
        }
        if (displayWidth === 1200) {
          targetRadius = 16;      
        }
        setCurrentRadius(targetRadius);
      }, 500);
    }

    return () => {
      if (radiusTimeoutRef.current) clearTimeout(radiusTimeoutRef.current);
    };
  }, [isResizingWebcam, displayWidth, displayHeight]);

  const webcamFrameDisplayRadius = currentRadius + frameBorderWidth;
  const meshWidth = displayWidth - (frameBorderWidth * 2);
  const meshHeight = displayHeight - (frameBorderWidth * 2);

  const animationConfig = {
    tension: 280,
    friction: 24,
  }

  const [positionSprings, positionApi] = useSpring(() => ({
    position: [left + displayWidth / 2 - SCENE_WIDTH / 2, -(top + displayHeight / 2 - SCENE_HEIGHT / 2), 0],
    config: animationConfig,
    immediate
  }));

  const [meshDimensionSprings, meshDimensionApi] = useSpring(() => ({
    meshWidth,
    meshHeight,
    config: animationConfig,
    immediate
  }));

  const [frameDimensionSprings, frameDimensionApi] = useSpring(() => ({
    displayWidth,
    displayHeight,
    config: animationConfig,
    immediate
  }));

  useEffect(() => {
    positionApi.start({
      position: [left + displayWidth / 2 - SCENE_WIDTH / 2, -(top + displayHeight / 2 - SCENE_HEIGHT / 2), 0],
      immediate
    });
  }, [left, top, displayWidth, displayHeight, immediate]);

  useEffect(() => {
    meshDimensionApi.start({
      meshWidth,
      meshHeight,
      immediate
    });
  }, [meshWidth, meshHeight, immediate]);

  useEffect(() => {
    frameDimensionApi.start({
      displayWidth,
      displayHeight,
      immediate
    });
  }, [displayWidth, displayHeight, immediate]);

  const deviceRef = useRef();
  const frameRef = useRef();

  const AnimatedMeshGeometry = animated(({ meshWidth, meshHeight }) => {
    const shape = createRoundedRectShape(meshWidth, meshHeight, currentRadius);
    const geometry = new THREE.ShapeGeometry(shape);
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      uvs[i * 2] = (vertex.x + meshWidth / 2) / meshWidth;
      uvs[i * 2 + 1] = (vertex.y + meshHeight / 2) / meshHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return <primitive object={geometry} />;
  });

  const AnimatedFrameGeometry = animated(({ displayWidth, displayHeight }) => {
    const shape = createRoundedRectShape(displayWidth, displayHeight, webcamFrameDisplayRadius);
    const geometry = new THREE.ShapeGeometry(shape);
    const uvs = new Float32Array(geometry.attributes.position.count * 2);
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
      uvs[i * 2] = (vertex.x + displayWidth / 2) / displayWidth;
      uvs[i * 2 + 1] = (vertex.y + displayHeight / 2) / displayHeight;
    }
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    return <primitive object={geometry} />;
  });

  const placeholderBG = "rgb(170,170,170)";
  const webcamRenderOrder = (displayWidth === SCENE_WIDTH) ? 
    renderOrders.webcamVideoFullScreen : 
    renderOrders.webcamVideo;

  const isHidden = displayWidth < 20;

  return (
    <animated.group position={positionSprings.position} renderOrder={webcamRenderOrder}>
      <mesh
        ref={deviceRef}
        visible={visible}
        renderOrder={webcamRenderOrder}
      >
        <AnimatedMeshGeometry {...meshDimensionSprings} />
        {clip.metadata.isVariable ? (
          <meshBasicMaterial 
            color={placeholderBG}
            transparent
            opacity={1}
          />
        ) : (
          <WebcamVideoVideoMaterial 
            videoElement={clip.video}
            meshWidth={meshWidth}
            meshHeight={meshHeight}
            zoom={clip.metadata.zoom}
            xOffset={clip.metadata.xOffset}
            yOffset={clip.metadata.yOffset}
            colorAdjustments={clip.metadata.colorAdjustments}
            renderOrder={webcamRenderOrder}
            videoAssetWidth={clip.metadata.originalWidth}
            videoAssetHeight={clip.metadata.originalHeight}             
            opacity={isHidden ? 0 : 1}
            faceBox={clip.metadata.faceBox}
            applyFaceBox={clip.metadata.applyFaceBox || false}
          />
        )}
      </mesh>

      {frameBorderWidth > 0 && (
        <mesh
          ref={frameRef}
          visible={visible}
          renderOrder={renderOrders.webcamVideoFrame}
        >
          <AnimatedFrameGeometry {...frameDimensionSprings} />
          <meshBasicMaterial 
            color="rgb(248,248,252)"
            transparent
            opacity={1}
          />
        </mesh>
      )}
    </animated.group>
  );
};

export default WebcamVideoPreview;