import store from './store';
import pusherInstance from './pusherInstance';
import axios from 'axios';
import { Mixpanel } from './Mixpanel';
import Pusher from 'pusher-js'
import find from 'lodash/find'
import {handleSaveExport} from './actions/exportUpload'

const PUSHER_KEY='a7eac2ac287fcd5e92ea'
//const PUSHER_KEY='857f156820169492b745' //for dev
const RENDER_SEGMENT_SECONDS = 4;

// States:
// - enqueued: Initial state when export is requested
// - in_progress: After the first pusher with render-segment-started action
// - generation_completed: After pusher with status completed
// - downloading: When download starts
// - downloaded: After download is completed
// - failed: If export or download fails at any point
class ExportManager {
  constructor() {
    this.channel = null;
    this.pusher = new Pusher(PUSHER_KEY, {
      cluster: 'us2',
      encrypted: true
    });
    this.setupPusher();
  }

  setupPusher() {
    if (!this.channel) {
      this.channel = this.pusher.subscribe('video-renders');
      this.channel.bind('render-progress-update', this.handleProgressUpdatePusher);
      this.channel.bind('render-update', this.handlePusherUpdate);
    }
  }

  handleProgressUpdatePusher = (data) => {
    const { projectId, renderId, action, segmentId } = data;
    
    if (action === 'segment-render-started') {
      const state = store.getState();
      const exportStatus = state.projectExports[projectId]?.status;
      // Check if this is the first 'segment-render-started' event for this export
      if (exportStatus !== 'in_progress') {
        const inProgressTime = Date.now();
        store.dispatch({
          type: 'UPDATE_EXPORT_STATUS',
          payload: {
            projectId,
            renderId,
            status: 'in_progress',
            inProgressTime
          }
        });
        //console.log(`Export for project ${projectId} is now in progress. First segment: ${segmentId}`);
      }
    } else if (action === 'segment-render-finished') {
      store.dispatch({
        type: 'UPDATE_EXPORT_PROGRESS',
        payload: {
          projectId,
          renderId,
          completedSegment: segmentId
        }
      });
    }
  }

  handlePusherUpdate = (data) => {
   // console.log(data)
    if (data.status === 'completed') {
      const state = store.getState();
      const activeExports = state.projectExports || {};



      if (activeExports[data.projectId] && activeExports[data.projectId].renderId == data.renderId) {
        const project = find(state.projects, {id: data.projectId});
        let name = project ? project.name : 'export';
        const filename = `${name}.mp4`;
        const completionTime = Date.now();
        store.dispatch({
          type: 'EXPORT_GENERATION_COMPLETED',
          payload: {
            projectId: data.projectId,
            renderId: data.renderId,
            resultUrl: data.resultUrl,
            completionTime
          }
        });

        handleSaveExport(data.projectId,data.resultUrl)


        //   // Trigger download through Electron
        // window.ipcRenderer.send('download-exported-video', {
        //   url: data.resultUrl,
        //   filename
        // });

        // // Listen for download completion
        // window.ipcRenderer.once('download-exported-video-complete', (result) => {
        //   if (result.success) {
        //     store.dispatch({
        //       type: 'EXPORT_DOWNLOAD_COMPLETED',
        //       payload: {
        //         projectId: data.projectId,
        //         renderId: data.renderId,
        //         filePath: result.filePath,
        //         filename: result.filename,
        //         downloadCompletionTime: Date.now()
        //       }
        //     });
        //   } else {
        //     store.dispatch({
        //       type: 'EXPORT_DOWNLOAD_FAILED',
        //       payload: {
        //         projectId: data.projectId,
        //         renderId: data.renderId,
        //         error: result.error
        //       }
        //     });
        //   }
        // }



        // const downloadListener = (result) => {
        //   window.ipcRenderer.removeListener('download-exported-video-complete', downloadListener);
        //   if (result.success) {
        //     console.log(`Download completed: ${result.filename}`);
        //     store.dispatch({
        //       type: 'EXPORT_DOWNLOAD_COMPLETED',
        //       payload: {
        //         projectId: data.projectId,
        //         renderId: data.renderId,
        //         filePath: result.filePath,
        //         filename: result.filename,
        //         downloadCompletionTime: Date.now()
        //       }
        //     });

        //     // Calculate and send Mixpanel event
        //     this.sendExportCompletionEvent(data.projectId, data.renderId, name, result.filename);
        //   } else {
        //     console.error(`Download failed: ${result.error}`);
        //     store.dispatch({
        //       type: 'EXPORT_DOWNLOAD_FAILED',
        //       payload: {
        //         projectId: data.projectId,
        //         renderId: data.renderId,
        //         error: result.error
        //       }
        //     });
        //   }
        // };

        // window.ipcRenderer.receive('download-exported-video-complete', downloadListener);

        // window.ipcRenderer.send('download-exported-video', { 
        //   url: data.resultUrl, 
        //   filename: filename 
        // });

        // store.dispatch({
        //   type: 'EXPORT_DOWNLOADING',
        //   payload: {
        //     projectId: data.projectId,
        //     renderId: data.renderId
        //   }
        // });
      } else {
      //  console.log('not our render do nothing')
      }
    } else if (data.status === 'failed') {
      store.dispatch({
        type: 'EXPORT_FAILED',
        payload: {
          projectId: data.projectId,
          renderId: data.renderId,
          error: 'Video generation failed'
        }
      });
    }
  }

  calculateRenderSegmentSizeSeconds(duration) {
	  const numberOfMachines = 18;
	  const minimumSegmentSize = 2;
	  const maximumSegmentSize = 6;
    const maxTotalSegments = 36
	  // Calculate the minimum number of batches required
	  let numberOfBatches = Math.ceil(duration / (numberOfMachines * maximumSegmentSize));
	  // Compute the segment size based on the number of batches
	  let segmentSize = duration / (numberOfMachines * numberOfBatches);
	  // Ensure the segment size is not less than the minimum segment size
	  while (segmentSize < minimumSegmentSize && numberOfBatches > 1) {
	    numberOfBatches -= 1;
	    segmentSize = duration / (numberOfMachines * numberOfBatches);
	  }
	  // Round the segment size and ensure it's within the allowed range
	  segmentSize = Math.round(segmentSize);
	  segmentSize = Math.max(minimumSegmentSize, Math.min(maximumSegmentSize, segmentSize));
	  // Calculate total number of segments
	  let totalSegments = Math.ceil(duration / segmentSize);

    if (totalSegments > maxTotalSegments) {
      segmentSize = Math.ceil(duration / maxTotalSegments);
      totalSegments = maxTotalSegments;
      // Recalculate number of batches
      numberOfBatches = Math.ceil(totalSegments / numberOfMachines);
    }


	  return { segmentSize, numberOfBatches, totalSegments };
	}


 




  estimateGenerationTime(projectDuration, segmentLength, numberOfBatches, totalSegments) {
	  const fixedTimePerSegment = 2;
	  const secondsPerSegment = fixedTimePerSegment + (3.25 * segmentLength);
	  const estimatedTimeSeconds = numberOfBatches * secondsPerSegment;
	  const estimatedFinalizeTime = projectDuration * 0.1;
	  const estimatedDownloadTime = projectDuration * 0.22;
	  // Add a 5% buffer for potential overhead
	  const estimatedTimeWithBuffer = (estimatedTimeSeconds * 1.05) + estimatedFinalizeTime + estimatedDownloadTime;
    return Math.ceil(estimatedTimeWithBuffer);
  }

  async startExport(projectId) {
    try {
      const enqueuedTime = Date.now();
      const state = store.getState();
      const project = find(state.projects, { id: projectId });
      let renderSegmentSeconds = 4; // Default value
	    let projectDuration
	    let segmentSize
	     let numberOfBatches;
	    let totalSegments;

	    if (project && project.duration) {
	      projectDuration = project.duration;
	      const result = this.calculateRenderSegmentSizeSeconds(projectDuration);
	      renderSegmentSeconds = result.segmentSize;
	      segmentSize = result.segmentSize;
	      numberOfBatches = result.numberOfBatches;
	      totalSegments = result.totalSegments;
	    }

    	const estimatedGenerationTime = this.estimateGenerationTime(projectDuration, segmentSize, numberOfBatches, totalSegments);

      const data = await this.generateVideo(projectId, renderSegmentSeconds);     
      store.dispatch({
        type: 'START_EXPORT_SUCCESS',
        payload: {
          projectId,
          renderId: data.renderId,
          segmentCount: Math.ceil(data.segmentCount),
          status: 'enqueued',
          renderSegmentSeconds,
          enqueuedTime,
          estimatedGenerationTime,
          projectDuration
        }
      });

      Mixpanel.track('Project Export Started', {
        projectId,
        renderId: data.renderId,
        segmentCount: Math.ceil(data.segmentCount),
        renderSegmentSeconds,
        estimatedGenerationTime,
        enqueuedTime
      });

      return data;
    } catch (error) {
      store.dispatch({ 
        type: 'START_EXPORT_FAILURE', 
        payload: { projectId, error: error.message } 
      });
      Mixpanel.track('Project Export Failed', {
        projectId,
        error: error.message
      });
      throw error;
    }
  }

  async generateVideo(projectId, renderSegmentSeconds, maxRetries = 3) {
    let apiTarget = 'prod';
  
    // if(process.env.REACT_APP_ENV=='development' || process.env.REACT_APP_ENV=='local'){
    //   apiTarget='dev'
    // }

  const url = `https://orch.yarndist.com/orchestrator/render/${projectId}?renderSegmentSizeSeconds=${renderSegmentSeconds}&exportWidth=3840&apiTarget=${apiTarget}`;
   
// const externalAxios = axios.create();


  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await axios.get(url);
      console.log('Video generation successful:', response.data);
      return response.data;
    } catch (error) {
      console.error(`Error generating video (Attempt ${attempt}/${maxRetries}):`, error);
      
      // Log more detailed error information
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);

      if (attempt === maxRetries) {
        throw new Error(`Failed to generate video after ${maxRetries} attempts: ${error.message}`);
      }

      // Wait before retrying (you can implement exponential backoff here if needed)
      await new Promise(resolve => setTimeout(resolve, 6000));
    }
  }
}


  // async generateVideo(projectId, renderSegmentSeconds) {
  //   let apiTarget='prod'
  //   // if(process.env.REACT_APP_ENV=='development' || process.env.REACT_APP_ENV=='local'){
  //   //   apiTarget='dev'
  //   // }
  //   const url = `https://orch.yarndist.com/orchestrator/render/${projectId}?renderSegmentSizeSeconds=${renderSegmentSeconds}&exportWidth=3840&apiTarget=${apiTarget}`;
  //   try {
  //     const response = await axios.get(url);
  //     console.log(response)
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error generating video:', error);
  //     throw new Error('Failed to generate video');
  //   }
  // }

  sendExportCompletionEvent(projectId, renderId, projectName, filename) {
    const state = store.getState();
    const exportData = state.projectExports[projectId];

    if (exportData) {
      const { enqueuedTime, inProgressTime, completionTime, downloadCompletionTime, estimatedGenerationTime, projectDuration, renderSegmentSeconds, resultUrl } = exportData;

      const queueTime = inProgressTime - enqueuedTime;
      const generationTime = completionTime - inProgressTime;
      const totalTime = completionTime - enqueuedTime;
      const downloadTime = downloadCompletionTime - completionTime;

      // Format times in seconds with 2 decimal places
      const queueTimeSeconds = (queueTime / 1000).toFixed(2);
      const generationTimeSeconds = (generationTime / 1000).toFixed(2);
      const totalTimeSeconds = (totalTime / 1000).toFixed(2);
      const downloadTimeSeconds = (downloadTime / 1000).toFixed(2);
      const estimatedGenerationTimeSeconds = (estimatedGenerationTime).toFixed(2);

      const generationTimeDelta = generationTime - (estimatedGenerationTime * 1000);
      const generationTimeDeltaSeconds = (generationTimeDelta / 1000).toFixed(2);

      // Find the project
      const project = find(state.projects, {id: projectId});

      // Get the organization information
      const organization = state.organization;
      const orgId = organization ? organization.id : null;
      const orgName = organization ? organization.name : null;

      Mixpanel.track('Project Export Completed', {
        projectId,
        projectName,
        renderId,
       // queueTime,
       // generationTime,
       // totalTime,
       // downloadTime,
        queueTimeSeconds: parseFloat(queueTimeSeconds),
        generationTimeSeconds: parseFloat(generationTimeSeconds),
        totalTimeSeconds: parseFloat(totalTimeSeconds),
        downloadTimeSeconds: parseFloat(downloadTimeSeconds),
        estimatedGenerationTimeSeconds: parseFloat(estimatedGenerationTimeSeconds),
        generationTimeDeltaSeconds: parseFloat(generationTimeDeltaSeconds),
        filename,
        resultUrl,
        orgId,
        orgName,
        renderSegmentSeconds,
        projectDuration
      });

      // Cleanup can be handled in the reducer when processing EXPORT_DOWNLOAD_COMPLETED action
    }
  }

  async cancelExport(projectId) {
    const state = store.getState();
    const activeExports = state.projectExports || {};
    const exportData = activeExports[projectId];
    if (exportData && exportData.renderId) {
      try {
        store.dispatch({
          type: 'CANCEL_EXPORT',
          payload: { projectId, renderId: exportData.renderId }
        });        
        // Mixpanel.track('Project Export Cancelled', {
        //   projectId,
        //   renderId: exportData.renderId
        // });
      } catch (error) {
        throw new Error('Failed to cancel export');
      }
    } else {
      console.log(`No active export found for project ${projectId}`);
    }
  }
}

const exportManager = new ExportManager();
export default exportManager;