import React, { useState } from 'react';
import Icon from '../../../misc/Icon';
import DPTextInput from '../../detailPanel/DPTextInput';
import EditorGeneratePopoverInfoBox from './EditorGeneratePopoverInfoBox';
import {getOrgDomain} from '../../../../utils/getOrgDomain'

const getIconNameForType = (type) => {
  const typeToIcon = {
    string: 'textElementMedium',
    image: 'insertBarImage',
    camera: 'cameraOutline',  
    music: 'musicNotes',    
    voice: 'waveform',    
    background: 'insertBarImage',    
  };
  
  return typeToIcon[type];
};

function EditorGeneratePopoverColabPageVariableInput({ 
  name,
  type,
  clip,
  isExpanded,
  updateClipMetadata
}) {


  const [pageLabel, setPageLabel] = useState(clip.metadata.pageLabel || '');


  const udpatePageLabel = (value) => {
    setPageLabel(value)
    updateClipMetadata(clip.id,{pageLabel:value})
  }  



  return (
    
    <div 
      data-variable-input='true' 
      data-expanded-state={isExpanded ? 'expanded' : 'default'} 
      className='generatePopover-section--inputs-input'
    >
      <div className='generatePopover-section--inputs-input-row'>
        <div className='generatePopover-section--inputs-input-row-typeIcon'>
          <Icon name={getIconNameForType(type)} />
        </div>
        <div className='generatePopover-section--inputs-input-row-title'>
          {name}
        </div>
      </div>
      {isExpanded &&
        <div className='generatePopover-section--inputs-input-contents'>      
          <div className='generatePopover-section--inputs-input-contents-textInputContainer'>
            <DPTextInput 
              value={pageLabel}
              setValue={udpatePageLabel}        
              fullWidth
              placeholder="Write page label..."
            />
          </div>
        </div>
      }              
    </div>
  );
}

function EditorGeneratePopoverColabPageVariablePassed({ 
  name,
  type
}) {

  return (
    <div data-variable-input='false' className='generatePopover-section--inputs-input'>
      <div className='generatePopover-section--inputs-input-row'>
        <div className='generatePopover-section--inputs-input-row-typeIcon'>
          <Icon name={getIconNameForType(type)} />
        </div>
        <div className='generatePopover-section--inputs-input-row-title'>
          {name}        
        </div>
      </div>
    </div>
  );
}

function EditorGeneratePopoverColabPageVariables(props) {

  const stringVariables = props.stringVariables
  const variableWebcams = props.variableWebcams
  const orgDomain = getOrgDomain()
  const isClay = (orgDomain=='clay.com' || orgDomain=='clay.run' || orgDomain=='yarn.so') 

  return (
    <>
      <div className='generatePopover-section generatePopover-section--inputs'>
        <div className='generatePopover-section-header'>
          Page Inputs          
          <EditorGeneratePopoverInfoBox 
            label='These are the inputs that individuals will provide or record on the page to generate a video.'
            //label='These will appear at the top of the page. It can be long, and is a great place to write instructions or a message.'
          />                      
        </div>      
        {variableWebcams.map(clip => (
          <EditorGeneratePopoverColabPageVariableInput
            key={clip.id}
            clip={clip}
            name={'Webcam'}
            type={'camera'}
            isExpanded={true}            
            updateClipMetadata={props.updateClipMetadata}
          />
        ))}

        {variableWebcams.length < 1 &&
          <div 
            data-variable-input='true' 
            data-expanded-state='default'
            className='generatePopover-section--inputs-input'
          >
            <div className='generatePopover-section--inputs-input-row'>
              <div className='generatePopover-section--inputs-input-row-typeIcon'>
                <Icon name={getIconNameForType('voice')} />
              </div>
              <div className='generatePopover-section--inputs-input-row-title'>
                AI Voiceover
              </div>
            </div>                  
          </div>
        }
        
        {/*{isClay && stringVariables.length > 0 &&*/}
         {isClay &&
          <div className='generatePopover-section-footer'>
          
            <button 
              onClick={() => props.handleFieldUpdate('enableSelectBGMusic',!props.enableSelectBGMusic)}
              className='generatePopover-section-footer-lightBtn'
            >
              {!props.enableSelectBGMusic ? 'Allow Select Music' : "Don't Allow Select Music"}
            </button>
          
            <button 
              onClick={() => props.handleFieldUpdate('enableSelectBGColor',!props.enableSelectBGColor)}
              className='generatePopover-section-footer-lightBtn'
            >
              {!props.enableSelectBGColor ? 'Allow Select BG Color' : "Don't Allow Select BG Color"}
            </button>      
          </div>
        }

      </div>

      
      
      {stringVariables.length > 0 &&
        <>
          <div className='generatePopover-divider' />
          
          <div className='generatePopover-section generatePopover-section--inputs'>
            <div className='generatePopover-section-header'>
              Variables Provided via Link
              <EditorGeneratePopoverInfoBox 
                label="Pass values for variables like text or image URLs via personal links using the URL schema below."
                //label='These will appear at the top of the page. It can be long, and is a great place to write instructions or a message.'
              /> 
            </div>      
            {stringVariables.map((variable,i) => (
              <EditorGeneratePopoverColabPageVariablePassed 
                key={i}
                name={variable}
                type={'string'}
              />
            ))}
          </div>
        </>
      }
    </>
  );
}

export default EditorGeneratePopoverColabPageVariables;