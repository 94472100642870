import React, { useState } from 'react';
import Icon from '../../../misc/Icon';
import { getOrgNameLabel } from '../../../../utils/getOrgNameLabel';
import EditorGeneratePopoverCodeBox from './EditorGeneratePopoverCodeBox';
import {getOrgDomain} from '../../../../utils/getOrgDomain'

function generateStructuredContent(type, baseUrl, variables) {
  switch (type) {
    case 'plain':
      return [{
        text: baseUrl,
        type: 'plain'
      }];

    case 'urlSchema':
      const parts = [
        { text: baseUrl, type: 'url' }
      ];
      
      if (variables.length > 0) {
        parts.push({ text: '?', type: 'url' });
        
        variables.forEach((variable, index) => {
          if (index > 0) parts.push({ text: '&', type: 'url' });
          
          parts.push(
            { text: `${encodeURIComponent(variable)}=`, type: 'url' },
            { text: `{{${variable}}}`, type: 'urlVariable' }
          );
        });
      }
      
      return parts;

    case 'googleSheet':
      const formula = [
        { text: '=CONCATENATE("', type: 'url' },
        { text: baseUrl, type: 'url' }
      ];

      if (variables.length > 0) {
        formula.push({ text: '?",', type: 'url' });

        variables.forEach((variable, index) => {
          if (index > 0) formula.push({ text: ',"&",', type: 'url' });
          
          formula.push(
            { text: `"${encodeURIComponent(variable)}=", ENCODEURL(`, type: 'url' },
            { text: `${String.fromCharCode(65 + index)}2`, type: 'cellReference' },
            { text: ')', type: 'url' }
          );
        });

        formula.push({ text: ')', type: 'url' });
      } else {
        formula.push({ text: '")', type: 'url' });
      }

      return formula;

    case 'clayFormula':
      const clayParts = [
        { text: '"', type: 'url' },
        { text: baseUrl, type: 'url' }
      ];

      if (variables.length > 0) {
        variables.forEach((variable, index) => {
          clayParts.push(
            { text: index === 0 ? '?' : '&', type: 'url' },
            { text: `${encodeURIComponent(variable)}=", `, type: 'url' },
            { text: 'encodeURIComponent', type: 'url' },
            { text: '(', type: 'url' },
            { text: `{{${variable}}}`, type: 'url' },
            { text: ')', type: 'url' }
          );

          if (index < variables.length - 1) {
            clayParts.push({ text: ' + "', type: 'url' });
          }
        });
      }

      return clayParts;
  }
}

function EditorGeneratePopoverColabPageLinks({ stringVariables, projectName, projectId }) {
  const orgName = getOrgNameLabel();
  const isClay = getOrgDomain()=='clay.com' || getOrgDomain()=='yarn.so'

  const customLinks = stringVariables && stringVariables.length > 0;
  const [activeFormulaTab, setActiveFormulaTab] = useState('schemaUrl');

  // Generate base URL
  const generateBaseUrl = () => {
    const formattedProjectName = projectName.replace(/\s+/g, '-');
    return isClay
      ? `https://clay.yarn.so/collab/${formattedProjectName}-${projectId}`
      : `https://gen.yarn.so/collab/${formattedProjectName}-${projectId}`;
  };

  const baseUrl = generateBaseUrl();

  // Generate structured content for each type
  const staticContent = generateStructuredContent('plain', baseUrl, []);
  const schemaContent = generateStructuredContent('urlSchema', baseUrl, stringVariables || []);
  const googleContent = generateStructuredContent('googleSheet', baseUrl, stringVariables || []);
  const clayContent = generateStructuredContent('clayFormula', baseUrl, stringVariables || []);

  const handleOpenUrl = (url) => {
    window.ipcRenderer.send('open-oauth-window', { url });
  };

  return (
    <>
      {!customLinks &&
        <div className='generatePopover-section generatePopover-section--pageURL'>
          <div className='generatePopover-section-header'>
            Page URL
          </div>

          <EditorGeneratePopoverCodeBox 
            content={staticContent}
            type="plain"
          />

          <button 
            onClick={() => handleOpenUrl(baseUrl)} 
            className='generatePopover-section--pageURL-viewPageBtn'
          >
            View Page
          </button>
        </div>
      }

      {customLinks &&
        <div className='generatePopover-section generatePopover-section--urlFormula'>
          <div className='generatePopover-section-header generatePopover-section-header--tabs'>
            <div 
              onClick={() => setActiveFormulaTab('schemaUrl')} 
              data-active-state={activeFormulaTab === 'schemaUrl' ? 'active' : 'inactive'} 
              className='generatePopover-section-header-tab'
            >
              URL Schema
              <div className='generatePopover-section-header-tab-border' />
            </div>
            <div 
              onClick={() => setActiveFormulaTab('google')} 
              data-active-state={activeFormulaTab === 'google' ? 'active' : 'inactive'} 
              className='generatePopover-section-header-tab'
            >
              Google Sheets
              <div className='generatePopover-section-header-tab-border' />
            </div>
            <div 
              onClick={() => setActiveFormulaTab('clay')} 
              data-active-state={activeFormulaTab === 'clay' ? 'active' : 'inactive'}  
              className='generatePopover-section-header-tab'
            >
              Clay Formula
              <div className='generatePopover-section-header-tab-border' />
            </div>
          </div>

          {activeFormulaTab === 'schemaUrl' && 
            <EditorGeneratePopoverCodeBox
              content={schemaContent}
              type="urlSchema"
              minHeight
            />
          }

          {activeFormulaTab === 'google' && 
            <EditorGeneratePopoverCodeBox
              content={googleContent}
              type="googleSheet"
              minHeight
            />
          }

          {activeFormulaTab === 'clay' && 
            <EditorGeneratePopoverCodeBox
              content={clayContent}
              type="clayFormula"
              minHeight
            />
          }
        </div>
      }
    </>
  );
}

export default EditorGeneratePopoverColabPageLinks;