import { post, fetch,put } from '../api';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {downloadMissingWebcamRecordings} from '../utils/assets/downloadMissingWebcamRecordings'


export function fetchWebcamRecordings(){
  return (dispatch) => fetch(`/webcam-recording`)
  .then((response) => {
    dispatch({ type: 'FETCH_WEBCAM_RECORDINGS_SUCCESS', response })
    downloadMissingWebcamRecordings(response)
    return response
  })
  .catch((error) => {
    console.log(error)
    return error
  })
}


export const uploadWebcamRecording= (clipId,file, duration, captureId) => async (dispatch) => {
  try {
    dispatch(setPendingWebcamUpload(captureId,clipId))
    // Get the signed URL from your backend
    const response = await post('/webcam-recording', {
      capture_id: captureId,
      filename: file.name,
      content_type: file.type,
      duration
    });
    // Upload to S3 using the signed URL
    await axios.put(response.uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });
    const updateResponse = await put(`/webcam-recording/${captureId}/upload-complete`);
    return updateResponse.recording;
  } catch (error) {
    console.error('Error during upload:', error);
    Sentry.captureException(error);
    throw error;
  }
};


const setPendingWebcamUpload = (captureId, clipId) => ({
  type: 'SET_PENDING_WEBCAM_UPLOAD',
  payload: { captureId, clipId }
});


export const webcamProcessingComplete = (captureId,webcamData) => async (dispatch) => {
  try {
 
    console.log('Starting webcam file downloads for', captureId);
    
    const result = await ipcRenderer.invoke('download-webcam-recording-files', captureId);
    
    if (!result.success) {
      throw new Error(result.error);
    }

   // console.log('Downloads completed for', captureId, result.files);
    
    // Now dispatch the completion action
    dispatch({
      type: 'WEBCAM_PROCESSING_COMPLETE',
      captureId:captureId,
      webcamData: webcamData
    });

    dispatch(fetchWebcamRecordings())

  } catch (error) {
 //   console.error('Error in webcam processing:', error);
    Sentry.captureException(error);
    
    dispatch({
      type: 'WEBCAM_PROCESSING_ERROR',
      payload: {
        captureId: captureId,
        error: error.message
      }
    });
  }
};

// export const webcamProcessingComplete = (webcamData) => ({
//   type: 'WEBCAM_PROCESSING_COMPLETE',
//   payload: webcamData
// });

