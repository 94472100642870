import {combineReducers} from 'redux'
import "../styles/App.scss"

import authenticationStatus from './authenticationStatus'
import user from './user'
import projects from './projects'
import organization from './organization'
import orgBrand from './orgBrand'
import orgMembers from './orgMembers'
import downloads from './downloads'
import uploadedImages from './uploadedImages'
import uploadedMusic from './uploadedMusic'
import imageDownloads from './imageDownloads'
import uploadedVideos from './uploadedVideos'
import basicVideoDownloads from './basicVideoDownloads'
import musicDownloads from './musicDownloads'
import recordings from './recordings'
import collections from './collections'
// import sceneTemplates from './sceneTemplates'
import archivedProjects from './archivedProjects'
import toastNotification from './toastNotification'
import librarySettings from './librarySettings'
import tabs from './tabs'
import userSettings from './userSettings'
import clipActions from './clipActions'
import slideTemplates from './slideTemplates'
import archivedSlideTemplates from './archivedSlideTemplates'
import webcamDownloads from './webcamDownloads'
// import webcamVideos from './webcamVideos'
import projectExports from './projectExports'
import screenRecordings from './screenRecordings'
import images from './images'
import pendingWebcamUploads from './pendingWebcamUploads'
import webcamRecordings from './webcamRecordings'
import voiceMatch from './voiceMatch'


const rootReducer = combineReducers({
	authenticationStatus,
	user,
	projects,
	organization,
	orgBrand,
	orgMembers,
	downloads,
	uploadedImages,
	imageDownloads,
	uploadedVideos,
	basicVideoDownloads,
	uploadedMusic,
	musicDownloads,
	recordings,
	collections,
	archivedProjects,
	toastNotification,
	librarySettings,
	tabs,
	userSettings,
	clipActions,
	slideTemplates,
	archivedSlideTemplates,
	webcamDownloads,
	projectExports,
	screenRecordings,
	images,
	pendingWebcamUploads,
	webcamRecordings,
	voiceMatch
})

export default rootReducer